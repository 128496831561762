import { Injectable } from '@angular/core';
import { HttpClient ,HttpParams} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {categoriaBeneficio,portadaBeneficio,detalleBeneficio,bannerBeneficio,provincia,canton,distrito,bannerComercio, cuponAdquirido, sucursalComercioCercano } from '../interfaces';
import {ID_CLIENTE_BENEFICIOS,URL_BASE_WEB_API,ID_SITIO_ECOMMERCE } from '../global-variables';
const HTTP_OPTIONS={
  headers: new HttpHeaders({'content-type':'application/json','Access-Control-Allow-Origin':'*'})
};
@Injectable({
  providedIn: 'root'
})
export class BeneficioService {
  httpOptions=HTTP_OPTIONS;
  url_base_web_api:  string  =  URL_BASE_WEB_API;
  
  idSitio=ID_SITIO_ECOMMERCE;

  constructor(private http:HttpClient) { 
  }

  categorias(){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<categoriaBeneficio[]>(this.url_base_web_api+'Beneficios/categorias',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  comerciosCategoria(pIdCategoria:number){
    try{
      let params = new HttpParams()
      .set('pIdCategoria',pIdCategoria.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<portadaBeneficio[]>(this.url_base_web_api+'Beneficios/comercioscategoria',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  comerciosCategoriaTop(){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<portadaBeneficio[]>(this.url_base_web_api+'Beneficios/comercioscategoriatop',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  detalleComercioBeneficio( pIdComercio:number, pIdCategoria:number){
    try{
      let params = new HttpParams()
      .set('pIdComercio',pIdComercio.toString())
      .set('pIdCategoria',pIdCategoria.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<detalleBeneficio>(this.url_base_web_api+'Beneficios/detalleComercioBeneficio',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  buscarBeneficiosCriterios(pCriterioBusqueda:string){
    try{
      let params = new HttpParams()
      .set('pCriterioBusqueda',pCriterioBusqueda.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<portadaBeneficio[]>(this.url_base_web_api+'Beneficios/buscarbeneficioscriterios',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  bannerBeneficio(pIdCategoria:number) {
    try {
      let params = new HttpParams()
        .set('pIdSitio', this.idSitio.toString())
        .set('pIdCategoria', pIdCategoria.toString());
      return this.http.get<bannerBeneficio[]>(this.url_base_web_api + 'Beneficios/bannersBeneficios', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerProvincias() {
    try {
      let params = new HttpParams();
      return this.http.get<provincia[]>(this.url_base_web_api + 'Beneficios/consultarProvincias', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerCantones(pIdProvincia:number) {
    try {
      let params = new HttpParams()
      .set('pIdProvincia',pIdProvincia.toString());
      return this.http.get<canton[]>(this.url_base_web_api + 'Beneficios/consultarCantones', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerDistritos(pIdCanton:number) {
    try {
      let params = new HttpParams()
      .set('pIdCanton',pIdCanton.toString());
      return this.http.get<distrito[]>(this.url_base_web_api + 'Beneficios/consultarDistritos', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  filtrarComerciosUbicacion(pIdCategoriaComercio:number,pIdProvincia:number,pIdCanton:number,pIdDistrito:number,pLatitud:string,pLongitud:string) {
    try {
      let params = new HttpParams()
      .set('pIdSitio', this.idSitio.toString())
      .set('pIdCategoriaComercio',pIdCategoriaComercio.toString())
      .set('pIdProvincia',pIdProvincia.toString())
      .set('pIdCanton',pIdCanton.toString())
      .set('pIdDistrito',pIdDistrito.toString())
      .set('pLatitud',pLatitud.toString())
      .set('pLongitud',pLongitud.toString());
      return this.http.get<portadaBeneficio[]>(this.url_base_web_api + 'Beneficios/consultarbeneficiosubicaciongeografica', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerBannersComercio(pIdComercio:number) {
    try {
      let params = new HttpParams()
      .set('pIdSitio', this.idSitio.toString())
      .set('pIdComercio',pIdComercio.toString());
      return this.http.get<bannerComercio[]>(this.url_base_web_api + 'Beneficios/bannerscomercios', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  adquirirCuponBeneficio(pIdBeneficio:number,pIdUsuario,pServidorCorreoRemitente:string,
   pUsuarioCorreoRemitente:string,pContrasenaCorreoRemitente:string,
    pNombreMostrarCorreoRemitente:string,pCorreoCC:string,pCorreoDestino:string,
    pTituloSeccion:string,pSubtituloSeccion:string) {
    try {
      let params = new HttpParams()
      .set('pIdBeneficio', pIdBeneficio.toString())
      .set('pIdUsuario',pIdUsuario.toString())
      .set('pServidorCorreoRemitente',pServidorCorreoRemitente.toString())
      .set('pIdSitio',this.idSitio.toString())
      .set('pUsuarioCorreoRemitente',pUsuarioCorreoRemitente.toString())
      .set('pContrasenaCorreoRemitente',pContrasenaCorreoRemitente.toString())
      .set('pNombreMostrarCorreoRemitente',pNombreMostrarCorreoRemitente.toString())
      .set('pCorreoCC',pCorreoCC.toString())
      .set('pCorreoDestino',pCorreoDestino.toString())
      .set('pTituloSeccion',pTituloSeccion.toString())
      .set('pSubtituloSeccion',pSubtituloSeccion.toString());
      return this.http.get<cuponAdquirido>(this.url_base_web_api + 'Beneficios/adquirircuponbeneficio', { params: params });
    }
    catch (Error) {
      return null;
    }
  }


    reenviarCuponBeneficio(pServidorCorreoRemitente:string,
      pUsuarioCorreoRemitente:string,pContrasenaCorreoRemitente:string,
       pNombreMostrarCorreoRemitente:string,pCorreoCC:string,pCorreoDestino:string,
       pTituloSeccion:string,pSubtituloSeccion:string,pCondiciones:String,
       pCodigo:string,pFormaUso:string,pTituloCupon:string,pNombreComercio:string) {
       try {
         let params = new HttpParams()
         .set('pIdSitio',this.idSitio.toString())
         .set('pServidorCorreoRemitente',pServidorCorreoRemitente.toString())
         .set('pUsuarioCorreoRemitente',pUsuarioCorreoRemitente.toString())
         .set('pContrasenaCorreoRemitente',pContrasenaCorreoRemitente.toString())
         .set('pNombreMostrarCorreoRemitente',pNombreMostrarCorreoRemitente.toString())
         .set('pCorreoCC',pCorreoCC.toString())
         .set('pCorreoDestino',pCorreoDestino.toString())
         .set('pTituloSeccion',pTituloSeccion.toString())
         .set('pSubtituloSeccion',pSubtituloSeccion.toString())
         .set('pCondiciones',pCondiciones.toString())
         .set('pCodigo',pCodigo.toString())
         .set('pFormaUso',pFormaUso.toString())
         .set('pTituloCupon',pTituloCupon.toString())
         .set('pNombreComercio',pNombreComercio.toString());
         return this.http.get<number>(this.url_base_web_api + 'Beneficios/enviarcopiacuponbeneficio', { params: params });
       }
       catch (Error) {
         return null;
       }
  }

  obtenerListaCuponesAdquiridos(pCorreoUsuario:string) {
    try {
      let params = new HttpParams()
      .set('pCorreoUsuario', pCorreoUsuario.toString())
      .set('pIdSitioAsociado',this.idSitio.toString());
      return this.http.get<cuponAdquirido[]>(this.url_base_web_api + 'Beneficios/cuponesadquiridos', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  buscarComerciosCercanos(pIdProvincia:number,pIdCanton:number,pIdDistrito:number,pLatitud:string,pLongitud:string,pDistanciaKM:number) {
    try {
      let distanciaMetros:number=pDistanciaKM*1000;
      let params = new HttpParams()
      .set('pIdSitio', this.idSitio.toString())
      .set('pIdProvincia',pIdProvincia.toString())
      .set('pIdCanton',pIdCanton.toString())
      .set('pIdDistrito',pIdDistrito.toString())
      .set('pLatitud',pLatitud.toString())
      .set('pLongitud',pLongitud.toString())
      .set('pRangoMetros',distanciaMetros.toString());
      return this.http.get<sucursalComercioCercano[]>(this.url_base_web_api + 'Beneficios/consultarsucursalescomerciosubicaciongeograficabeneficios', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

}
