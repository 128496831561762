import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriasComponent } from './categorias/categorias.component';
import { ListaElementosCategoriaComponent } from './lista-elementos-categoria/lista-elementos-categoria.component';
import { DetalleBeneficioComponent } from './detalle-beneficio/detalle-beneficio.component';
import { BeneficiosComponent } from './beneficios.component';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BarraProductoModule } from '../barra-producto/barra-producto.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import {FooterMenuModule} from '../footer-menu/footer-menu.module';
import {CarruselGenericoModule} from '../carrusel-generico/carrusel-generico.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {CuponAdquiridoModule} from '../cupon-adquirido/cupon-adquirido.module';

@NgModule({
  declarations: [CategoriasComponent, ListaElementosCategoriaComponent, DetalleBeneficioComponent,BeneficiosComponent],
  imports: [
    CommonModule, RouterModule, MatGridListModule,  MatPaginatorModule,FormsModule,MatFormFieldModule,MatTooltipModule
    ,FooterMenuModule, BarraProductoModule,FontAwesomeModule,MatButtonModule,CarruselGenericoModule,MatInputModule,MatSelectModule,CuponAdquiridoModule
  ],
  exports: [CategoriasComponent, ListaElementosCategoriaComponent, DetalleBeneficioComponent,BeneficiosComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BeneficiosModule { }
