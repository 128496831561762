import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA,  Inject ,Output, EventEmitter} from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { URL_BASE_IMG_CATEGORIA_MARKET } from '../../../global-variables';
import { categoriaMarket,referenciaCategoriaComercioMarket } from '../../../interfaces';
import { MarketService} from 'src/app/services/market.service';

@Component({
  selector: 'app-categorias-market',
  templateUrl: './categorias-market.component.html',
  styleUrls: ['./categorias-market.component.scss'],
})
export class CategoriasMarketComponent implements OnInit {
  modoResposive:boolean=false;
  url_base_imagenes: string = URL_BASE_IMG_CATEGORIA_MARKET;
  listaCategoriasMarket: categoriaMarket[]=[];
  @Output() categoriaSeleccionada = new EventEmitter<referenciaCategoriaComercioMarket>();
  elementoSeleccionado:referenciaCategoriaComercioMarket;
  constructor(private servicioMarket:MarketService) {
    let ancho:number=window.visualViewport.width;
    if(ancho<=992){
      this.modoResposive=true;
    }
    else{
      this.modoResposive=false;
    }
  }
  
  ngOnInit() {
    let ancho:number=window.visualViewport.width;
    if(ancho<=992){
      this.modoResposive=true;
    }
    else{
      this.modoResposive=false;
    }
    this.servicioMarket.categorias().subscribe(
      next => this.listaCategoriasMarket=next
    )
    
  }

  avisarSeleccion(idCategoria: number, nombreCategoria: string) {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: '',idMarca:0
    };
    this.elementoSeleccionado.idCategoria = idCategoria;
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreCategoria = nombreCategoria;
    this.elementoSeleccionado.nombreComercio = '';
    this.categoriaSeleccionada.emit(this.elementoSeleccionado); 
  }
}