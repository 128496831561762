import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
// import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_BASE_IMG_COMERCIOS_BENEFICIOS } from '../../../global-variables';
import { URL_BASE_IMG_PORTADA_BENEFICIOS } from '../../../global-variables';
import {
  SERVIDOR_CORREO, USUARIO_CORREO_REMITENTE, CONTRASENA_CORREO_REMITENTE,
  NOMBRE_MOSTRAR_CORREO_CUPON_BENEFICIO, CORREO_CC_CUPON_BENEFICIO, URL_IMG_MARCO_BENEFICIO_DETALLE,
  TITULO_MODULO_BENEFICIOS, SUBTITULO_MODULO_BENEFICIOS
} from '../../../global-variables';
import { usuario, telefono, detalleBeneficio, datosAdicionalesUsuario, referenciaCategoriaComercioBeneficio, bannerComercio, cuponAdquirido } from '../../../interfaces';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { BeneficioService } from 'src/app/services/beneficio.service';
import { SessionStorageService } from 'ngx-webstorage';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingController } from '@ionic/angular';
@Component({
  selector: 'app-detalle-beneficio',
  templateUrl: './detalle-beneficio.component.html',
  styleUrls: ['./detalle-beneficio.component.scss'],
})
export class DetalleBeneficioComponent implements OnInit {
  infoUsuario: usuario;
  infoAdicionalUsuario: datosAdicionalesUsuario;
  cuponAdquirido: cuponAdquirido;
  servidorCorreo = SERVIDOR_CORREO;
  usuarioCorreoRemitente = USUARIO_CORREO_REMITENTE;
  contrasenaCorreoRemitente = CONTRASENA_CORREO_REMITENTE;
  nombreMostrarCorreoCuponBeneficio = NOMBRE_MOSTRAR_CORREO_CUPON_BENEFICIO;
  correoCCCuponBeneficio = CORREO_CC_CUPON_BENEFICIO;
  titulo_modulo_beneficios = TITULO_MODULO_BENEFICIOS;
  subtitulo_modulo_beneficios = SUBTITULO_MODULO_BENEFICIOS;
  faChevronCircleLeft = faAngleLeft;
  @Output() regreso = new EventEmitter<referenciaCategoriaComercioBeneficio>();
  @Output() avisoCuponAdquirido = new EventEmitter<cuponAdquirido>();
  @Input() elementoSeleccionado: referenciaCategoriaComercioBeneficio = {
    idCategoria: 0, idComercio: 0,
    nombreComercio: '', nombreCategoria: ''
  };
  prefijoMapa: string = 'https://www.google.com/maps/?q=';
  concatenacionMapa: string = '+';
  prefijoCallTo: string = 'tel:+506';
  prefijoMailTo: string = 'mailto:';
  prefijoWeb: string = 'http://www.';
  listaTelefonos: telefono[];
  url_base_imagenes_comercios: string = URL_BASE_IMG_COMERCIOS_BENEFICIOS;
  url_base_imagenes_portada: string = URL_BASE_IMG_PORTADA_BENEFICIOS;
  url_imagen_marco_beneficio_detalle: string = URL_IMG_MARCO_BENEFICIO_DETALLE;
  idComercio: number = 0;
  nombreCategoria: string = '';
  idCategoria: number = 0;
  datosBeneficio: detalleBeneficio = {
    idComercio: 0, urlImagenPortada: '', urlImagenLogo: '', nombreComercio: '', contactoComercio: '',
    facebook: '', telefono: '', correo: '', web: '', listaBeneficios: [], listaSucursales: []
  };
  rutaBeneficio: string;
  listaBannersComercio: bannerComercio[] = [];
  constructor(public loading: LoadingController,private toastr: ToastrService,private seguridadServicio: SeguridadService, private sessionStore: SessionStorageService, private servicioBeneficios: BeneficioService, private activatedRoute: ActivatedRoute) {
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    this.infoAdicionalUsuario = { puntos: 0, tipoIdentificacion: '' };
    this.cuponAdquirido = {
      id: 0, tituloSeccion: '', subtituloSeccion: '', codigo: '', correoEnvio: '', tituloCupon: '', disclaimer: '', condiciones: '',
      restricciones: '', vigencia: '', fechaFinalizacion: '', urlImagen: '', disponibles: 0, precio: 0, precioCuotas: 0,
      precioPunto: 0, idBeneficio: 0, idProducto: 0, nombreComercio: '', formaUso: '',cantidadArticulos:0,montoPagarEnComercio:0,
      montoPagadoConPuntos:0,montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''
    };
    this.activatedRoute.paramMap.subscribe((parametros: ParamMap) => {
      this.listaBannersComercio = [];
      this.idComercio = parseInt(parametros.get("idComercio")),
        this.nombreCategoria = parametros.get("nombreCategoria"),
        this.idCategoria = parseInt(parametros.get("idCategoria")),
        this.rutaBeneficio = this.idComercio + '/' + this.nombreCategoria + '/' + this.idCategoria;
    });

    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
    }
    if (this.sessionStore.retrieve('infoAdicionalUsuario') != null) {
      this.infoAdicionalUsuario = this.sessionStore.retrieve('infoAdicionalUsuario');
    }
  }
  async mostrarLoading() {
    const loading = await this.loading.create({
      message: 'Espere por favor...',
      duration: 500
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }
  separarTelefonos(datos: string) {
    try {
      var datosSplit = datos.split('\n');
      this.listaTelefonos = null;
      if (datosSplit.length != 0) {
        this.listaTelefonos = [{ numeroMostrar: datosSplit[0], numeroLlamar: datosSplit[0].replace('-', '') }];
        for (let i = 1; i < datosSplit.length; i++) {
          this.listaTelefonos.push({ numeroMostrar: datosSplit[i], numeroLlamar: datosSplit[i].replace('-', '') });
        }
      }
    } catch (e) {

    }

  }

  ngOnInit() {
    if (this.elementoSeleccionado != undefined) {
      this.idComercio = this.elementoSeleccionado.idComercio;
      this.nombreCategoria = this.elementoSeleccionado.nombreCategoria;
      this.idCategoria = this.elementoSeleccionado.idCategoria;
      this.rutaBeneficio = this.idComercio + '/' + this.nombreCategoria + '/' + this.idCategoria;
    }
    this.servicioBeneficios.detalleComercioBeneficio(this.idComercio, this.idCategoria).subscribe(
      next => {
        this.datosBeneficio = next;
        this.servicioBeneficios.obtenerBannersComercio(this.idComercio).subscribe(
          next => {
            this.listaBannersComercio = next;
          }
        )
        this.separarTelefonos(this.datosBeneficio.telefono);
      }
    )
  }

  // avisarRegreso

  avisarSeleccion() {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: ''
    };
    this.elementoSeleccionado.idCategoria = this.idCategoria;
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreCategoria = this.nombreCategoria;
    this.elementoSeleccionado.nombreComercio = '';
    this.regreso.emit(this.elementoSeleccionado);
  }

  comprarCupon(idBeneficio: number) {
    this.mostrarLoading();
    if (this.infoUsuario.usuario != '') {
      this.servicioBeneficios.adquirirCuponBeneficio(idBeneficio, this.infoUsuario.id, this.servidorCorreo,
        this.usuarioCorreoRemitente, this.contrasenaCorreoRemitente, this.nombreMostrarCorreoCuponBeneficio,
        this.correoCCCuponBeneficio, this.infoUsuario.usuario, this.titulo_modulo_beneficios,
        this.subtitulo_modulo_beneficios).subscribe(
          next => {
            this.cuponAdquirido = next;
            this.avisoCuponAdquirido.emit(this.cuponAdquirido);
          }
        )
    }
    else{
      this.toastr.error('Recuerda iniciar sesión en INS en Línea!','Error',{progressBar:true});
    }
  }
}
