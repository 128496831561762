import { Component, OnInit, Inject, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BannerBotonHomeComponent } from './banner-boton-home/banner-boton-home.component';
import { HttpClient } from '@angular/common/http';
import { cuponProducto,portadaProducto, portadaBeneficio, usuario,banner, portadaComercioMarket,datosAdicionalesUsuario } from '../../interfaces';
import { BeneficioService } from 'src/app/services/beneficio.service';
import { TiendaService } from 'src/app/services/tienda.service';
import { Observable, observable } from 'rxjs';
import { URL_BANNER_BOTON_HOME, URL_BASE_IMAGENES_INTERNAS, MODO_WEB, 
  ACTIVAR_MARKET_PLACE, ACTIVAR_TIENDA, ACTIVAR_CUPONES, ACTIVAR_BENEFICIOS,
  TITULO_MODULO_MARKET_PLACE,TITULO_MODULO_TIENDA,TITULO_MODULO_BENEFICIOS,
  TITULO_MODULO_CUPONES,SUBTITULO_MODULO_MARKET_PLACE,SUBTITULO_MODULO_TIENDA,
  SUBTITULO_MODULO_BENEFICIOS,SUBTITULO_MODULO_CUPONES,MOSTRAR_BANNER_INTERMEDIO,MOSTRAR_BANNER_INTERMEDIO_FIJO,MOSTRAR_BANNER_FINAL,
  UTILIZAR_LOGIN_EXTERNO,MOSTRAR_BOTONES_MODULOS_ACTIVOS ,TITULO_TIENDA_PRODUCTOS_NUEVOS,TITULO_TIENDA_PRODUCTOS_MAS_VENDIDOS,
  MOSTRAR_BOTONES_MODULOS_ACTIVOS_SOLO_EN_APP,MOSTRAR_BOTONES_MODULOS_VERTICALMENTE,MOSTRAR_CARRUSEL_TIENDA_HOME,
  MOSTRAR_CARRUSEL_BENEFICIOS_HOME,MOSTRAR_CARRUSEL_MARKET_HOME,MOSTRAR_CARRUSEL_CUPONES_HOME
} from '../../global-variables';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { ClienteExternoService} from 'src/app/services/cliente-externo.service';
import { ModalController } from '@ionic/angular';
import { RegistroComponent } from '../../modules/registro/registro.component';
import { MarketService } from 'src/app/services/market.service';
import {CuponesService}from 'src/app/services/cupones.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
//import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  claseFilaBotonesModulos:string='';
  tituloTiendaProductosNuevos:string=TITULO_TIENDA_PRODUCTOS_NUEVOS;
  tituloTiendaProductosMasVendidos:string=TITULO_TIENDA_PRODUCTOS_MAS_VENDIDOS;
  mostrarBotonesModulosActivos:number=MOSTRAR_BOTONES_MODULOS_ACTIVOS;
  mostrarBotonesModulosActivosSoloApp:number=MOSTRAR_BOTONES_MODULOS_ACTIVOS_SOLO_EN_APP;
  mostrarBotonesModulosVerticalmente:number=MOSTRAR_BOTONES_MODULOS_VERTICALMENTE;
  utilizarLoginExterno:number=UTILIZAR_LOGIN_EXTERNO;
  margenSuperior:string;
  esModoWeb: number = MODO_WEB;
  infoUsuario: usuario;
  infoAdicionalUsuario:datosAdicionalesUsuario;
  mostrarBannerFinal=MOSTRAR_BANNER_FINAL;
  mostrarBannerIntermedioFijo=MOSTRAR_BANNER_INTERMEDIO_FIJO;
  mostrarBannerIntermedio=MOSTRAR_BANNER_INTERMEDIO;  
  mostrarCarruselTienda=MOSTRAR_CARRUSEL_TIENDA_HOME;
  mostrarCarruselMarket=MOSTRAR_CARRUSEL_MARKET_HOME;
  mostrarCarruselBeneficios=MOSTRAR_CARRUSEL_BENEFICIOS_HOME;
  mostrarCarruselCupones=MOSTRAR_CARRUSEL_CUPONES_HOME;
  codIdentificacionRecibida:string;
  identificacionRecibida:string;
  tokenRecibido:string;
  urlBaseImgBotones: string = URL_BANNER_BOTON_HOME;
  urlBaseImagenesInternas: string = URL_BASE_IMAGENES_INTERNAS;
  urlImgRegistro: string = this.urlBaseImgBotones + 'REGISTRO.png';
  activarBeneficios = ACTIVAR_BENEFICIOS;
  activarTienda = ACTIVAR_TIENDA;
  activarMarketPlace = ACTIVAR_MARKET_PLACE;
  activarCupones = ACTIVAR_CUPONES;
  claseBotonesModulosHabilitados: string;
  cantidadModulosHabilitados: number;
  claseDivBotonesModulos: string;
  titulo_modulo_market_place=TITULO_MODULO_MARKET_PLACE;
  titulo_modulo_tienda=TITULO_MODULO_TIENDA;
  titulo_modulo_beneficios=TITULO_MODULO_BENEFICIOS;
  titulo_modulo_cupones=TITULO_MODULO_CUPONES;
  subtitulo_modulo_market_place=SUBTITULO_MODULO_MARKET_PLACE;
  subtitulo_modulo_tienda=SUBTITULO_MODULO_TIENDA;
  subtitulo_modulo_beneficios=SUBTITULO_MODULO_BENEFICIOS;
  subtitulo_modulo_cupones=SUBTITULO_MODULO_CUPONES;

  //////////
  //genera la informacion necesaria para el bannerboton de beneficios y de tienda (las imagenes principales que redirigen a esas dos secciones)
  bannerBotonBeneficios = { urlRedireccion: '../beneficios', imagenBanner: this.urlBaseImgBotones + 'BENEFICIOS.png', titulo: '', subtitulo: '' };
  // bannerBotonTienda = { urlRedireccion: '../tienda', imagenBanner: this.urlBaseImgBotones + 'TIENDA.png', titulo: '', subtitulo: '' };
  bannerBotonTienda = { urlRedireccion: '../tienda', imagenBanner: this.urlBaseImgBotones + 'TIENDA.png', titulo: '', subtitulo: '' };
  bannerBotonMarketPlace = { urlRedireccion: '../market', imagenBanner: this.urlBaseImgBotones + 'MARKET.png', titulo: '', subtitulo: '' };
  // bannerBotonCupones = { urlRedireccion: '../cupones', imagenBanner: this.urlBaseImgBotones + 'CUPONES.png', titulo: '', subtitulo: '' };
  bannerBotonCupones = { urlRedireccion: '../cupones', imagenBanner: this.urlBaseImgBotones + 'CUPONES.png', titulo: '', subtitulo: '' };

  // bannerBotonRegistro = { urlRedireccion: '../registro', imagenBanner:  this.urlBaseImgBotones+'REGISTRO.png' };
  //////////


  //////////
  //genera la lista de beneficios a mostrar en carrusel multiple de beneficios
  beneficiosCarrusel : portadaBeneficio[];
  //////////

  //////////
  //genera la lista de beneficios a mostrar en carrusel multiple de beneficios
  marketCarrusel : portadaComercioMarket[];
  //////////

   //////////
  //genera la lista de productos a mostrar en carrusel multiple de tienda
  tiendaCarrusel : portadaProducto[];
  //////////

 //////////
  //genera la lista de cupones a mostrar en carrusel multiple de cupones
  cuponesCarrusel:cuponProducto[];
  //////////

  //////////
  //variables para cargar las imagenes de los carruseles
  //el banner principal es al inicio del sitio
  //el banner intermedio estará en medio de los carruseles de los dos primeros modulos
  //el banner final estará antes del footer de la pagina
  bannersCarruselPrincipal: banner[] = [];
  bannersCarruselesSecundarios: banner[] = [];
  bannersUtilizados: banner[] = [];
  bannersCarruselIntermedio: banner[] = [];
  bannersCarruselFinal: banner[] = [];
  posicionSeleccionada: number;
  listaPosicionesUtilizadas: number[];
  cantidadPosicionesUsadas: number;
  resulBeneficiosServ: portadaBeneficio[];
  resulTiendaServ: portadaProducto[];
  listaBannersBD: banner[] = [];
  //////////

  // constructor(private servicioBeneficios:BeneficioService,private servicioTienda:TiendaService) {
  //   this.resulBeneficiosServ=this.servicioBeneficios.comerciosCategoriaTop();
  //   this.resulTiendaServ=this.servicioTienda.productosNuevos();
  //   this.cargarBannersTiendaCarrusel();
  // }

  constructor(private router: Router,private activatedRoute: ActivatedRoute,public modalController: ModalController, private sessionStore: SessionStorageService, 
    private servicioSeguridad: SeguridadService, private servicioTienda: TiendaService, private servicioCupones:CuponesService,
    private servicioBeneficio: BeneficioService,private servicioMarket:MarketService,private servicioExterno:ClienteExternoService) {
    this.bannerBotonBeneficios.titulo=this.titulo_modulo_beneficios;
    this.bannerBotonBeneficios.subtitulo=this.subtitulo_modulo_beneficios;
    this.bannerBotonTienda.titulo=this.titulo_modulo_tienda;
    this.bannerBotonTienda.subtitulo=this.subtitulo_modulo_tienda;
    this.bannerBotonMarketPlace.titulo=this.titulo_modulo_market_place;
    this.bannerBotonMarketPlace.subtitulo=this.subtitulo_modulo_market_place;
    this.bannerBotonCupones.titulo=this.titulo_modulo_cupones;
    this.bannerBotonCupones.subtitulo=this.subtitulo_modulo_cupones;
    if(this.mostrarBotonesModulosVerticalmente!=1){
      this.claseFilaBotonesModulos='row';
      this.cantidadModulosHabilitados = this.activarBeneficios + this.activarCupones + this.activarMarketPlace + this.activarTienda;
      switch (this.cantidadModulosHabilitados) {
        case 1:
          this.claseBotonesModulosHabilitados = 'col-12';
          break;
        case 2:
          this.claseBotonesModulosHabilitados = 'col-6';
          break;
        case 3:
          if(window.screen.width<768){
            this.claseBotonesModulosHabilitados = 'col-6';
          }
          else{
            this.claseBotonesModulosHabilitados = 'col-4';
          }
          break;
        case 4:
          if(window.screen.width<768){
            this.claseBotonesModulosHabilitados = 'col-6';
          }
          else{
            this.claseBotonesModulosHabilitados = 'col-3';
          }
          break;
      }
      this.claseDivBotonesModulos = 'contenedorBotonesModulos' + this.cantidadModulosHabilitados;
    }
    else{
      this.claseDivBotonesModulos = 'contenedorBotonesModulosVertical';
      this.claseBotonesModulosHabilitados = 'botonModuloVertical';
    }
    
    //obtiene token de servicios 360
    if (this.sessionStore.retrieve('token') == null) {
      this.servicioSeguridad.obtenerToken().subscribe(
        resp => {
          this.sessionStore.store('token', resp.token);
        }
      )
    }
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0,nombreCompleto:''
    };
    this.infoAdicionalUsuario={puntos:0,tipoIdentificacion:''};
    if(this.esModoWeb==1){
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
  }

  ngOnInit() {
    //registra los valores recibidos en querystring
    this.activatedRoute.queryParams
      .filter(params => params.identificacion)
      .subscribe(params => {
        this.identificacionRecibida = params.identificacion;
        this.codIdentificacionRecibida = params.codIdentificacion;
        this.tokenRecibido = params.token;
        
        // //verifica si el usuario es valido
        if(this.utilizarLoginExterno==1){
          this.servicioExterno.autenticarUsuario(this.codIdentificacionRecibida,this.identificacionRecibida,'',this.tokenRecibido).subscribe(
            resp => {
              //si se obtuvo la informacion del usuario entonces carga la informacion
              if (resp!=null){
                this.infoUsuario=resp;
                this.sessionStore.store('usuario', this.infoUsuario);
                //setea el tipo de identificacion del usuario según la información recibida
                switch(this.codIdentificacionRecibida){
                  case 'F':
                    this.infoAdicionalUsuario.tipoIdentificacion='0';
                    break;
                    case 'J':
                    this.infoAdicionalUsuario.tipoIdentificacion='3';
                    break;
                    case 'D':
                    this.infoAdicionalUsuario.tipoIdentificacion='6';
                    break;
                    case 'JE':
                    this.infoAdicionalUsuario.tipoIdentificacion='7';
                    break;
                    case 'CD':
                    this.infoAdicionalUsuario.tipoIdentificacion='12';
                    break;
                }
                //consulta puntos disponibles del usuario
                this.servicioExterno.consultarPuntos(this.infoAdicionalUsuario.tipoIdentificacion,this.infoUsuario.identificacion,this.infoUsuario.usuario).subscribe(
                  resp => {
                    if(resp!=null){
                      this.infoAdicionalUsuario.puntos=resp;
                      this.sessionStore.store('infoAdicionalUsuario', this.infoAdicionalUsuario);
                    }
                  }
                )
              }
            }
          );
        }
       

      }
    );
   
    //OBTIENE BANNERS A MOSTRAR
    this.servicioTienda.bannersHome().subscribe(
      next => {
        this.listaBannersBD = next;
        if (this.listaBannersBD != undefined) {
          this.cargarBannersCarruseles();
        }
      }
    );

    //OBTIENE BENEFICIOS A MOSTRAR EN CARRUSEL
    if(this.activarBeneficios==1){
      this.servicioBeneficio.comerciosCategoriaTop().subscribe(
        next =>{
          this.beneficiosCarrusel=next;
        }
      );
    }

    //OBTIENE COMERCIOS MARKET PLACE A MOSTRAR EN CARRUSEL
    if(this.activarMarketPlace==1){
      this.servicioMarket.comerciosCategoria(0,0).subscribe(
        next =>{
          this.marketCarrusel=next;
        }
      );
    }

    //OBTIENE PRODUCTOS NUEVOS TIENDA A MOSTRAR EN CARRUSEL
    if(this.activarTienda==1){
      this.servicioTienda.productosNuevos().subscribe(
        next =>{
          this.tiendaCarrusel=next;
        }
      );
    }

      //OBTIENE CUPONES A MOSTRAR EN CARRUSEL
    if(this.activarCupones==1){
      this.servicioCupones.obtenerCuponesCarrusel().subscribe(
        next =>{
          let listaCuponesRecibidos:cuponProducto[]=next;
          this.cuponesCarrusel = listaCuponesRecibidos.filter((e) => {
            return e.cantCuponesDisponibles>0;
          });;
        }
      );
    }
  }



  cargarBannersCarruseles() {
    try {

      this.bannersCarruselPrincipal = this.listaBannersBD.filter((e) => {
        if (e.esSecundario != 1) {
          return e;
        }
      });

      this.bannersCarruselesSecundarios = this.listaBannersBD.filter((e) => {
        if (e.esSecundario == 1) {
          return e;
        }
      });
      let cantidadBannersxCarruselSecundario: number;
      cantidadBannersxCarruselSecundario = Math.trunc(this.bannersCarruselesSecundarios.length / 2);
      this.bannersCarruselIntermedio = null;
      this.posicionSeleccionada = this.getRandomInt(0, (this.bannersCarruselesSecundarios.length - 1));
      this.cantidadPosicionesUsadas = 1;
      this.listaPosicionesUtilizadas = [this.posicionSeleccionada];
      this.bannersCarruselIntermedio = [this.bannersCarruselesSecundarios[this.posicionSeleccionada]];
      while (this.cantidadPosicionesUsadas < cantidadBannersxCarruselSecundario) {
        this.posicionSeleccionada = this.getRandomInt(0, (this.bannersCarruselesSecundarios.length - 1));
        if (!this.listaPosicionesUtilizadas.includes(this.posicionSeleccionada)) {
          this.listaPosicionesUtilizadas.push(this.posicionSeleccionada);
          this.cantidadPosicionesUsadas++;
        }
      }

      this.bannersCarruselFinal = null;
      for (let i = 0; i < this.bannersCarruselesSecundarios.length; i++) {
        if (!this.bannersCarruselIntermedio.includes(this.bannersCarruselesSecundarios[i])) {
          if (this.bannersCarruselFinal == null) {
            this.bannersCarruselFinal = [this.bannersCarruselesSecundarios[i]];
          }
          else {
            this.bannersCarruselFinal.push(this.bannersCarruselesSecundarios[i]);
          }
        }
      }
    } catch (e) {
      // this.bannersCaruselTiendaVisiblesCarruselPrimario = null;
      // if (this.bannersCaruselTienda.length > 0) {
      //   this.bannersCaruselTiendaVisiblesCarruselPrimario = [this.bannersCaruselTienda[0]];
      // }
      // if (this.bannersCaruselTienda.length > 1) {
      //   this.bannersCaruselTiendaVisiblesCarruselPrimario.push(this.bannersCaruselTienda[1]);
      // }
      // if (this.bannersCaruselTienda.length > 2) {
      //   this.bannersCaruselTiendaVisiblesCarruselPrimario.push(this.bannersCaruselTienda[2]);
      // }
    }
  }


  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  async cargarRegistro() {
    const modal = await this.modalController.create({
      component: RegistroComponent
    });
    modal.present();
    modal.onDidDismiss().then(data => {

    })
  }
}
