import { Injectable } from '@angular/core';
import { HttpClient ,HttpParams} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {portadaProducto,infoProductoMarket,portadaProductoMarket,categoriaMarket,portadaComercioMarket,bannerBeneficio,provincia,canton,distrito,bannerComercio, cuponAdquirido, sucursalComercioCercano } from '../interfaces';
import {URL_BASE_WEB_API,ID_SITIO_MARKET_PLACE,CANTIDAD_TOP_COMERCIOS_MARKET_PLACE } from '../global-variables';
const HTTP_OPTIONS={
  headers: new HttpHeaders({'content-type':'application/json','Access-Control-Allow-Origin':'*'})
};

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  httpOptions=HTTP_OPTIONS;
  url_base_web_api:  string  =  URL_BASE_WEB_API;
  idSitio=ID_SITIO_MARKET_PLACE;
  cantidadTopComercios=CANTIDAD_TOP_COMERCIOS_MARKET_PLACE;

  constructor(private http:HttpClient) { }

  categorias(){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<categoriaMarket[]>(this.url_base_web_api+'MarketPlace/categorias',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  comerciosCategoria(pIdCategoria:number,pEsTop:number){
    try{
      let cantidad:number=0;
      if(pEsTop==1){
        cantidad=this.cantidadTopComercios;
      }
      let params = new HttpParams()
      .set('pIdCategoria',pIdCategoria.toString())
      .set('pTopCantidad',cantidad.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<portadaComercioMarket[]>(this.url_base_web_api+'MarketPlace/comercioscategoria',{params:params});
    } 
    catch(Error){
      return null;
    }
  }


  productosComercio(pIdComercio:number){
    try{
      let params = new HttpParams()
      .set('pIdComercio',pIdComercio.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<portadaProductoMarket[]>(this.url_base_web_api+'MarketPlace/productosComercio',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  bannerCategoria(pIdCategoria:number) {
    try {
      let params = new HttpParams()
      .set('pIdCategoria', pIdCategoria.toString())
      .set('pIdSitioMarketPlace', this.idSitio.toString());
      return this.http.get<bannerBeneficio[]>(this.url_base_web_api + 'MarketPlace/bannerscategoria', { params: params });
    }
    catch (Error) {
      return null;
    }
  }


  bannerComercio(pIdComercio:number) {
    try {
      let params = new HttpParams()
      .set('pIdComercio', pIdComercio.toString())
      .set('pIdSitioMarketPlace', this.idSitio.toString());
      return this.http.get<bannerBeneficio[]>(this.url_base_web_api + 'MarketPlace/bannerscomercio', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  filtrarComerciosUbicacion(pIdCategoriaComercio:number,pIdProvincia:number,pIdCanton:number,pIdDistrito:number,pLatitud:string,pLongitud:string) {
    try {
      let params = new HttpParams()
      .set('pIdSitioMarket', this.idSitio.toString())
      .set('pIdCategoriaComercio',pIdCategoriaComercio.toString())
      .set('pIdProvincia',pIdProvincia.toString())
      .set('pIdCanton',pIdCanton.toString())
      .set('pIdDistrito',pIdDistrito.toString())
      .set('pLatitud',pLatitud.toString())
      .set('pLongitud',pLongitud.toString());
      return this.http.get<portadaComercioMarket[]>(this.url_base_web_api + 'MarketPlace/consultarcomerciosubicaciongeografica', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  buscarProductos(pIdMarca: number) {
    try {
      let params = new HttpParams()
        .set('pIdMarca', pIdMarca.toString())
        .set('pIdSitioMarketPlace', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'MarketPlace/buscarproductosmarca', { params: params });
    }
    catch (Error) {
      return null;
    }
  }


  detalleProducto(pIdProducto: number,pIdComercio:number) {
    try {
      let params = new HttpParams()
        .set('pIdProducto', pIdProducto.toString())
        .set('pIdComercio', pIdComercio.toString())
        .set('pIdSitioMarket', this.idSitio.toString());
      return this.http.get<infoProductoMarket>(this.url_base_web_api + 'MarketPlace/detalleProducto', { params: params });
    }
    catch (Error) {
      return null;
    }
  }


  adquirirCuponMarket(pIdProducto:number,pIdUsuario,pServidorCorreoRemitente:string,
    pUsuarioCorreoRemitente:string,pContrasenaCorreoRemitente:string,
     pNombreMostrarCorreoRemitente:string,pCorreoCC:string,pCorreoDestino:string,
     pTituloSeccion:string, pSubtituloSeccion:string,pCantidadPuntos: number,pCodigoTransaccion:string,
     pCantidadArticulos:number,pMontoPagarComercio:number,pIdOrden:number,pIdFormaPagoUtilizada:number,
     pMontoPuntos:number,pMontoCuotas:number,pMontoEnLinea:number,pReferenciaInterna:string) {
     try {
       let params = new HttpParams()
       .set('pIdProducto', pIdProducto.toString())
       .set('pIdUsuario',pIdUsuario.toString())
       .set('pServidorCorreoRemitente',pServidorCorreoRemitente.toString())
       .set('pIdSitioMarketPlace',this.idSitio.toString())
       .set('pUsuarioCorreoRemitente',pUsuarioCorreoRemitente.toString())
       .set('pContrasenaCorreoRemitente',pContrasenaCorreoRemitente.toString())
       .set('pNombreMostrarCorreoRemitente',pNombreMostrarCorreoRemitente.toString())
       .set('pCorreoCC',pCorreoCC.toString())
       .set('pCorreoDestino',pCorreoDestino.toString())
       .set('pTituloSeccion',pTituloSeccion.toString())
       .set('pSubtituloSeccion',pSubtituloSeccion.toString())
       .set('pCantidadPuntos',pCantidadPuntos.toString())
       .set('pCodigoTransaccion',pCodigoTransaccion.toString())
       .set('pCantidadArticulos',pCantidadArticulos.toString())
       .set('pMontoPagarComercio',pMontoPagarComercio.toString())
       .set('pIdOrden',pIdOrden.toString())
       .set('pIdFormaPagoUtilizada',pIdFormaPagoUtilizada.toString())
       .set('pMontoPuntos',pMontoPuntos.toString())
       .set('pMontoCuotas',pMontoCuotas.toString())
       .set('pMontoEnLinea',pMontoEnLinea.toString())
       .set('pReferenciaInterna',pReferenciaInterna.toString());
       return this.http.get<cuponAdquirido>(this.url_base_web_api + 'MarketPlace/adquirircuponmarket', { params: params });
     }
     catch (Error) {
       return null;
     }
   }

   reenviarCuponMarket(pServidorCorreoRemitente:string,
    pUsuarioCorreoRemitente:string,pContrasenaCorreoRemitente:string,
     pNombreMostrarCorreoRemitente:string,pCorreoCC:string,pCorreoDestino:string,
     pTituloSeccion:string,pSubtituloSeccion:string,pCondiciones:String,
     pCodigo:string,pFormaUso:string,pTituloCupon:string,pNombreComercio:string,
     pCantidadArticulos:number,pMontoPagarComercio:number,pIdOrden:number,pIdFormaPagoUtilizada:number,
     pMontoPuntos:number,pMontoCuotas:number,pMontoEnLinea:number,pCantidadPuntos: number) {
     try {
       let params = new HttpParams()
       .set('pIdSitio',this.idSitio.toString())
       .set('pServidorCorreoRemitente',pServidorCorreoRemitente.toString())
       .set('pUsuarioCorreoRemitente',pUsuarioCorreoRemitente.toString())
       .set('pContrasenaCorreoRemitente',pContrasenaCorreoRemitente.toString())
       .set('pNombreMostrarCorreoRemitente',pNombreMostrarCorreoRemitente.toString())
       .set('pCorreoCC',pCorreoCC.toString())
       .set('pCorreoDestino',pCorreoDestino.toString())
       .set('pTituloSeccion',pTituloSeccion.toString())
       .set('pSubtituloSeccion',pSubtituloSeccion.toString())
       .set('pCondiciones',pCondiciones.toString())
       .set('pCodigo',pCodigo.toString())
       .set('pFormaUso',pFormaUso.toString())
       .set('pTituloCupon',pTituloCupon.toString())
       .set('pNombreComercio',pNombreComercio.toString())
       .set('pCantidadArticulos',pCantidadArticulos.toString())
       .set('pMontoPagarComercio',pMontoPagarComercio.toString())
       .set('pIdOrden',pIdOrden.toString())
       .set('pIdFormaPagoUtilizada',pIdFormaPagoUtilizada.toString())
       .set('pMontoPuntos',pMontoPuntos.toString())
       .set('pMontoCuotas',pMontoCuotas.toString())
       .set('pMontoEnLinea',pMontoEnLinea.toString())
       .set('pCantidadPuntos',pCantidadPuntos.toString());
       return this.http.get<number>(this.url_base_web_api + 'MarketPlace/enviarcopiacuponmarket', { params: params });
     }
     catch (Error) {
       return null;
     }
}


   obtenerListaCuponesAdquiridos(pCorreoUsuario:string) {
     try {
       let params = new HttpParams()
       .set('pCorreoUsuario', pCorreoUsuario.toString())
       .set('pIdSitioMarketPlace',this.idSitio.toString());
       return this.http.get<cuponAdquirido[]>(this.url_base_web_api + 'MarketPlace/cuponesadquiridos', { params: params });
     }
     catch (Error) {
       return null;
     }
   }

   buscarProductosCriterios(pCriterioBusqueda: string) {
    try {
      let params = new HttpParams()
        .set('pCriterioBusqueda', pCriterioBusqueda.toString())
        .set('pIdSitioMarketPlace', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'MarketPlace/buscarproductoscriterios', { params: params });
    }
    catch (Error) {
      return null;
    }
  }


  buscarComerciosCercanos(pIdProvincia:number,pIdCanton:number,pIdDistrito:number,pLatitud:string,pLongitud:string,pDistanciaKM:number) {
    try {
      let distanciaMetros:number=pDistanciaKM*1000;
      let params = new HttpParams()
      .set('pIdSitioMarketPlace', this.idSitio.toString())
      .set('pIdProvincia',pIdProvincia.toString())
      .set('pIdCanton',pIdCanton.toString())
      .set('pIdDistrito',pIdDistrito.toString())
      .set('pLatitud',pLatitud.toString())
      .set('pLongitud',pLongitud.toString())
      .set('pRangoMetros',distanciaMetros.toString());
      return this.http.get<sucursalComercioCercano[]>(this.url_base_web_api + 'MarketPlace/consultarsucursalescomerciosubicaciongeograficamarketplace', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

}
