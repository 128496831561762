import { Component, OnInit } from '@angular/core';
import {URL_FACEBOOK_CLIENTE_CORPORATIVO,URL_INSTAGRAM_CLIENTE_CORPORATIVO,
  URL_YOUTUBE_CLIENTE_CORPORATIVO,URL_TWITTER_CLIENTE_CORPORATIVO,URL_LINKEDIN_CLIENTE_CORPORATIVO,URL_WHATSAPP_CLIENTE_CORPORATIVO } from '../../../global-variables';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent implements OnInit {
  alineacion:string='';
  urlFacebook=URL_FACEBOOK_CLIENTE_CORPORATIVO;
  urlInstagram=URL_INSTAGRAM_CLIENTE_CORPORATIVO;
  urlYouTube=URL_YOUTUBE_CLIENTE_CORPORATIVO;
  urlTwitter=URL_TWITTER_CLIENTE_CORPORATIVO;
  urlLinkedin=URL_LINKEDIN_CLIENTE_CORPORATIVO;
  urlWhatsapp=URL_WHATSAPP_CLIENTE_CORPORATIVO;
  copyright :string;
  constructor() { 
    let fechaActual:Date=new Date();
    this.copyright ='© ' + fechaActual.getFullYear().toString() + ' Copyright: ';
    if(screen.width<screen.height){
      this.alineacion='text-center';
    }
    else{
      this.alineacion='text-right';
    }
  }

  ngOnInit() {}

}
