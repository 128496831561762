import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders ,HttpResponse} from '@angular/common/http';
import { observable, BehaviorSubject, throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { canton, direccionUsuario, usuario, LoginRequest,responseRedencionPuntos, respuestaConsultaClienteExterno } from '../interfaces';
import { NumericValueAccessor } from '@ionic/angular';
import { BlockLike } from 'typescript';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { URL_BASE_WEB_API, ID_SITIO_ECOMMERCE,USER_WEB_API_CLIENTE_EXTERNO,PASS_WEB_API_CLIENTE_EXTERNO,CONTRASENA_AUTOMATICA_USUARIO_INTERNO_INS } from '../global-variables';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({'Content-Type':'application/json','Access-Control-Allow-Origin':'*'})
};
@Injectable({
  providedIn: 'root'
})
export class ClienteExternoService {
  httpOptions = HTTP_OPTIONS;
  authSubject = new BehaviorSubject(false);
  idSitio = ID_SITIO_ECOMMERCE;
  url_base_web_api: string = URL_BASE_WEB_API;
  constructor(private http: HttpClient, private sessionStore: SessionStorageService) { }

  autenticarUsuario(pTipoIdentificacion:string,pIdentificacion:string,pUsuario:string,pClave:string) {
      try{
        let params = new HttpParams()
        .set('pTipoIdentificacion',pTipoIdentificacion.toString())
        .set('pIdentificacion',pIdentificacion.toString())
        .set('pUsuario',pUsuario.toString())
        .set('pClave',pClave.toString())
        .set('pIdSitio',this.idSitio.toString())
        .set('pClaveInterna',CONTRASENA_AUTOMATICA_USUARIO_INTERNO_INS.toString())
        .set('pUsuarioWebAPIExterno',USER_WEB_API_CLIENTE_EXTERNO.toString())
        .set('pClaveWebAPIExterno',PASS_WEB_API_CLIENTE_EXTERNO.toString());
        return this.http.get<usuario>(this.url_base_web_api+'Externo/autenticarusuario',{params:params});
      } 
      catch(Error){
        return null;
      }
    }

    consultarPuntos(pTipoIdentificacion:string,pIdentificacion:string,pUsuario:string) {
      try{
        let params = new HttpParams()
        .set('pTipoIdentificacion',pTipoIdentificacion.toString())
        .set('pIdentificacion',pIdentificacion.toString())
        .set('pUsuario',pUsuario.toString())
        .set('pIdSitio',this.idSitio.toString())
        .set('pUsuarioWebAPIExterno',USER_WEB_API_CLIENTE_EXTERNO.toString())
        .set('pClaveWebAPIExterno',PASS_WEB_API_CLIENTE_EXTERNO.toString());
        return this.http.get<number>(this.url_base_web_api+'Externo/consultarpuntos',{params:params});
      } 
      catch(Error){
        return null;
      }
    }


    redimirPuntos(pTipoIdentificacion:string,pIdentificacion:string,pUsuario:string,
      pPuntosRedimir:number,pCodigoReferencia:string,pModulo:string,pDescripcionMovimiento:string) {
      try{
        let params = new HttpParams()
        .set('pTipoIdentificacion',pTipoIdentificacion.toString())
        .set('pIdentificacion',pIdentificacion.toString())
        .set('pUsuario',pUsuario.toString())
        .set('pIdSitio',this.idSitio.toString())
        .set('pPuntosRedimir',pPuntosRedimir.toString())
        .set('pCodigoReferencia',pCodigoReferencia.toString())
        .set('pModulo',pModulo.toString())
        .set('pDescripcionMovimiento',pDescripcionMovimiento.toString())
        .set('pUsuarioWebAPIExterno',USER_WEB_API_CLIENTE_EXTERNO.toString())
        .set('pClaveWebAPIExterno',PASS_WEB_API_CLIENTE_EXTERNO.toString());
        return this.http.get<responseRedencionPuntos>(this.url_base_web_api+'Externo/redimirpuntos',{params:params});
      } 
      catch(Error){
        return null;
      }
    }

}
