import { Component, OnInit, Inject, Input, CUSTOM_ELEMENTS_SCHEMA ,SimpleChange, SimpleChanges } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { portadaProducto } from '../../../interfaces';

@Component({
  selector: 'app-subcategoria',
  templateUrl: './subcategoria.component.html',
  styleUrls: ['./subcategoria.component.scss'],
})
export class SubcategoriaComponent implements OnInit {

  @Input() idSubcategoriaProducto: number;
  @Input() nombreSubcategoriaProducto: string;
  @Input() idCategoriaProducto: number;
  @Input() nombreCategoriaProducto: string;
  @Input() listaProductos: portadaProducto[];
  httpRecibido: HttpClient;
  baseUrlRecibido: string;
  constructor() {
    //this.httpRecibido = http;
    //this.baseUrlRecibido = baseUrl;
  }

  ngOnInit(): void {
    /*this.actualizarListaProductos();*/
  }

  //ngOnChanges(cambios: SimpleChanges) {
  //  if (cambios.idSubcategoriaProducto.currentValue != cambios.idSubcategoriaProducto.previousValue) {
  //    this.actualizarListaProductos();
  //  }
  //}

  //actualizarListaProductos() {
  //  //const params = new HttpParams()
  //  //  .set('pIdSubcategoria', this.idSubcategoriaProducto.toString());
  //  //this.httpRecibido.get<portadaProducto[]>(this.baseUrlRecibido + 'tienda/productosporsubcategoria', { params }).subscribe(result => {
  //  //  this.listaProductos = result;
  //  //}, error => console.error(error));
  //}
}
