import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarruselBeneficiosComponent } from '../carrusel-beneficios/carrusel-beneficios.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BeneficiosModule } from '../beneficios/beneficios.module';

@NgModule({
  declarations: [CarruselBeneficiosComponent],
  imports: [
    CommonModule, RouterModule,  CarouselModule, BeneficiosModule
  ],
  exports: [CarruselBeneficiosComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CarruselBeneficiosModule { }
