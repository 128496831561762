import { Input, Component, Inject, ViewChild, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { cuponProducto } from '../../interfaces';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CuponesService } from 'src/app/services/cupones.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { URL_BANNER_BOTON_HOME, MODO_WEB } from '../../global-variables';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-cupones',
  templateUrl: './cupones.component.html',
  styleUrls: ['./cupones.component.scss'],
})
export class CuponesComponent implements OnInit {
  mostrarCarrito:boolean=false;
  esModoWeb: number = MODO_WEB;
  @Input() cuponComprar: cuponProducto= {
    id: 0, precioPuntos: 0,
    cuotaPrincipal: {
      id: 0, nombre: '', descripcion: '', interes: 0, cantidadCuotas: 0, valorCuota: 0,
      requiereAprobacion: 0, correoAprobacionProducto: '', correoAprobacionFormaPago: '', imagen: '',
      disclaimer: ''
    },
    otrasCuotas: [], productID: 0, codigo: '', valorPunto: 0,
    name: '', classification1ID: 0, subClassificationID: 0,
    image: '', taxPercent: 0, unitDiscount: 0,
    hasSize: 0, costAfterUtility: 0, finalCost: 0,
    nombrePunto: '', precioMillas: 0, valorMilla: 0, nombreMilla: '', cantCuponesDisponibles: 0, cantDiasDisponibilidad: 0,
    cantIniCupones: 0, codigoAutogenerable: 0, condiciones: '', descripcion: '', disponible: 0, fechaFinVisualizacion: '', fechaIniVisualizacion: '',
    formaUso: '', idBanco: 0, idCuponMaestro: 0, idEnteEmisor: 0, idProducto: 0, idTipoCupon: 0, infoAdicional: '', nombre: '', perfilId: 0, siteId: 0, siteIdFormaPago: 0,
    precioUnitario:0,cantidadCuponesComprar:0
  };
  banner: string = URL_BANNER_BOTON_HOME + 'cintillos/tienda.png';
  @Input() idCuponMaestroSeleccionado: number = 0;
  cuponActual: cuponProducto;
  listaCupones: cuponProducto[];
  margenSuperior: string='';

  constructor(private toastr: ToastrService, private router: Router, public modalController: ModalController, private activatedRoute: ActivatedRoute, private servicioCupones: CuponesService) { 
    
  }

  ngOnInit(): void {
    if(this.esModoWeb==1){   
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
    this.inicializarCupones();
  }

  inicializarCupones() {
    if (this.idCuponMaestroSeleccionado == 0) {
      this.obtenerListaCupones();
    }
    this.activatedRoute.params
      .subscribe(
        (updatedParams) => {
          if (updatedParams['idCuponMaestro'] != undefined) {
            this.idCuponMaestroSeleccionado = updatedParams['idCuponMaestro'];
          }
          if (updatedParams['mostrarCarrito'] != undefined) {
            this.mostrarCarrito = updatedParams['mostrarCarrito'];
          }
        }
      );
  }

  obtenerListaCupones() {
    this.servicioCupones.obtenerListaCupones().subscribe(
      result => {
        // let listaCuponesRecibidos:cuponProducto[]=result;
        // this.listaCupones = listaCuponesRecibidos.filter((e) => {
        //   return e.cantCuponesDisponibles>0;
        // });;
        this.listaCupones=result;
      }
    );
    document.querySelector('app-cupones-page').querySelector('ion-content').scrollToTop();
  }


  cuponSeleccionado(t) {
    this.idCuponMaestroSeleccionado = t;
  }

  comprarCupon(t) {
    this.cuponComprar = t;
    this.mostrarCarrito=true;
  }

}
