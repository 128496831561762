import { Input, Component, Inject, ViewChild, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { portadaProducto, referenciaCategoriaSubCategoriaProducto } from '../../interfaces';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { TiendaService } from 'src/app/services/tienda.service';
import { ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { MenuCategoriaProductosComponent } from './menu-categoria-productos/menu-categoria-productos.component';
import { URL_BANNER_BOTON_HOME, MODO_WEB } from '../../global-variables';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.scss'],
})
export class TiendaComponent implements OnInit {
  margenSuperior: string = '';
  esModoWeb: number = MODO_WEB;
  banner: string = URL_BANNER_BOTON_HOME + 'cintillos/tienda.png';
  @Input() idProductoSeleccionado: number = 0;
  mostrar: boolean;
  mostrarBuscador: boolean;
  productosNuevos: portadaProducto[];
  productosMasVendidos: portadaProducto[];
  @Input() elementoSeleccionado: referenciaCategoriaSubCategoriaProducto;
  listaProductos: portadaProducto[];
  listaProductosFiltrados: portadaProducto[];
  constructor(public navCtrl: NavController, private toastr: ToastrService, private router: Router, public modalController: ModalController, private activatedRoute: ActivatedRoute, private tiendaServicio: TiendaService) {
  }

  ngOnInit(): void {
    if (this.esModoWeb == 1) {
      this.margenSuperior = 'margenSuperior';
    }
    else {
      this.margenSuperior = '';
    }
    this.mostrar = false;
    this.mostrarBuscador = false;
    this.elementoSeleccionado = {
      idCategoria: 0, idSubcategoria: 0,
      nombreSubcategoria: '', nombreCategoria: '', idMarca: 0, nombreMarca: ''
    };
    this.inicializarTienda();
  }

  inicializarTienda() {
    this.tiendaServicio.productosNuevos().subscribe(
      result => {
        this.productosNuevos = result;
      }
    )
    this.tiendaServicio.productosMasVendidos().subscribe(
      result => {
        this.productosMasVendidos = result;
      }
    )
    this.activatedRoute.params
      .subscribe(
        (updatedParams) => {
          if (updatedParams['idProducto'] != undefined) {
            this.elementoSeleccionado = {
              idCategoria: 0, idSubcategoria: 0,
              nombreSubcategoria: '', nombreCategoria: '', idMarca: 0, nombreMarca: ''
            };
            this.idProductoSeleccionado = updatedParams['idProducto'];
            
          }
          else if (updatedParams['idCategoria'] == undefined && updatedParams['idSubCategoria'] == undefined && updatedParams['idMarca'] == undefined && updatedParams['idMarca'] == undefined) {
            this.elementoSeleccionado = {
              idCategoria: 0, idSubcategoria: 0,
              nombreSubcategoria: '', nombreCategoria: '', idMarca: 0, nombreMarca: ''
            };
          }
          else if (updatedParams['idMarca'] != undefined) {
            this.elementoSeleccionado = {
              idCategoria: 0, idSubcategoria: 0,
              nombreSubcategoria: '', nombreCategoria: '', idMarca: 0, nombreMarca: ''
            };
            this.elementoSeleccionado.idMarca = updatedParams['idMarca'];
            this.elementoSeleccionado.nombreMarca = updatedParams['nombreMarca'];
            this.mostrar = false;
            this.obtenerListaProductos();
          }
          else if (updatedParams['idCategoria'] != undefined && updatedParams['idSubCategoria'] != undefined) {
            this.elementoSeleccionado = {
              idCategoria: 0, idSubcategoria: 0,
              nombreSubcategoria: '', nombreCategoria: '', idMarca: 0, nombreMarca: ''
            };
            this.elementoSeleccionado.idCategoria = updatedParams['idCategoria'];
            this.elementoSeleccionado.nombreCategoria = updatedParams['nombreCategoria'];
            this.elementoSeleccionado.idSubcategoria = updatedParams['idSubCategoria'];
            this.elementoSeleccionado.nombreSubcategoria = updatedParams['nombreSubCategoria'];
            this.mostrar = false;
            this.obtenerListaProductos();
          }
        }
      );
  }

  ocultar(t) {
    this.mostrar = false;
  }

  async mostrarCategorias() {
    const modal = await this.modalController.create({
      component: MenuCategoriaProductosComponent
    });
    modal.present();
    this.mostrar = true;
    modal.onDidDismiss().then(data => {
      this.elementoSeleccionado = {
        idCategoria: 0, idSubcategoria: 0,
        nombreSubcategoria: '', nombreCategoria: '', idMarca: 0, nombreMarca: ''
      };
      if (data == null) {
        this.inicializarTienda();
      }
      else {
        this.elementoSeleccionado = {
          idCategoria: data.data.idCategoria, idSubcategoria: data.data.idSubcategoria,
          nombreSubcategoria: data.data.nombreSubcategoria, nombreCategoria: data.data.nombreCategoria, idMarca: data.data.idMarca, nombreMarca: data.data.nombreMarca
        };
        this.seleccionInvocada(this.elementoSeleccionado);
      }
    })
  }
  seleccionInvocada(t) {
    this.elementoSeleccionado.idCategoria = t.idCategoria;
    this.elementoSeleccionado.idSubcategoria = t.idSubcategoria;
    this.elementoSeleccionado.nombreSubcategoria = t.nombreSubcategoria;
    this.elementoSeleccionado.nombreCategoria = t.nombreCategoria;
    this.elementoSeleccionado.idMarca = t.idMarca;
    this.elementoSeleccionado.nombreMarca = t.nombreMarca;
    this.mostrar = false;
    this.idProductoSeleccionado = 0;
    this.obtenerListaProductos();
  }

  subCategoriaSeleccionada(t) {
    this.elementoSeleccionado.idCategoria = t.idCategoria;
    this.elementoSeleccionado.idSubcategoria = t.idSubcategoria;
    this.elementoSeleccionado.nombreSubcategoria = t.nombreSubcategoria;
    this.elementoSeleccionado.nombreCategoria = t.nombreCategoria;
    this.mostrar = false;
    this.idProductoSeleccionado = 0;
    this.obtenerListaProductos();
  }

  productoSeleccionado(t) {
    this.idProductoSeleccionado = t;
  }

  obtenerListaProductos() {
    this.listaProductosFiltrados = null;
    // const params = new HttpParams()
    //   .set('pIdSubcategoria', this.elementoSeleccionado.idSubcategoria.toString());
    // this.httpRecibido.get<portadaProducto[]>(this.baseUrlRecibido + 'tienda/productosporsubcategoria', { params }).subscribe(result => {
    //   this.listaProductos = result;
    // }, error => console.error(error));

    if (this.elementoSeleccionado.idMarca != 0 && this.elementoSeleccionado.idMarca != undefined) {
      this.tiendaServicio.productosPorMarca(this.elementoSeleccionado.idMarca).subscribe(
        result => {
          this.listaProductos = result;
        }
      );
    }
    else {
      this.tiendaServicio.productosPorSubcategoria(this.elementoSeleccionado.idSubcategoria).subscribe(
        result => {
          this.listaProductos = result;
        }
      );
    }
    document.querySelector('app-tienda-page').querySelector('ion-content').scrollToTop();
  }

  filtrar(t) {
    this.mostrarBuscador = false;
    this.listaProductosFiltrados = t;
  }

  buscarProducto() {
    this.mostrarBuscador = true;
  }
  ocultarBuscador(t) {
    this.mostrarBuscador = false;
  }


}
