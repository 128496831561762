import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarruselProductosComponent } from './carrusel-productos.component';
/*import { TiendaModuloModule} from '../tienda-modulo/tienda-modulo.module';*/
import { BarraProductoModule } from '../barra-producto/barra-producto.module'

@NgModule({
  declarations: [CarruselProductosComponent],
  imports: [
    CommonModule, RouterModule,  CarouselModule, BarraProductoModule
  ],
  exports: [CarruselProductosComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CarruselProductosModule { }
