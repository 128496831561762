import { Component, Input,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import {  banner,bannerBeneficio,bannerComercio } from '../../../interfaces';

@Component({
  selector: 'app-carrusel-generico',
  templateUrl: './carrusel-generico.component.html',
  styleUrls: ['./carrusel-generico.component.scss'],
  providers: [NgbCarouselConfig]
})
export class CarruselGenericoComponent{
  @Input() bannersBeneficios:bannerBeneficio[];
  @Input() bannersComercios:bannerComercio[];
  @Input() banners:banner[];
  constructor(config: NgbCarouselConfig) {
    config.interval = 3000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.animation = true;
    config.showNavigationIndicators = true;
  }

}
