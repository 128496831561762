import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { usuario, carrito, datosAdicionalesUsuario } from './interfaces';
import { ModalController } from '@ionic/angular';
import { InicioSesionComponent } from './modules/inicio-sesion/inicio-sesion.component';
import { RegistroComponent } from './modules/registro/registro.component';
import { URL_LOGO_ENCABEZADO_MENU_LATERAL, MODO_WEB } from './global-variables';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { NavController } from '@ionic/angular';
import { RecuperarContrasenaComponent } from './modules/recuperar-contrasena/recuperar-contrasena.component';
import { Platform } from '@ionic/angular';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public unsubscribeBackEvent: any;
  esModoWeb: number = MODO_WEB;
  logo: string = URL_LOGO_ENCABEZADO_MENU_LATERAL;
  nombreCompletoCliente: string = '';
  infoUsuario: usuario;
  infoAdicionalUsuario: datosAdicionalesUsuario;
  infocarrito: carrito;
  intervaloValidacionUsuario: number;
  cantidadProductosCarrito: number;

  public appPages = [
    { title: 'Principal', esModal: 0, url: '/home', icon: 'ico-UsuarioVerdeOscuro' },
    { title: 'Buscar', esModal: 0, url: '/buscar', icon: 'ico-LupaVerdeO' },
    { title: 'Mall Virtual', esModal: 0, url: '/tienda', icon: 'ico-CarritoVerdeOscuro' },
    { title: 'Cupones', esModal: 0, url: '/cupones', icon: 'ico-CuponVerde' },
    { title: 'Registrate', esModal: 1, url: 'registro', icon: 'ico-Registrate' },
    { title: 'Iniciar Sesión', esModal: 1, url: 'iniciarSesion', icon: 'ico-IniciodeSesion' },
    { title: 'Recuperar Contraseña', esModal: 1, url: 'recuperarContrasena', icon: 'ico-UsuarioVerdeOscuro' }
    // { title: '¿Quiénes Somos?', esModal: 0, url: '/footer-det/FAQ/¿Quiénes Somos?', icon: 'ico-quienes_somos_blanco' },
  ];

  constructor(private router: Router, private gtmService: GoogleTagManagerService, private platform: Platform, public navCtrl: NavController, public dialog: MatDialog, private sessionStore: SessionStorageService, private toastr: ToastrService, public modalController: ModalController,) {
    gtmService.addGtmToDom();

    this.platform.backButton.subscribeWithPriority(999999999999999999, () => {

      // this.toastr.success('quieres salir?', 'no podrás!!!');
      this.navCtrl.navigateRoot(['home']);

    });
    this.cantidadProductosCarrito = 0;
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    this.infocarrito = { id: 0, listaProductos: null, idUsuario: 0 };
    this.infoAdicionalUsuario = { puntos: 0, tipoIdentificacion: '' };

    if (this.sessionStore.retrieve('usuario') != null) {
      this.actualizarDatosUsuarioLogueado();
    }
    // else {
    //   if (this.esModoWeb != 1) {
    //     this.desplegarModal('iniciarSesion');
    //   }
    // }

  }


  ngOnInit() {

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
    this.platform.backButton.subscribeWithPriority(999999999999999999, () => {

      //this.toastr.success('quieres salir?', 'no podrás!!!');
      this.navCtrl.navigateRoot(['home']);

    });
    //este semaforo se usa para que no se matricule la observacion de los cambios en las variables de sesión si ya existe dicha observacion
    this.sessionStore.observe('usuario')
      .subscribe((value) => {
        if (value != null) {
          this.infoUsuario = value;
          this.actualizarDatosUsuarioLogueado();
        }
      }
      );

    this.sessionStore.observe('infoAdicionalUsuario')
      .subscribe((value) => {
        if (value != null) {
          this.infoAdicionalUsuario = value;
          this.actualizarDatosUsuarioLogueado();
        }
      }
      );

    //este semaforo se usa para que no se matricule la observacion de los cambios en las variables de sesión si ya existe dicha observacion
    // this.sessionStore.observe('infoAdicionalUsuario')
    // .subscribe((value) => {
    //   if (value != null) {
    //     this.infoUsuario = value;
    //     this.nombreCompletoCliente=this.infoUsuario.nombre + ' ' + this.infoUsuario.apellidos
    //   }
    //   else {
    //     this.infoUsuario = {
    //       id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
    //       telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0,nombreCompleto:''
    //     };
    //   }
    //   this.actualizarOpcionesMenu();
    // }
    // );
  }

  actualizarDatosUsuarioLogueado() {
    this.infoUsuario = this.sessionStore.retrieve('usuario');
    this.nombreCompletoCliente = this.infoUsuario.nombre;
    if (this.sessionStore.retrieve('carrito') != null) {
      this.infocarrito = this.sessionStore.retrieve('carrito');
      this.cantidadProductosCarrito = this.infocarrito.listaProductos.length;
    }
    if (this.sessionStore.retrieve('infoAdicionalUsuario') != null) {
      this.infoAdicionalUsuario = this.sessionStore.retrieve('infoAdicionalUsuario');
    }
    this.actualizarOpcionesMenu();
    this.actualizarContadorCarrito();
  }

  actualizarOpcionesMenu() {
    if (this.infoUsuario.id != 0) {
      this.appPages = [
        { title: 'Principal', esModal: 0, url: '/home', icon: 'ico-UsuarioVerdeOscuro' },
        { title: 'Buscar', esModal: 0, url: '/buscar', icon: 'ico-LupaVerdeO' },
        { title: 'Mall Virtual', esModal: 0, url: '/tienda', icon: 'ico-CarritoVerdeOscuro' },
        { title: 'Cupones', esModal: 0, url: '/cupones', icon: 'ico-CuponVerde' },
        { title: 'Carrito', esModal: 0, url: '/carrito', icon: 'ico-CarritoVerdeClaro' },
        { title: 'Salir', esModal: 1, url: 'cerrarSesion', icon: 'ico-Salir' },
      ];
    }
    else {
      this.appPages = [
        { title: 'Principal', esModal: 0, url: '/home', icon: 'ico-UsuarioVerdeOscuro' },
        { title: 'Buscar', esModal: 0, url: '/buscar', icon: 'ico-LupaVerdeO' },
        { title: 'Mall Virtual', esModal: 0, url: '/tienda', icon: 'ico-CarritoVerdeOscuro' },
        { title: 'Cupones', esModal: 0, url: '/cupones', icon: 'ico-CuponVerde' },
        { title: 'Registrate', esModal: 1, url: 'registro', icon: 'ico-Registrate' },
        { title: 'Iniciar Sesión', esModal: 1, url: 'iniciarSesion', icon: 'ico-IniciodeSesion' },
        { title: 'Recuperar Contraseña', esModal: 1, url: 'recuperarContrasena', icon: 'ico-UsuarioVerdeOscuro' }
      ];
    }
  }

  desplegarModal(modalDesplegar: string) {
    if (modalDesplegar == 'iniciarSesion') {
      this.abrirModalInicioSesion();
    }
    if (modalDesplegar == 'registro') {
      this.abrirModalRegistro();
    }
    if (modalDesplegar == 'cerrarSesion') {
      this.cerrarSesion();
    }
    if (modalDesplegar == 'recuperarContrasena') {
      this.abrirModalRecuperarContrasena();
    }
  }

  async abrirModalInicioSesion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '95%';
    const dialogRef = this.dialog.open(InicioSesionComponent, dialogConfig);

  }

  async abrirModalRecuperarContrasena() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '95%';
    const dialogRef = this.dialog.open(RecuperarContrasenaComponent, dialogConfig);

  }

  async abrirModalRegistro() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '95%';
    const dialogRef = this.dialog.open(RegistroComponent, dialogConfig);
  }


  actualizarContadorCarrito() {
    let intervalo = setInterval(() => {
      if (this.sessionStore.retrieve('carrito') != null) {
        this.infocarrito = this.sessionStore.retrieve('carrito');
        this.cantidadProductosCarrito = this.infocarrito.listaProductos.length;
      }
      else {
        this.infocarrito = null;
        this.cantidadProductosCarrito = 0;
      }
    }, 1000);
  }

  cerrarSesion() {
    this.toastr.info('Muchas gracias ' + this.infoUsuario.nombre + ' regresa pronto!', '¡Hasta luego!', { progressBar: true });
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    this.infocarrito = { id: 0, listaProductos: null, idUsuario: 0 };
    this.infoAdicionalUsuario = { puntos: 0, tipoIdentificacion: '' };
    this.sessionStore.clear('usuario');
    this.sessionStore.clear('carrito');
    this.sessionStore.clear('infoAdicionalUsuario')
    this.cantidadProductosCarrito = 0;
    // if (this.esModoWeb != 1) {
    //   this.desplegarModal('iniciarSesion');
    // }
    this.actualizarOpcionesMenu();
    this.navCtrl.navigateRoot(['home']);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }


}
