import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdenCompraCuponComponent } from './orden-compra-cupon.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { CurrencyPipe } from '@angular/common';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxBarcode6Module } from 'ngx-barcode6';


@NgModule({
  declarations: [OrdenCompraCuponComponent],
  imports: [
    CommonModule,  FormsModule,  MatButtonModule, ReactiveFormsModule,
    MatFormFieldModule, FontAwesomeModule,NgxQRCodeModule,NgxBarcode6Module,
    MatTooltipModule, MatTableModule
  ],
  exports: [OrdenCompraCuponComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CurrencyPipe]
})
export class OrdenCompraCuponModule { }
