import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, OnInit, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { URL_IMG_MINIATURA_TIENDA, URL_IMG_DESCUENTO_PRODUCTO } from '../../../global-variables';
import { portadaProducto } from '../../../interfaces';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { faShoppingCart,faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss'],
})
export class ListaProductosComponent implements OnInit {

  @Input() listaDatos: portadaProducto[];
  url_img_miniatura_tienda: string = URL_IMG_MINIATURA_TIENDA;
  url_img_descuento: string = URL_IMG_DESCUENTO_PRODUCTO;
  cantidadColumnas: number;
  relacionAnchoAlto: string;
  @Input() mostrarPaginacion: boolean;
  @Input() esMarketPlace: boolean=false;
  @Output() idProductoSeleccionado = new EventEmitter<number>();
  listaDatosMostrar: portadaProducto[];
  page = 0;
  size = 16;

  constructor(public navCtrl: NavController, private paginator: MatPaginatorIntl) {
    this.paginator.itemsPerPageLabel = "productos por página";
    this.paginator.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return 'No hay productos disponibles';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return 'mostrando productos del ' + (startIndex + 1) + ' al ' + endIndex + ' de un total de ' + length + ' productos';
    };
    this.paginator.nextPageLabel = "página siguiente";
    this.paginator.previousPageLabel = "página anterior";
    this.getData({ pageIndex: this.page, pageSize: this.size });

  }

  ngOnInit(): void {
    this.inicializarLista();
  }

  ngOnChanges(cambios: SimpleChanges) {
    if (cambios.listaDatos.currentValue != cambios.listaDatos.previousValue) {
      this.inicializarLista();
    }
  }

  inicializarLista() {
    this.page = 0;
    //if (this.mostrarPaginacion == true) {
      
    //}
    //else {
    //  this.listaDatosMostrar = this.listaDatos;
    //}
    this.getData({ pageIndex: this.page, pageSize: this.size });
    this.calcularCantidadColumnas(window.innerWidth, window.innerHeight);
    
  }

  onResize(event) {
    this.calcularCantidadColumnas(event.target.innerWidth,event.target.innerHeight);
  }

  calcularCantidadColumnas(pAncho: number, pAlto: number) {
    this.relacionAnchoAlto = '100:130';

    if ((pAncho / pAlto) > 1) {
      if (pAncho >= 0 && pAncho <= 480) {
        this.cantidadColumnas = 2;
      }
      if (pAncho > 480 && pAncho <= 767) {
        this.cantidadColumnas = 2;
      }
      if (pAncho > 767 && pAncho <= 1024) {
        this.cantidadColumnas = 3;
      }
      if (pAncho > 1024) {
        this.cantidadColumnas = 4;
      }
    }
    else {
      if (pAncho >= 0 && pAncho <= 480) {
        this.cantidadColumnas = 2;
        /*this.relacionAnchoAlto = '100:130';*/
      }
      if (pAncho > 480 && pAncho <= 767) {
        this.cantidadColumnas = 2;
        /*this.relacionAnchoAlto = '100:130';*/
      }
      if (pAncho > 767 && pAncho <= 1024) {
        this.cantidadColumnas = 3;
        /*this.relacionAnchoAlto = '100:140';*/

      }
      if (pAncho > 1024) {
        this.cantidadColumnas = 4;
        /*this.relacionAnchoAlto = '100:150';*/
      }
    }
  }

  getData(obj) {
    try {
      let index = 0,
        startingIndex = obj.pageIndex * obj.pageSize,
        endingIndex = startingIndex + obj.pageSize;

      this.listaDatosMostrar = this.listaDatos.filter(() => {
        index++;
        return (index > startingIndex && index <= endingIndex) ? true : false;
      });
      window.scroll(0, 0);
    } catch (e) {
    }
  }

  avisarSeleccionProducto(pIdProducto:number){
    this.idProductoSeleccionado.emit(pIdProducto);
    this.navCtrl.navigateRoot(['tienda/'+pIdProducto]);

  }

}
