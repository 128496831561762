import { Component, OnInit, Input, Inject, SimpleChange, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { usuario, precioCuotas, formaPago, productoOrden, direccionUsuario, ordenCuponesGenerada, resumenCompra, cuponDescuento, datosFormSolicitud, cuponProducto } from '../../../interfaces';
import { VALIDAR_BIN_EN_COMPRA, MODO_WEB, ENMASCARAR_NUMERO_TARJETA_ORDEN, MOSTRAR_FORMULARIO_PREVIO_A_COMPRA, URL_IMG_MINIATURA_TIENDA, IMPUESTO_VALOR_AGREGADO, MONTO_MINIMO_COMPRA } from '../../../global-variables';
import { SessionStorageService } from 'ngx-webstorage';
import { faTrashAlt, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MantenimientoDireccionComponent } from '../../cuenta/mantenimiento-direccion/mantenimiento-direccion.component';
import { SolicitarCreditoComponent } from '../../solicitar-credito/solicitar-credito.component';
import { CuponesService } from 'src/app/services/cupones.service';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { URL_BANNER_BOTON_HOME } from '../../../global-variables';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { LoadingController } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-carrito-cupon',
  templateUrl: './carrito-cupon.component.html',
  styleUrls: ['./carrito-cupon.component.scss'],
})
export class CarritoCuponComponent implements OnInit {
  dataSource = new MatTableDataSource<productoOrden>([]);
  validarBIN = VALIDAR_BIN_EN_COMPRA;
  estiloCeldaCarrito: string = '';
  listaProductos: productoOrden[];
  cuponProductoCarrito: productoOrden = {
    productID: 0, name1: '', name: '', code: '', image: '', unitCost: 0,
    quantity: 0, extendedAmount: 0, discount: 0, type: 0, iD_Ticket_Code: 0, ticket_Code: '',
    details: '', unitDiscount: 0
  };
  @Input() cuponMaestro: cuponProducto = {
    id: 0, precioPuntos: 0,
    cuotaPrincipal: {
      id: 0, nombre: '', descripcion: '', interes: 0, cantidadCuotas: 0, valorCuota: 0,
      requiereAprobacion: 0, correoAprobacionProducto: '', correoAprobacionFormaPago: '', imagen: '',
      disclaimer: ''
    },
    otrasCuotas: [], productID: 0, codigo: '', valorPunto: 0,
    name: '', classification1ID: 0, subClassificationID: 0,
    image: '', taxPercent: 0, unitDiscount: 0,
    hasSize: 0, costAfterUtility: 0, finalCost: 0,
    nombrePunto: '', precioMillas: 0, valorMilla: 0, nombreMilla: '', cantCuponesDisponibles: 0, cantDiasDisponibilidad: 0,
    cantIniCupones: 0, codigoAutogenerable: 0, condiciones: '', descripcion: '', disponible: 0, fechaFinVisualizacion: '', fechaIniVisualizacion: '',
    formaUso: '', idBanco: 0, idCuponMaestro: 0, idEnteEmisor: 0, idProducto: 0, idTipoCupon: 0, infoAdicional: '', nombre: '', perfilId: 0, siteId: 0, siteIdFormaPago: 0,
    precioUnitario: 0, cantidadCuponesComprar: 0
  };
  esModoWeb: number = MODO_WEB;
  enmascararNumeroTarjetaOrden = ENMASCARAR_NUMERO_TARJETA_ORDEN;
  mostrarFormularioPrevioACompra = MOSTRAR_FORMULARIO_PREVIO_A_COMPRA;
  porcentajePagoTarjeta: number = 50;
  listaAniosExpiracion = [];
  numeroTarjeta: string = '';
  tipoTarjeta: string = '';
  mesExpiracion: string = '';
  anioExpiracion: number = new Date().getFullYear();
  banner: string = URL_BANNER_BOTON_HOME + 'cintillos/tienda.png';
  ordenGenerada: ordenCuponesGenerada;
  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faCheck = faCheck;
  url_img_miniatura_tienda: string = URL_IMG_MINIATURA_TIENDA;
  montoMinimoCompra: number = MONTO_MINIMO_COMPRA;
  iva: number = IMPUESTO_VALOR_AGREGADO;
  infoUsuario: usuario;
  codigoDescuento: string;
  cuotaSeleccionada: string;
  montoCupones: number = 0;
  totales: resumenCompra;
  cuponValidado: cuponDescuento;
  formaPagoActual: formaPago;
  formasPago: formaPago[] = [];
  montoTotal: number;
  mostrarOrdenFinalizada: boolean;
  columnasProductos: string[] = ['image', 'name', 'quantity', 'unitCost', 'unitDiscount', 'extendedAmount', "eliminar"];
  options: Options = {
    floor: 0,
    ceil: 100,
    boundPointerLabels: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "pago con tarjeta: " + value + "%";
        case LabelType.High:
          return "₡" + value;
        default:
          return value + "%";
      }

    }
  };
  cargando: boolean = false;
  margenSuperior: string;

  constructor(private router: Router,public loading: LoadingController, private servicioCupones: CuponesService, public dialog: MatDialog, private sessionStore: SessionStorageService,
    private toastr: ToastrService, private seguridadServicio: SeguridadService) {
    this.mostrarOrdenFinalizada = false;
    this.totales = { subTotal: 0, shipping: 0, descuento: 0, impuesto: 0, total: 0 };
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
      if (this.infoUsuario.id != 0) {
        this.inicializar();
      }
    }
    else {
      toastr.info('Por favor inicia sesión o registrate para continuar con la compra!');
    }

    this.calcularAniosExpiracion();
    window.scroll(0, 0);
  }

  async mostrarLoading() {
    this.cargando = true;
    return await this.loading.create({
      message: 'Procesando, espere por favor...'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.cargando) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async cerrarLoading() {
    this.cargando = false;
    return await this.loading.dismiss().then(() => console.log('cerrado'))
  }

  elevarPotencia(a: number, b: number) {
    return Math.pow(a, b);
  }

  calcularAniosExpiracion() {
    let anioActual = new Date().getFullYear();
    this.listaAniosExpiracion = [{ anio: anioActual }, { anio: anioActual + 1 }, { anio: anioActual + 2 }, { anio: anioActual + 3 }, { anio: anioActual + 4 }, { anio: anioActual + 5 }, { anio: anioActual + 6 }];
  }

  ngOnInit(): void {
    if(this.esModoWeb==1){   
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
    if (window.innerWidth > window.innerHeight) {
      this.estiloCeldaCarrito = '';
    }
    else {
      this.estiloCeldaCarrito = 'celdaResponsive';
    }
    this.sessionStore.observe('usuario')
      .subscribe((value) => {
        this.infoUsuario = value;
        this.inicializar();
      }
      );

    this.cuponProductoCarrito.code = this.cuponMaestro.codigo;
    this.cuponProductoCarrito.name = this.cuponMaestro.name;
    this.cuponProductoCarrito.image = this.cuponMaestro.image;
    this.cuponProductoCarrito.productID = this.cuponMaestro.productID;
    this.cuponProductoCarrito.unitDiscount = this.cuponMaestro.unitDiscount;
    this.cuponProductoCarrito.unitCost = this.cuponMaestro.precioUnitario;
    this.cuponProductoCarrito.extendedAmount = this.cuponMaestro.finalCost;
    this.cuponProductoCarrito.quantity = this.cuponMaestro.cantidadCuponesComprar;
    this.listaProductos = [this.cuponProductoCarrito];
    this.inicializar();
  }

  inicializar() {
    if (window.innerWidth > window.innerHeight) {
      this.estiloCeldaCarrito = '';
    }
    else {
      this.estiloCeldaCarrito = 'celdaResponsive';
    }
    this.dataSource.data = this.listaProductos;
    this.calcularTotales();
    this.consultarFormasPago();
  }

  eliminarProductoCarrito(producto: productoOrden) {
    this.toastr.success('Compra cancelada!', 'Aviso', { progressBar: true });
    this.router.navigate(['cupones/']);
  }
  multiplicarCantidadSubtotal(producto: productoOrden) {
    return producto.quantity * (producto.unitCost - (producto.unitCost / 100 * producto.unitDiscount));
  }

  seleccionPlazo() {

    this.formaPagoActual = this.formasPago.filter((e) => {
      if (e.id.toString() == this.cuotaSeleccionada.toString()) {
        return e;
      }
    })[0];
  }



  consultarFormasPago() {
    if (this.totales.total != null && this.totales.total != 0) {
      if (this.montoMinimoCompra <= this.totales.total) {
        this.servicioCupones.obtenerformaspago(this.totales.total).subscribe(
          result => {
            this.formasPago = result;
            this.formaPagoActual = null;
          }
        );
      }
      else {
        this.formasPago = [];
        this.formaPagoActual = null;
      }
    }
    else {
      if (this.montoMinimoCompra <= this.montoTotal) {
        this.servicioCupones.obtenerformaspago(this.montoTotal).subscribe(
          result => {
            this.formasPago = result;
            this.formaPagoActual = null;
          }
        );
      }
      else {
        this.formasPago = [];
      }
    }
  }


  calcularTotales() {
    let subtotal = (this.cuponProductoCarrito.unitCost * this.cuponProductoCarrito.quantity);
    let descuento = subtotal / 100 * this.cuponMaestro.unitDiscount;
    let impuesto = (subtotal - descuento) / 100 * this.cuponMaestro.taxPercent;
    let total = subtotal - descuento + impuesto;
    this.totales = { descuento: descuento, impuesto: impuesto, shipping: 0, subTotal: subtotal, total: total };
    this.montoTotal = total;
    this.consultarFormasPago();
  }

  cambiarCantidad(producto: productoOrden) {
    if (producto.quantity <= 0) {
      this.eliminarProductoCarrito(producto);
    }
    else {
      this.inicializar();
    }
  }


  aplicarCupon() {
    if (this.infoUsuario.id != 0) {
      this.servicioCupones.validarCuponDescuento(this.infoUsuario.id, this.codigoDescuento, this.totales.total).subscribe(
        result => {
          this.cuponValidado = result;
          if (!this.cuponValidado.resultadoValidacion) {
            this.toastr.error(this.cuponValidado.mensajeValidacion, 'Error', { progressBar: true });
          }
          else {
            this.montoCupones = this.cuponValidado.montoActual;
            this.calcularTotales();
            this.toastr.success('Cupón validado!', 'Aviso', { progressBar: true });
          }
        }
      );
    }
  }



  comprar() {
    this.mostrarLoading();
    if (this.formaPagoActual == null) {
      this.cerrarLoading();
      this.toastr.error('Por favor elija una forma de pago', 'Error', { progressBar: true });
    }
    else {
      if (this.mostrarFormularioPrevioACompra == 1) {
        this.invocarSolicitudCredito();
      }
      else {
        if (this.validarBIN == 1) {
          this.seguridadServicio.validarBIN(this.numeroTarjeta.substring(0, 6)).subscribe(
            result => {
              let resultado = result;
              if (resultado == null) {
                this.cerrarLoading();
                this.toastr.error('Tarjeta inválida, puede solicitar una tarjeta de crédito Promerica <a target="_blank" href="https://www.promerica.fi.cr/banca-de-personas/tarjetas-de-credito/"><strong>AQUÍ</strong></a>', 'Error', { enableHtml: true, progressBar: true });
              }
              else if (resultado.tipoTarjeta == 'Débito' && (this.formaPagoActual.tipo.includes('CUOTA')
                || this.formaPagoActual.tipo.includes('PUNTO')
                || this.formaPagoActual.tipo.includes('MILLA'))) {
                this.cerrarLoading();
                this.toastr.error('Para Tarjeta de Débito su forma de pago debe ser “Pago con Tarjeta de Crédito o Débito”, puede solicitar una tarjeta de crédito Promerica <a target="_blank" href="https://www.promerica.fi.cr/banca-de-personas/tarjetas-de-credito/"><strong>AQUÍ</strong></a>', 'Error', { enableHtml: true, progressBar: true });
                this.consultarFormasPago();
              }
              else {
                this.procesarCompra();
              }
            });
        }
        else {
          this.procesarCompra();
        }
      }
    }
  }

  invocarSolicitudCredito() {
    let datosEnviar: datosFormSolicitud = { monto: this.totales.total, tipoSolicitud: '' };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '50vw';
    dialogConfig.data = datosEnviar;
    const dialogRef = this.dialog.open(SolicitarCreditoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'PROCESADO') {
        this.procesarCompra();
      }
    });
  }

  enmascararTexto(valor: string) {
    let textoReemplazar: string;
    let cantidadCaracteres: number;
    let textoInmutable: string;
    cantidadCaracteres = valor.length - 1;

    textoInmutable = valor.substring(cantidadCaracteres - 4, cantidadCaracteres);
    textoReemplazar = valor.substring(0, cantidadCaracteres - 5);
    textoReemplazar = textoReemplazar.replace(/[0-9]/g, 'X');
    return textoReemplazar + textoInmutable;
  }

  procesarCompra() {
    let compraExitosa: boolean = false;
    let tipoShipping: number = 1;
    let direccionEntrega = 0;
    let detalleDireccion = '';
    let detalleFormaPago: string;

    detalleFormaPago = "Seleccionó pagar con " + this.formaPagoActual.nombre + " equivalentes al total a pagar de ₡" + Math.round((this.totales.total + Number.EPSILON) * 100) / 100 + " colones";

    // forma de pago en cuotas
    if (this.formaPagoActual.tipo.includes('CUOTAS') && !this.formaPagoActual.tipo.includes('CONTADO')) {
      detalleFormaPago += " pagando con " + this.formaPagoActual.cantidadCuotas + " cuotas de " + Math.round((this.formaPagoActual.valorCuota + Number.EPSILON) * 100) / 100 + " colones";
    }

    // forma de pago en puntos o millas
    if ((this.formaPagoActual.tipo == 'PUNTOS' && !this.formaPagoActual.tipo.includes('CONTADO')) || this.formaPagoActual.tipo == 'MILLAS' && !this.formaPagoActual.tipo.includes('CONTADO')) {
      detalleFormaPago += " pagando un monto de " + Math.round((this.totales.total + Number.EPSILON) * 100) / 100 + " por medio de " + Math.round((this.totales.total / this.formaPagoActual.valorPunto)) + " " + this.formaPagoActual.nombreUnidadPunto;
    }

    // forma de pago combinado con millas-tarjeta o con puntos-tarjeta
    if ((this.formaPagoActual.tipo.includes('CONTADO') && this.formaPagoActual.tipo.includes('MILLAS')) || (this.formaPagoActual.tipo.includes('CONTADO') && this.formaPagoActual.tipo.includes('PUNTOS'))) {
      detalleFormaPago += " pagando un monto de " + Math.round(((this.totales.total / 100 * (100 - this.porcentajePagoTarjeta)) + Number.EPSILON) * 100) / 100 + " colones por medio de " + Math.round(((this.totales.total / 100 * (100 - this.porcentajePagoTarjeta) / this.formaPagoActual.valorPunto))) + " " + this.formaPagoActual.nombreUnidadPunto + " y";
    }

    // forma de pago combinado con cuotas
    if (this.formaPagoActual.tipo.includes('CONTADO') && this.formaPagoActual.tipo.includes('CUOTAS')) {
      if (this.formaPagoActual.interes == 0) {
        detalleFormaPago += " pagando un monto de " + Math.round(((this.totales.total / 100 * (100 - this.porcentajePagoTarjeta)) + Number.EPSILON) * 100) / 100 + " colones por medio de " + this.formaPagoActual.cantidadCuotas + " cuotas de " + Math.round((((this.totales.total / 100 * (100 - this.porcentajePagoTarjeta)) / this.formaPagoActual.cantidadCuotas) + Number.EPSILON) * 100) / 100 + " colones y";
      }
      else {
        detalleFormaPago += " pagando un monto de " + Math.round(((this.totales.total / 100 * (100 - this.porcentajePagoTarjeta)) + Number.EPSILON) * 100) / 100 + " colones por medio de " + this.formaPagoActual.cantidadCuotas + " cuotas de " + Math.round((((this.totales.total / 100 * (100 - this.porcentajePagoTarjeta)) / ((1 - this.elevarPotencia(1 + (this.formaPagoActual.interes / 100), -this.formaPagoActual.cantidadCuotas)) / (this.formaPagoActual.interes / 100))) + Number.EPSILON) * 100) / 100 + " colones y";

      }
    }

    // forma de pago que incluya tarjeta
    if (this.formaPagoActual.requiereTarjeta == true) {
      if (this.formaPagoActual.tipo.includes('CONTADO') && (this.formaPagoActual.tipo.includes('CUOTAS') || this.formaPagoActual.tipo.includes('PUNTOS') || this.formaPagoActual.tipo.includes('MILLAS'))) {
        if (this.enmascararNumeroTarjetaOrden == 1) {
          detalleFormaPago += " pagando un monto de " + Math.round(((this.totales.total / 100 * this.porcentajePagoTarjeta) + Number.EPSILON) * 100) / 100 + " colones de contado con su tarjeta de " + this.tipoTarjeta + " &" + this.enmascararTexto(this.numeroTarjeta) + "&, Fecha Vencimiento: " + this.mesExpiracion + "/" + this.anioExpiracion;
        }
        else {
          detalleFormaPago += " pagando un monto de " + Math.round(((this.totales.total / 100 * this.porcentajePagoTarjeta) + Number.EPSILON) * 100) / 100 + " colones de contado con su tarjeta de " + this.tipoTarjeta + " &" + this.numeroTarjeta + "&, Fecha Vencimiento: " + this.mesExpiracion + "/" + this.anioExpiracion;
        }
      }
      else if (this.formaPagoActual.tipo == 'CONTADO') {
        if (this.enmascararNumeroTarjetaOrden == 1) {
          detalleFormaPago += " pagando un monto de " + Math.round((this.totales.total + Number.EPSILON) * 100) / 100 + " colones de contado con su tarjeta de " + this.tipoTarjeta + " &" + this.enmascararTexto(this.numeroTarjeta) + "&, Fecha Vencimiento: " + this.mesExpiracion + "/" + this.anioExpiracion;
        }
        else {
          detalleFormaPago += " pagando un monto de " + Math.round((this.totales.total + Number.EPSILON) * 100) / 100 + " colones de contado con su tarjeta de " + this.tipoTarjeta + " &" + this.numeroTarjeta + "&, Fecha Vencimiento: " + this.mesExpiracion + "/" + this.anioExpiracion;
        }
      }
      else {
        if (this.enmascararNumeroTarjetaOrden == 1) {
          detalleFormaPago += " con su tarjeta de " + this.tipoTarjeta + " &" + this.enmascararTexto(this.numeroTarjeta) + "&, Fecha Vencimiento: " + this.mesExpiracion + "/" + this.anioExpiracion;
        }
        else {
          detalleFormaPago += " con su tarjeta de " + this.tipoTarjeta + " &" + this.numeroTarjeta + "&, Fecha Vencimiento: " + this.mesExpiracion + "/" + this.anioExpiracion;
        }
      }

    }

    //GENERA ORDEN DE COMPRA
    this.servicioCupones.agregarOrdenCompra(this.infoUsuario.id, this.cuponValidado != null ? this.cuponValidado.codigo.toString() : '',
      detalleFormaPago,
      this.formaPagoActual.id, this.cuponValidado != null ? this.cuponValidado.montoActual : 0,
      this.totales.impuesto, this.totales.subTotal, this.totales.shipping, this.totales.total,
      this.totales.descuento, tipoShipping, 0,
      this.infoUsuario.nombre + ' ' + this.infoUsuario.apellidos, this.infoUsuario.identificacion,
      this.infoUsuario.usuario, this.formaPagoActual.nombre, this.cuponMaestro.idProducto, this.cuponMaestro.cantidadCuponesComprar,
      this.cuponMaestro.precioUnitario, this.cuponMaestro.unitDiscount, this.cuponMaestro.precioUnitario / 100 * this.cuponMaestro.taxPercent,
      this.cuponMaestro.name, this.cuponMaestro.id).subscribe(
        result => {
          if (result != null) {
            this.ordenGenerada = result;
            if (this.ordenGenerada.orden.id == 0) {
              compraExitosa = false;
              this.cerrarLoading();
              this.toastr.error('No se pudo procesar su orden de compra! Intente más tarde.', 'Error', { progressBar: true });
            }
            else {
              compraExitosa = true;
            }
            if (compraExitosa) {
              //CONSUME METODO PARA REBAJAR EL CUPON
              if (this.cuponValidado != null) {
                this.servicioCupones.actualizarCuponDescuento(this.infoUsuario.id,
                  this.cuponValidado != null ? this.cuponValidado.codigo.toString() : '',
                  this.cuponValidado != null ? this.cuponValidado.montoActual : 0,
                  this.ordenGenerada.orden.id, this.cuponValidado != null ? this.cuponValidado.montoActual : 0).subscribe(
                    result => {
                      let resultado: boolean = result;
                    });
              }
              this.cerrarLoading();
              this.toastr.success('Se ha enviado un correo electrónico con el detalle de su compra. Orden de compra: ' + this.ordenGenerada.orden.id.toString(), 'Felicidades, compra exitosa!', { progressBar: true });
              this.mostrarOrdenFinalizada = true;
              window.scroll(0, 0);
            }
          }
          else {
            this.cerrarLoading();
            this.toastr.success('No hay cupones disponibles para su compra, por favor revisar la cantidad de cupones disponibles', 'La compra no se pudo realizar!', { progressBar: true });
          }
        });
  }

}
