import { Component, OnInit } from '@angular/core';
import {MODO_WEB, URL_IMAGENES_LOGO,URL_WHATSAPP_CLIENTE_CORPORATIVO } from '../../../global-variables';
@Component({
  selector: 'app-footer-menu-app',
  templateUrl: './footer-menu-app.component.html',
  styleUrls: ['./footer-menu-app.component.scss'],
})
export class FooterMenuAppComponent implements OnInit {
  urlWhatsapp=URL_WHATSAPP_CLIENTE_CORPORATIVO;
  margenSuperior:string;
  esModoWeb: number = MODO_WEB;
  logoDerecho: string = URL_IMAGENES_LOGO + 'LOGO_DERECHO.png';
  constructor() {
    if(this.esModoWeb==1){
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
   }

  ngOnInit() {}

}
