import { Injectable } from '@angular/core';
import { HttpClient ,HttpParams} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {cuponProducto,formaPago,cuponDescuento,ordenCuponesGenerada,ordenPortada } from '../interfaces';
import {URL_BASE_WEB_API,ID_SITIO_ECOMMERCE,CANTIDAD_TOP_CUPONES } from '../global-variables';

const HTTP_OPTIONS={
  headers: new HttpHeaders({'content-type':'application/json','Access-Control-Allow-Origin':'*'})
};
@Injectable({
  providedIn: 'root'
})
export class CuponesService {

  httpOptions=HTTP_OPTIONS;
  url_base_web_api:  string  =  URL_BASE_WEB_API;
  idSitio=ID_SITIO_ECOMMERCE;
  cantidadTopCuponesMostrar=CANTIDAD_TOP_CUPONES;

  constructor(private http:HttpClient) { }

  obtenerCuponesCarrusel(){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString())
      .set('pCantidadElementosMostrar',this.cantidadTopCuponesMostrar.toString());
      return this.http.get<cuponProducto[]>(this.url_base_web_api+'Cupones/cuponescarrusel',{params:params});
    } 
    catch(Error){
      return null;
    }
  }


  obtenerListaCupones(){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<cuponProducto[]>(this.url_base_web_api+'Cupones/listacupones',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  obtenerListaCuponesCriterio(pCriterio:string){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString())
      .set('pCriterio',pCriterio.toString());
      return this.http.get<cuponProducto[]>(this.url_base_web_api+'Cupones/listacuponescriterio',{params:params});
    } 
    catch(Error){
      return null;
    }
  }


  obtenerDetalleCupon(pIdCuponMaestro:number){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString())
      .set('pIdCuponMaestro',pIdCuponMaestro.toString());
      return this.http.get<cuponProducto>(this.url_base_web_api+'Cupones/detallecupon',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  obtenerformaspago(pMontoTotal: number) {
    try {
      let params = new HttpParams()
        .set('pMontoTotal', pMontoTotal.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<formaPago[]>(this.url_base_web_api + 'Cupones/obtenerformaspago', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  validarCuponDescuento(pIdUsuario: number, pCodigoCupon: string, pMontoTotalCompra: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pCodigoCupon', pCodigoCupon.toString())
        .set('pMontoTotalCompra', pMontoTotalCompra.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<cuponDescuento>(this.url_base_web_api + 'Cupones/validarCuponDescuento', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  actualizarCuponDescuento(pIdUsuario: number, pCodigoCupon: string, pMontoDisponible: number,
    pIdOrdenCompra: number, pMontoAplicado: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pCodigoCupon', pCodigoCupon.toString())
        .set('pMontoDisponible', pMontoDisponible.toString())
        .set('pIdOrdenCompra', pIdOrdenCompra.toString())
        .set('pMontoAplicado', pMontoAplicado.toString());
      return this.http.get<boolean>(this.url_base_web_api + 'Cupones/actualizarCuponDescuento', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  agregarOrdenCompra(pIdUsuario: number, pCodigoCupon: string, pDetalleFormaPago: string, pIdFormaPago: number,
    pMontoAplicadoCupon: number, pImpuestos: number, pSubtotal: number, pShipping: number, pTotal: number,
    pDescuento: number, pTipoShipping: number, pIdDireccionEntrega: number, pNombreCliente: string,
    pIdentificacionCliente: string, pCorreoCliente: string,
    pNombreFormaPago: string,pIdProducto:number,pCantidadCupones:number,pPrecioUnitarioCupon:number,pDescuentoCupon:number,
    pImpuestoCupon:number,pNombreCupon:string,pIdCuponMaestro:number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pCodigoCupon', pCodigoCupon.toString())
        .set('pDetalleFormaPago', pDetalleFormaPago.toString())
        .set('pIdFormaPago', pIdFormaPago.toString())
        .set('pMontoAplicadoCupon', pMontoAplicadoCupon.toString())
        .set('pImpuestos', pImpuestos.toString())
        .set('pSubtotal', pSubtotal.toString())
        .set('pShipping', pShipping.toString())
        .set('pTotal', pTotal.toString())
        .set('pDescuento', pDescuento.toString())
        .set('pTipoShipping', pTipoShipping.toString())
        .set('pIdDireccionEntrega', pIdDireccionEntrega.toString())
        .set('pNombreCliente', pNombreCliente.toString())
        .set('pIdentificacionCliente', pIdentificacionCliente.toString())
        .set('pCorreoCliente', pCorreoCliente.toString())
        //.set('pTelefonoCliente', pTelefonoCliente.toString())
        //.set('pDetalleDireccionEntrega', pDetalleDireccionEntrega.toString())
        .set('pNombreFormaPago', pNombreFormaPago.toString())
        .set('pIdSitio', this.idSitio.toString())
        .set('pIdProducto', pIdProducto.toString())
        .set('pCantidadCupones', pCantidadCupones.toString())
        .set('pPrecioUnitarioCupon', pPrecioUnitarioCupon.toString())
        .set('pDescuentoCupon', pDescuentoCupon.toString())
        .set('pImpuestoCupon', pImpuestoCupon.toString())
        .set('pNombreCupon', pNombreCupon.toString())
        .set('pIdCuponMaestro', pIdCuponMaestro.toString());
      return this.http.get<ordenCuponesGenerada>(this.url_base_web_api + 'Cupones/agregarordencompra', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerDetalleOrden(pIdOrden: number) {
    try {
      let params = new HttpParams()
        .set('pIdOrden', pIdOrden.toString());
      // .set('pIdSitio',this.idSitio.toString());
      return this.http.get<ordenCuponesGenerada>(this.url_base_web_api + 'Cupones/obtenerdetalleorden', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerOrdenesCupones(pIdUsuario: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<ordenPortada[]>(this.url_base_web_api + 'Cupones/obtenerordenescupones', { params: params });
    }
    catch (Error) {
      return null;
    }
  }
}
