import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//import { BeneficiosModule } from './modules/beneficios/beneficios.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'beneficios',
    loadChildren: () => import('./pages/beneficios/beneficios.module').then( m => m.BeneficiosPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'beneficios/:idComercio/:nombreCategoria/:idCategoria',
    loadChildren: () => import('./pages/beneficios/beneficios.module').then( m => m.BeneficiosPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path:'detalleProducto/:idProducto',
    loadChildren: () => import('./pages/tienda/tienda.module').then( m => m.TiendaPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'tienda',
    loadChildren: () => import('./pages/tienda/tienda.module').then( m => m.TiendaPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'tienda/:idCategoria/:nombreCategoria/:idSubCategoria/:nombreSubCategoria',
    loadChildren: () => import('./pages/tienda/tienda.module').then( m => m.TiendaPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'tienda/:idProducto',
    loadChildren: () => import('./pages/tienda/tienda.module').then( m => m.TiendaPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'tienda/:idMarca/:nombreMarca',
    loadChildren: () => import('./pages/tienda/tienda.module').then( m => m.TiendaPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'cupones',
    loadChildren: () => import('./pages/cupones/cupones.module').then( m => m.CuponesPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'cupones/:idCuponMaestro/:mostrarcarrito',
    loadChildren: () => import('./pages/cupones/cupones.module').then( m => m.CuponesPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'cupones/:idCuponMaestro',
    loadChildren: () => import('./pages/cupones/cupones.module').then( m => m.CuponesPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'buscar',
    loadChildren: () => import('./pages/buscar/buscar.module').then( m => m.BuscarPageModule)
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./pages/cuenta/cuenta.module').then( m => m.CuentaPageModule)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./pages/servicios/servicios.module').then( m => m.ServiciosPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'carrito',
    loadChildren: () => import('./pages/carrito/carrito.module').then( m => m.CarritoPageModule)
  },
  {
    path: 'market',
    loadChildren: () => import('./pages/market/market.module').then( m => m.MarketPageModule)
  },
  {
    path: 'market/:idComercio/:idCategoria/:nombreCategoria/:nombreComercio',
    loadChildren: () => import('./pages/market/market.module').then( m => m.MarketPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'market/:idComercio/:idCategoria/:nombreCategoria/:nombreComercio/:idMarca',
    loadChildren: () => import('./pages/market/market.module').then( m => m.MarketPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'market/:idProducto',
    loadChildren: () => import('./pages/market/market.module').then( m => m.MarketPageModule),
    runGuardsAndResolvers:'always'
  },
  {
    path: 'footer-det',
    loadChildren: () => import('./pages/footer-det/footer-det.module').then( m => m.FooterDetPageModule)
  },
  {
    path: 'footer-det/:nombrePagina/:tituloPagina',
    loadChildren: () => import('./pages/footer-det/footer-det.module').then( m => m.FooterDetPageModule)
  },
  {
    path: 'cerca-de-mi',
    loadChildren: () => import('./pages/cerca-de-mi/cerca-de-mi.module').then( m => m.CercaDeMiPageModule)
  },
  {
    path: 'cupones',
    loadChildren: () => import('./pages/cupones/cupones.module').then( m => m.CuponesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation:'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
