import { Component, OnInit,Input,  CUSTOM_ELEMENTS_SCHEMA ,Output,EventEmitter} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { URL_BASE_IMG_PORTADA_BENEFICIOS,URL_IMG_MARCO_BENEFICIO_PORTADA,URL_BASE_IMG_COMERCIOS_BENEFICIOS } from '../../../global-variables'
import { referenciaCategoriaComercioMarket} from '../../../interfaces';

@Component({
  selector: 'app-carrusel-market-place',
  templateUrl: './carrusel-market-place.component.html',
  styleUrls: ['./carrusel-market-place.component.scss'],
})
export class CarruselMarketPlaceComponent implements OnInit {
  @Output() comercioSeleccionado = new EventEmitter<referenciaCategoriaComercioMarket>();
  @Input() listaDatos;
  @Input() tipoElemento;
  url_img_marco_portada: string = URL_IMG_MARCO_BENEFICIO_PORTADA;
  url_base_imagenes_comercios: string = URL_BASE_IMG_COMERCIOS_BENEFICIOS;
  url_base_imagenes_portada: string = URL_BASE_IMG_PORTADA_BENEFICIOS;
  elementoSeleccionado:referenciaCategoriaComercioMarket= {
    idCategoria: 0, idComercio: 0,
    nombreComercio: '', nombreCategoria: '',idMarca:0
  };
  constructor() {
    let hola:string;
    hola='dsads';
   }

  customOptions: OwlOptions = {
    autoWidth: true,
    autoHeight:true,
    loop: true,
    autoplay: true,
    animateIn: true,
    animateOut: true,
    autoplayHoverPause: true,
    lazyLoad: true,
    autoplaySpeed: 500,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    responsiveRefreshRate: 200,
    navSpeed: 900,
    responsive: {
      0: {
        items: 2,
        margin: 5
      },
      480: {
        items: 2,
        margin: 15
      },
      767: {
        items: 3,
        margin: 40
      },
      1024: {
        items: 4,
        margin: 50
      }
    },
    nav: true,
    navText: ['<','>'],
    fluidSpeed: false
  };

  ngOnInit() {
    setTimeout(() => {
      this.customOptions = {
        autoWidth: true,
        autoHeight:true,
        loop: true,
        autoplay: true,
        animateIn: true,
        animateOut: true,
        autoplayHoverPause: true,
        lazyLoad: true,
        autoplaySpeed: 500,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        responsiveRefreshRate: 200,
        navSpeed: 900,
        responsive: {
          0: {
            items: 2,
            margin: 5
          },
          480: {
            items: 2,
            margin: 15
          },
          767: {
            items: 3,
            margin: 40
          },
          1024: {
            items: 4,
            margin: 50
          }
        },
        nav: true,
        navText: ['<','>'],
        fluidSpeed: false
      }
    }, 200);
  }

  avisarSeleccionComercio(pIdComercio:number,pIdMarca:number){
    this.elementoSeleccionado={ idCategoria: 0,nombreCategoria: '' , idComercio:pIdComercio, nombreComercio:'',idMarca:pIdMarca}
    this.comercioSeleccionado.emit(this.elementoSeleccionado);
  }
 
}
