import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarruselCuponesComponent } from '../carrusel-cupones/carrusel-cupones.component';
import { BarraProductoModule } from '../barra-producto/barra-producto.module'

@NgModule({
  declarations: [CarruselCuponesComponent],
  imports: [
    CommonModule, RouterModule,  CarouselModule,BarraProductoModule
  ], 
  exports: [CarruselCuponesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CarruselCuponesModule { }
