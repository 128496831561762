import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InicioSesionComponent } from './inicio-sesion.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { BrowserModule } from '@angular/platform-browser';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [InicioSesionComponent],
  imports: [
    CommonModule, MatDialogModule,
    FormsModule,
    MatInputModule, FontAwesomeModule,
    MatButtonModule, ReactiveFormsModule, MatRadioModule, MatFormFieldModule, MatCheckboxModule,
    BrowserAnimationsModule, ToastrModule,NgxCaptchaModule
  ],
  entryComponents: [InicioSesionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InicioSesionModule { }
