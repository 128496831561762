import { Component, OnInit , Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
  selector: 'app-banner-boton-home',
  templateUrl: './banner-boton-home.component.html',
  styleUrls: ['./banner-boton-home.component.scss'],
})
export class BannerBotonHomeComponent implements OnInit {
  @Input() infoBannerBoton;
  @Input() mostrarTitulos:boolean=false;
  constructor() { }

  ngOnInit() {}

}
