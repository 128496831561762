import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuCategoriaProductosComponent } from '../tienda/menu-categoria-productos/menu-categoria-productos.component';
import { DetalleProductoComponent } from './detalle-producto/detalle-producto.component';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import {FooterMenuModule} from '../footer-menu/footer-menu.module';
import { ListaProductosComponent } from './lista-productos/lista-productos.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { SubcategoriaComponent } from './subcategoria/subcategoria.component';
import { TiendaComponent } from './tienda.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { CarruselProductosModule } from '../carrusel-productos/carrusel-productos.module';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BarraProductoModule} from '../barra-producto/barra-producto.module';
import {PipesModule} from '../pipes/pipes.module'
@NgModule({
  declarations: [TiendaComponent,MenuCategoriaProductosComponent, DetalleProductoComponent, ListaProductosComponent, SubcategoriaComponent, BuscadorComponent, CategoriaComponent],
  imports: [
    CommonModule, FontAwesomeModule, MatFormFieldModule, FormsModule, ReactiveFormsModule , MatInputModule, RouterModule, MatGridListModule,
     MatMenuModule, MatButtonModule, MatExpansionModule,
    MatSidenavModule, MatPaginatorModule, NgxSliderModule, MatSelectModule, NgxImageZoomModule, CarruselProductosModule,
      BarraProductoModule,FooterMenuModule,ToastrModule,PipesModule
  ],
  exports: [ListaProductosComponent,
    MenuCategoriaProductosComponent, DetalleProductoComponent, SubcategoriaComponent,
    CategoriaComponent, BuscadorComponent,TiendaComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TiendaModule { }
