import {Input, Component,ViewChild, OnInit,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import{referenciaCategoriaComercioBeneficio,bannerBeneficio,cuponAdquirido} from '../../interfaces';
import { ActivatedRoute, ParamMap } from '@angular/router';
import{MODO_WEB, URL_BANNER_BOTON_HOME,TITULO_MODULO_BENEFICIOS,SUBTITULO_MODULO_BENEFICIOS,ACTIVAR_BENEFICIOS} from '../../global-variables';
import { Router } from '@angular/router';
import { BeneficioService } from 'src/app/services/beneficio.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss'],
})
export class BeneficiosComponent implements OnInit {
  margenSuperior:string;
  cuponAdquirido:cuponAdquirido;
  listaBannersBeneficiosMostrar:bannerBeneficio[] = [];
  listaBannersBeneficios:bannerBeneficio[] = [];
  activarBeneficios = ACTIVAR_BENEFICIOS;
  titulo_modulo_beneficios=TITULO_MODULO_BENEFICIOS;
  subtitulo_modulo_beneficios=SUBTITULO_MODULO_BENEFICIOS;
  banner:string=URL_BANNER_BOTON_HOME+'cintillos/beneficios.png';
  esModoWeb:number=MODO_WEB;
  @Input() elementoSeleccionado:referenciaCategoriaComercioBeneficio;
  constructor(private toastr: ToastrService,private router: Router,private activatedRoute: ActivatedRoute,private servicioBeneficio: BeneficioService) { 
    if(this.esModoWeb==1){
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
  }

  ngOnInit(): void {
    
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: ''
    };
    this.cuponAdquirido={ id:0,tituloSeccion:'',subtituloSeccion:'',codigo:'',correoEnvio:'',tituloCupon:'',disclaimer:'',condiciones:'',
    restricciones:'',vigencia:'',fechaFinalizacion:'',urlImagen:'',disponibles:0,precio:0,precioCuotas:0,
    precioPunto:0,idBeneficio:0,idProducto:0,nombreComercio:'',formaUso:'',cantidadArticulos:0,montoPagarEnComercio:0,
    montoPagadoConPuntos:0,montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''};

   this.inicializarBeneficios();
  }

  inicializarBeneficios(){
    this.activatedRoute.params
    .subscribe(
      (updatedParams) => {
       if(updatedParams['idComercio']!=undefined && updatedParams['idCategoria']!= undefined && updatedParams['nombreCategoria']!= undefined){
          this.elementoSeleccionado = {
            idCategoria: updatedParams['idCategoria'], idComercio: updatedParams['idComercio'],
            nombreComercio: '', nombreCategoria: updatedParams['nombreCategoria']
          };
          this.seleccionRealizada(this.elementoSeleccionado);
        }
      }
    );

     //OBTIENE BANNERS A MOSTRAR
     this.servicioBeneficio.bannerBeneficio(0).subscribe(
      next => {
        this.listaBannersBeneficios = next;
        if (this.listaBannersBeneficios != undefined) {
          this.cargarBannersCarruseles();
        }
      }
    );
  }

  cargarBannersCarruseles() {
    try {
      this.listaBannersBeneficiosMostrar=[];
      if(this.elementoSeleccionado.idCategoria!=0){
        this.listaBannersBeneficiosMostrar = this.listaBannersBeneficios.filter((e) => {
          if (e.idCategoria == this.elementoSeleccionado.idCategoria) {
            if(window.screen.width<=480){
              e.urlImagenDesktop=e.urlImagenResponsive;
            }
            return e;
          }
        });
      }
      else{
        this.listaBannersBeneficiosMostrar = this.listaBannersBeneficios;
      }

    } catch (e) {
    }
  }

  seleccionRealizada(t) {
    document.querySelector('app-beneficios-page').querySelector('ion-content').scrollToTop();
    this.elementoSeleccionado.idCategoria = t.idCategoria;
    this.elementoSeleccionado.nombreCategoria = t.nombreCategoria;
    this.elementoSeleccionado.idComercio = t.idComercio;
    this.elementoSeleccionado.nombreComercio = t.nombreComercio;
    this.cargarBannersCarruseles();
  }

  adquisicionCupon(t){
    this.cuponAdquirido.codigo=t.codigo;
    this.cuponAdquirido.id=t.id;
    this.cuponAdquirido.tituloSeccion=t.tituloSeccion;
    this.cuponAdquirido.subtituloSeccion=t.subtituloSeccion;
    this.cuponAdquirido.correoEnvio=t.correoEnvio;
    this.cuponAdquirido.tituloCupon=t.tituloCupon;
    this.cuponAdquirido.disclaimer=t.disclaimer;
    this.cuponAdquirido.condiciones=t.condiciones;
    this.cuponAdquirido.restricciones=t.restricciones;
    this.cuponAdquirido.vigencia=t.vigencia;
    this.cuponAdquirido.fechaFinalizacion=t.fechaFinalizacion;
    this.cuponAdquirido.urlImagen=t.urlImagen;
    this.cuponAdquirido.disponibles=t.disponibles;
    this.cuponAdquirido.precio=t.precio;
    this.cuponAdquirido.precioCuotas=t.precioCuotas;
    this.cuponAdquirido.precioPunto=t.precioPunto;
    this.cuponAdquirido.idProducto=t.idProducto;
    this.cuponAdquirido.idBeneficio=t.idBeneficio;
    this.cuponAdquirido.nombreComercio=t.nombreComercio;
    this.cuponAdquirido.formaUso=t.formaUso;
    document.querySelector('app-beneficios-page').querySelector('ion-content').scrollToTop();
    this.elementoSeleccionado.idCategoria = 0;
    this.elementoSeleccionado.nombreCategoria = '';
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreComercio = '';
    this.toastr.success('Su cupón ha sido adquirido satisfactoriamente','¡Felicidades!',{progressBar:true})
  }

  finalizadaAdquisicion(t){
    this.elementoSeleccionado.idCategoria = 0;
    this.elementoSeleccionado.nombreCategoria = '';
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreComercio = '';
    this.cuponAdquirido={ id:0,tituloSeccion:'',subtituloSeccion:'',codigo:'',correoEnvio:'',tituloCupon:'',disclaimer:'',condiciones:'',
    restricciones:'',vigencia:'',fechaFinalizacion:'',urlImagen:'',disponibles:0,precio:0,precioCuotas:0,
    precioPunto:0,idBeneficio:0,idProducto:0,nombreComercio:'',formaUso:'',cantidadArticulos:0,montoPagarEnComercio:0,montoPagadoConPuntos:0,
    montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''};
  }
}
