import { animation } from '@angular/animations';
import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { SessionStorageService } from 'ngx-webstorage';
import { usuario } from '../../interfaces';
import { InicioSesionComponent } from '../inicio-sesion/inicio-sesion.component';
import { RegistroComponent } from '../registro/registro.component';
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  infoUsuario: usuario;
  usuarioLogueado: boolean = false;
  constructor(public dialog: MatDialog,private route: ActivatedRoute,public navCtrl: NavController, private router: Router, private sessionStore: SessionStorageService) {
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0,nombreCompleto:''
    };
    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
      this.usuarioLogueado = true;
    }
  }

  ngOnInit() {
    this.sessionStore.observe('usuario')
      .subscribe((value) => {
        if (value != null) {
          this.infoUsuario = value;
          this.usuarioLogueado = true;
        }
        else {
          this.infoUsuario = {
            id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
            telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0,nombreCompleto:''
          };
          this.usuarioLogueado = false;
        }
      }
      );
  }

  redireccionar(ruta: string) {
   this.navCtrl.navigateRoot([ruta]);
    // if (ruta.includes('tienda') || ruta.includes('beneficio')) {
    //   this.router.navigate([ruta],{replaceUrl:true,skipLocationChange:false,preserveFragment:false});
    // }
    // else {
    //   this.router.navigate([ruta]);
    // }
  }

  desplegarModal(modalDesplegar: string) {
    if (modalDesplegar == 'iniciarSesion') {
      this.abrirModalInicioSesion();
    }
    if (modalDesplegar == 'registro') {
      this.abrirModalRegistro();
    }
  }

  async abrirModalInicioSesion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '95%';
    const dialogRef = this.dialog.open(InicioSesionComponent, dialogConfig);

  }

  async abrirModalRegistro() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '95%';
    const dialogRef = this.dialog.open(RegistroComponent, dialogConfig);
  }

}
