import { Component, OnInit, Inject,Input, CUSTOM_ELEMENTS_SCHEMA,Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { URL_BASE_IMG_COMERCIOS_BENEFICIOS } from '../../../global-variables';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { portadaComercioMarket,referenciaCategoriaComercioBeneficio,canton, provincia, distrito, referenciaCategoriaComercioMarket} from '../../../interfaces';
import { MarketService } from 'src/app/services/market.service';
import { BeneficioService } from 'src/app/services/beneficio.service';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';

@Component({
  selector: 'app-lista-comercios-market',
  templateUrl: './lista-comercios-market.component.html',
  styleUrls: ['./lista-comercios-market.component.scss'],
})
export class ListaComerciosMarketComponent implements OnInit {
  cantones: canton[];
  provincias:provincia[];
  distritos:distrito[];
  faChevronCircleLeft=faAngleLeft;
  cantidadColumnas: number;
  provinciaBuscar:number=0;
  cantonBuscar:number=0;
  distritoBuscar:number=0;
  page = 0;
  size = 8;
  listaMarketMostrar: portadaComercioMarket[]=[];
  listaMarket: portadaComercioMarket[]=[];
  @Output() comercioSeleccionado = new EventEmitter<referenciaCategoriaComercioMarket>();
  elementoSeleccionado:referenciaCategoriaComercioMarket;
  @Input() idCategoria: number=0;
  @Input() nombreCategoria: string='';
  url_base_imagenes_comercios: string = URL_BASE_IMG_COMERCIOS_BENEFICIOS;
  @Input() listaMarketExterna: portadaComercioMarket[];
  constructor(private servicioMarket:MarketService,  private paginator: MatPaginatorIntl,
    private activatedRoute: ActivatedRoute,private servicioBeneficio:BeneficioService) {
    if (this.listaMarketExterna != null) {
      this.listaMarket = this.listaMarketExterna;
      this.getData({ pageIndex: this.page, pageSize: this.size });
    }
    else {
      this.activatedRoute.paramMap.subscribe((parametros: ParamMap) => {
        this.idCategoria = parseInt(parametros.get("idCategoria")),
          this.nombreCategoria = parametros.get("nombreCategoria");
      });
    }
    this.paginator.itemsPerPageLabel = "Comercios por página";
    this.paginator.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return 'No hay comercios disponibles';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return 'mostrando comercios del ' + (startIndex+1) + ' al ' + endIndex + ' de un total de ' + length + ' comercios';
    };
    this.paginator.nextPageLabel = "página siguiente";
    this.paginator.previousPageLabel = "página anterior";
    
  }

  obtenerCantones() {
    this.servicioBeneficio.obtenerCantones(this.provinciaBuscar).subscribe(
      result => {
        this.cantones= result;
        this.distritos=[];
        this.distritoBuscar=0;
      }
    );
  }

  obtenerProvincias() {
    this.servicioBeneficio.obtenerProvincias().subscribe(
      result => {
        this.provincias= result;
        this.provinciaBuscar=0;
        this.cantones=[];
        this.cantonBuscar=0;
        this.distritos=[];
        this.distritoBuscar=0;
      }
    );
  }

  obtenerDistritos() {
    this.servicioBeneficio.obtenerDistritos(this.cantonBuscar).subscribe(
      result => {
        this.distritos= result;
      }
    );
  }

  ngOnInit() {
    this.obtenerProvincias();
    if (this.listaMarketExterna != null) {
      this.listaMarket = this.listaMarketExterna;
      this.getData({ pageIndex: this.page, pageSize: this.size });
    }
    if( this.idCategoria!=0){
      this.servicioMarket.comerciosCategoria(this.idCategoria,0).subscribe(
        next => {
          this.listaMarket=next;
          this.getData({ pageIndex: this.page, pageSize: this.size });
        }
      );
    }
    
    this.calcularCantidadColumnas(window.innerWidth);
  }

  onResize(event) {
    this.calcularCantidadColumnas(event.target.innerWidth);
  }

  calcularCantidadColumnas(pResolucion: number) {
    if (pResolucion >= 0 && pResolucion <= 480) {
      this.cantidadColumnas = 2;
    }
    if (pResolucion > 480 && pResolucion <= 767) {
      this.cantidadColumnas = 2;
    }
    if (pResolucion > 767 && pResolucion <= 1024) {
      this.cantidadColumnas = 3;
    }
    if (pResolucion > 1024) {
      this.cantidadColumnas = 4;
    }
  }

  getData(obj) {
    try {
      let index = 0,
        startingIndex = obj.pageIndex * obj.pageSize,
        endingIndex = startingIndex + obj.pageSize;

      this.listaMarketMostrar = this.listaMarket.filter(() => {
        index++;
        return (index > startingIndex && index <= endingIndex) ? true : false;
      });
    } catch (e) {
    }
  }

  avisarSeleccion(idCategoria: number, nombreCategoria: string,idComercio:number,nombreComercio:string,idMarcaTienda:number) {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: '',idMarca:0
    };
    this.elementoSeleccionado.idCategoria = idCategoria;
    this.elementoSeleccionado.idComercio = idComercio;
    this.elementoSeleccionado.nombreCategoria = nombreCategoria;
    this.elementoSeleccionado.nombreComercio = nombreComercio;
    this.elementoSeleccionado.idMarca=idMarcaTienda;
    this.comercioSeleccionado.emit(this.elementoSeleccionado);
  }

  filtrarLista(){
    this.servicioMarket.filtrarComerciosUbicacion(this.idCategoria,this.provinciaBuscar,this.cantonBuscar,this.distritoBuscar,'','').subscribe(
      next => {
        this.listaMarket=next;
        this.getData({ pageIndex: this.page, pageSize: this.size });
      }
    );
  }

  limpiarFiltros(){
    if( this.idCategoria!=0){
      this.servicioMarket.comerciosCategoria(this.idCategoria,0).subscribe(
        next => {
          this.listaMarket=next;
          this.getData({ pageIndex: this.page, pageSize: this.size });
          this.obtenerProvincias();
        }
      );
    }
  }
}
