import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, SimpleChange, SimpleChanges, Input, SecurityContext,Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { cuponProducto, carrito, usuario, precioCuotas, formaPago,productoOrden } from '../../../interfaces';
import { URL_IMG_DETALLE_PRODUCTO_TIENDA } from '../../../global-variables';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from 'ngx-webstorage';
import { faShoppingCart, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { CuponesService } from 'src/app/services/cupones.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-detalle-cupon',
  templateUrl: './detalle-cupon.component.html',
  styleUrls: ['./detalle-cupon.component.scss'],
})
export class DetalleCuponComponent implements OnInit {

  mostrarCuotas: boolean = false;
  faShoppingCart = faShoppingCart;
  faShoppingBag = faShoppingBag;
  @Input() idCuponMaestro: number = 0;
  @Output() cuponComprar = new EventEmitter<cuponProducto>();
  cupon: cuponProducto = {
    id: 0, precioPuntos: 0,
    cuotaPrincipal: {
      id: 0, nombre: '', descripcion: '', interes: 0, cantidadCuotas: 0, valorCuota: 0,
      requiereAprobacion: 0, correoAprobacionProducto: '', correoAprobacionFormaPago: '', imagen: '',
      disclaimer: ''
    },
    otrasCuotas: [], productID: 0, codigo: '', valorPunto: 0,
    name: '', classification1ID: 0, subClassificationID: 0,
    image: '', taxPercent: 0, unitDiscount: 0,
    hasSize: 0, costAfterUtility: 0, finalCost: 0,
    nombrePunto: '', precioMillas: 0, valorMilla: 0, nombreMilla: '', cantCuponesDisponibles: 0, cantDiasDisponibilidad: 0,
    cantIniCupones: 0, codigoAutogenerable: 0, condiciones: '', descripcion: '', disponible: 0, fechaFinVisualizacion: '', fechaIniVisualizacion: '',
    formaUso: '', idBanco: 0, idCuponMaestro: 0, idEnteEmisor: 0, idProducto: 0, idTipoCupon: 0, infoAdicional: '', nombre: '', perfilId: 0, siteId: 0, siteIdFormaPago: 0,
    precioUnitario:0,cantidadCuponesComprar:0
  };
  cuotaSeleccionada: string;
  cantidad: number;
  modoPantallaHorizontal: boolean;
  imagenActual: SafeUrl;
  url_img_producto: string = URL_IMG_DETALLE_PRODUCTO_TIENDA;
  fechaVencimientoOferta: string;
  fechaVencimientoCupon: string;
  infocarrito: carrito;
  infoUsuario: usuario;
  productoAgregarCarrito: productoOrden;


  constructor(private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private sessionStore: SessionStorageService,
    private servicioCupones: CuponesService) {
    this.infocarrito = { id: 0, listaProductos: [], idUsuario: 0 };

    this.activatedRoute.paramMap.subscribe((parametros: ParamMap) => {
      if (parametros.get("idCuponMaestro") != null) {
        this.idCuponMaestro = parseInt(parametros.get("idCuponMaestro"));
        this.obtenerInfoCupon();
      }
    });

  }


  ngOnInit(): void {
    if (this.idCuponMaestro != 0) {
      this.obtenerInfoCupon();
    }
    // this.obtenerInformacionProducto();
    this.activatedRoute.params.subscribe(
      (updatedParams) => {
        if (updatedParams['idCuponMaestro'] != null) {
          this.idCuponMaestro = updatedParams['idCuponMaestro'];
          this.obtenerInfoCupon();
        }
      }
    );
    this.definirOrientacionImagenes(window.innerWidth, window.innerHeight);
  }

  seleccionPlazo() {
    this.cupon.cuotaPrincipal = this.cupon.otrasCuotas.filter((e) => {
      if (e.id.toString() == this.cuotaSeleccionada.toString()) {
        return e;
      }
    })[0];
  }

  onResize(event) {
    this.definirOrientacionImagenes(event.target.innerWidth, event.target.innerHeight);
  }
  definirOrientacionImagenes(pAncho: number, pAlto: number) {
    if (pAncho > pAlto) {
      this.modoPantallaHorizontal = true;
    }
    else {
      this.modoPantallaHorizontal = false;
    }
  }



  agregarCarrito() {
    this.cupon.cantidadCuponesComprar=this.cantidad;
    this.cuponComprar.emit(this.cupon);
  }

  compraloAqui() {
    this.agregarCarrito();
  }

  compraNoPosible(){
    this.toastr.success('Este cupón se encuentra agotado!', 'Aviso', { progressBar: true });
  }


  obtenerInfoCupon() {
    this.servicioCupones.obtenerDetalleCupon(this.idCuponMaestro).subscribe(
      result => {
        this.cupon = result;
        const fechaPipe: DatePipe = new DatePipe('en-US');
        this.fechaVencimientoOferta = fechaPipe.transform(this.cupon.fechaFinVisualizacion, 'dd/MM/YYYY');
        let fechaValidezCupon:Date=new Date();
        fechaValidezCupon.setDate(fechaValidezCupon.getDate()+ this.cupon.cantDiasDisponibilidad);
        this.fechaVencimientoCupon=fechaPipe.transform(fechaValidezCupon,'dd/MM/YYYY');
        this.cupon.cantidadCuponesComprar=1;
        this.cantidad=1;
        this.definirOrientacionImagenes(window.innerWidth, window.innerHeight);
      }
    );
    document.querySelector('app-cupones-page').querySelector('ion-content').scrollToTop();
  }

}
