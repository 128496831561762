import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarruselGenericoComponent } from './carrusel-generico/carrusel-generico.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CarruselGenericoComponent],
  imports: [
    CommonModule,NgbModule,RouterModule
  ],
  exports: [CarruselGenericoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CarruselGenericoModule { }
