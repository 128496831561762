import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CuponAdquiridoComponent } from './cupon-adquirido/cupon-adquirido.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
@NgModule({
  declarations: [CuponAdquiridoComponent],
  imports: [
    CommonModule,ToastrModule,RouterModule,FontAwesomeModule,MatButtonModule,NgxBarcode6Module,FormsModule,MatInputModule,ReactiveFormsModule,MatFormFieldModule,NgxQRCodeModule
  ],
  exports: [CuponAdquiridoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CuponAdquiridoModule { }
