import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriasMarketComponent } from './categorias-market/categorias-market.component';
import { ListaComerciosMarketComponent} from './lista-comercios-market/lista-comercios-market.component';
import { DetalleMarketComponent } from './detalle-market/detalle-market.component';
import { MarketComponent} from './market.component';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BarraProductoModule } from '../barra-producto/barra-producto.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import {FooterMenuModule} from '../footer-menu/footer-menu.module';
import { CarruselGenericoModule} from '../carrusel-generico/carrusel-generico.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {CuponAdquiridoModule} from '../cupon-adquirido/cupon-adquirido.module';
import { TiendaModule } from '../tienda/tienda.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxImageZoomModule } from 'ngx-image-zoom';



@NgModule({
  declarations: [ListaComerciosMarketComponent,DetalleMarketComponent,MarketComponent,CategoriasMarketComponent],
  imports: [
    CommonModule,ReactiveFormsModule, RouterModule, MatGridListModule,  MatPaginatorModule,FormsModule,MatFormFieldModule,MatTooltipModule
    ,FooterMenuModule, BarraProductoModule,MatExpansionModule,FontAwesomeModule,NgxImageZoomModule,MatButtonModule,CarruselGenericoModule,MatInputModule,MatSelectModule,CuponAdquiridoModule,TiendaModule
  ],
  exports: [ListaComerciosMarketComponent,DetalleMarketComponent,MarketComponent,CategoriasMarketComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MarketModule { }
