import { Component, Input,  Output, EventEmitter, OnInit, SimpleChange, SimpleChanges  } from '@angular/core';
import { portadaProducto } from '../../../interfaces';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss'],
})
export class BuscadorComponent implements OnInit {

  @Output() cerrarDiv = new EventEmitter();
  @Output() avisaFiltrado = new EventEmitter();
  precioMenor: number;
  precioMayor: number;
  clave: string='';
  marca: string='';
  codigo: string='';
  @Input() listaProductos: portadaProducto[];
  listaProductosFiltrada: portadaProducto[];
  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "₡" + value;
        case LabelType.High:
          return "₡" + value;
        default:
          return "₡" + value;
      }
    }
  };
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.limpiar();
  }


  avisarCerrado() {
    this.cerrarDiv.emit(false);
  }

  filtrar() {
    this.listaProductosFiltrada = this.listaProductos.filter((e) => {
      if ((e.name.toUpperCase().includes(this.clave.toUpperCase())) && (e.classification2Name.toUpperCase().includes(this.marca.toUpperCase()))
        && (e.code.toUpperCase().includes(this.codigo.toUpperCase())) && (e.finalCost <= this.precioMayor)
        && (e.finalCost >= this.precioMenor)) {
        return e;
      }
    });
    this.avisaFiltrado.emit(this.listaProductosFiltrada);
  }
  limpiar() {
    this.actualizarSlider();
    this.clave = '';
    this.codigo = '';
    this.marca = '';
    this.avisaFiltrado.emit(null);
  }

  obtenerPrecioMinimo() {
    if (this.listaProductos != null) {
      return Math.min.apply(Math, this.listaProductos.map(function (item) { return item.finalCost; }));
    }
    else {
      return 0;
    }
  }

  obtenerPrecioMaximo() {
    if (this.listaProductos != null) {
      return Math.max.apply(Math, this.listaProductos.map(function (item) { return item.finalCost; }));
    }
    else {
      return 0;
    }
  }

  ngOnChanges(cambios: SimpleChanges) {
    if (cambios.listaProductos.currentValue != cambios.listaProductos.previousValue) {
      this.limpiar();
    }
  }

  actualizarSlider() {
    this.precioMayor = this.obtenerPrecioMaximo();
    this.precioMenor = this.obtenerPrecioMinimo();
    this.options = {
      floor: this.precioMenor,
      ceil: this.precioMayor,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "₡" + value;
          case LabelType.High:
            return "₡" + value;
          default:
            return "₡" + value;
        }
      },
      hideLimitLabels:true
    };
  }

}
