import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OracionPipe } from '../../pipes/oracion.pipe';


@NgModule({
  declarations: [OracionPipe],
  imports: [
    CommonModule
  ],
  exports: [OracionPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PipesModule { }
