import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavMenuComponent } from './nav-menu.component';
import { AppRoutingModule } from '../../app-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge'
// import { MatDialog } from '@angular/material/dialog';
// import { MatDialogConfig } from '@angular/material/dialog';
// import { InicioSesionModule } from '../inicio-sesion/inicio-sesion.module';
// import { RegistroModule } from '../registro/registro.module';
// import {RecuperarContrasenaModule } from '../recuperar-contrasena/recuperar-contrasena.module';
// import { SessionStorageService } from 'ngx-webstorage';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {PipesModule} from '../pipes/pipes.module'
@NgModule({
  declarations: [NavMenuComponent],
  imports: [
    CommonModule,AppRoutingModule,MatMenuModule,FontAwesomeModule,PipesModule,MatBadgeModule
    
  ],

  exports: [NavMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavMenuModule { }
