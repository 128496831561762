import { Component, OnInit,ViewChild, AfterViewInit ,Input, CUSTOM_ELEMENTS_SCHEMA,Output, EventEmitter} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { URL_IMG_MINIATURA_TIENDA,URL_IMG_DESCUENTO_PRODUCTO } from '../../global-variables';

@Component({
  selector: 'app-carrusel-productos',
  templateUrl: './carrusel-productos.component.html',
  styleUrls: ['./carrusel-productos.component.scss'],
})
export class CarruselProductosComponent implements OnInit {
  @Output() idProductoSeleccionado = new EventEmitter<number>();
  @Input() listaDatos;
  @Input() tipoElemento;
  url_img_miniatura_tienda: string = URL_IMG_MINIATURA_TIENDA;
  url_img_descuento: string = URL_IMG_DESCUENTO_PRODUCTO;
  /*@Output() avisarSeleccionProducto = new EventEmitter();*/
  /*idProductoSeleccionado: number;*/
  constructor() {
  }

  customOptions: OwlOptions = {
    // autoWidth: true,
    loop: true,
    autoplay: true,
    animateIn: true,
    animateOut: true,
    autoplayHoverPause: true,
    lazyLoad: true,
    autoplaySpeed: 500,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    responsiveRefreshRate: 200,
    navSpeed: 500,
    responsive: {
      0: {
        items: 2,
        margin: 5
      },
      480: {
        items: 2,
        margin: 15
      },
      767: {
        items: 3,
        margin: 40
      },
      1024: {
        items: 4,
        margin: 50
      }
    },
    nav: true,
    navText: ['<', '>'],
    fluidSpeed: false
  };
  ngOnInit() {
    setTimeout(() => {
      this.customOptions = {
       // autoWidth: true,
        loop: true,
        autoplay: true,
        animateIn: true,
        animateOut: true,
        autoplayHoverPause: true,
        lazyLoad: true,
        autoplaySpeed: 500,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        responsiveRefreshRate: 200,
        navSpeed: 500,
        responsive: {
          0: {
            items: 2,
            margin: 5
          },
          480: {
            items: 2,
            margin: 15
          },
          767: {
            items: 3,
            margin: 40
          },
          1024: {
            items: 4,
            margin: 50
          }
        },
        nav: true,
        navText: ['<', '>'],
        fluidSpeed: false
      }
    }, 200);
    window.dispatchEvent(new Event('resize'));
  }
  
  avisarSeleccionProducto(pIdProducto:number){
    this.idProductoSeleccionado.emit(pIdProducto);
  }

  public ngAfterViewChecked(): void {window.dispatchEvent(new Event('resize'));}

}
