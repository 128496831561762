import { HttpHeaders } from '@angular/common/http';

export const URL_BASE_IMG_CATEGORIA_BENEFICIO: string = './assets/images/categoria_beneficios/';
export const URL_BASE_IMG_CATEGORIA_MARKET: string = './assets/images/categoria_market_place/';
export const URL_BASE_IMG_COMERCIOS_BENEFICIOS: string = 'https://www.pts.cr/images/beneficios/comercios-beneficios/';
export const URL_BASE_IMG_PORTADA_BENEFICIOS: string = 'https://www.pts.cr/images/beneficios/portada-comercio/';
export const URL_IMG_MARCO_BENEFICIO_PORTADA: string = '/assets/images/MARCO_PORTADA_BENEFICIO.png';
export const URL_IMG_MARCO_BENEFICIO_DETALLE: string = '/assets/images/MARCO_DETALLE_BENEFICIO.png';
export const URL_IMG_MINIATURA_TIENDA: string = 'https://www.todoaplazo.com/images/thumbs_300x300/';
export const URL_IMG_DETALLE_PRODUCTO_TIENDA: string = 'https://www.todoaplazo.com/images/products/';
export const URL_IMG_CATEGORIA_TIENDA: string = 'https://todoaplazo.com/images/categories/';
export const URL_IMG_DESCUENTO_PRODUCTO: string = '../../../assets/images/descuentos/';
export const URL_ESTADOS_ORDEN: string = '../../../assets/images/estados_orden/';
export const URL_IMG_ENCABEZADO_ORDEN: string = '../../assets/images/LOGO_BLANCO.png';
export const NOTA_ORDEN: string = 'Hemos recibido su orden. La misma está sujeta a la confirmación del disponible por el monto solicitado a un 100% y a la disponibilidad del artículo. Al ser aceptada o rechazada recibirá una confirmación. Tiempo de entrega 3 DÍAS HÁBILES (de Lunes a Viernes de 8 A.M. a 5 P.M.). Gracias por utilizar nuestros servicios.';
export const NOTA_ORDEN_SECUNDARIA: string = '*Productos especiales serán entregados de 8-10 días hábiles, ver detalles del producto.';
export const URL_DETALLE_PRODUCTO: string = 'http://cert.premiamall.cr/detalleProducto/';
export const URL_DETALLE_BENEFICIO: string = 'http://cert.premiamall.cr/detalleBeneficio/';
export const URL_DETALLE_CUPON:string ='http://cert.premiamall.cr/cupones/'
export const IMPUESTO_VALOR_AGREGADO: number = 13;
export const URL_FACEBOOK_CLIENTE_CORPORATIVO = 'https://www.facebook.com/InstitutoNacionaldeSeguros/'
export const URL_INSTAGRAM_CLIENTE_CORPORATIVO = 'https://www.instagram.com/insjoven/'
export const URL_LOCALIZACION_CLIENTE_CORPORATIVO = '';
export const URL_YOUTUBE_CLIENTE_CORPORATIVO = 'https://www.youtube.com/user/inscomunicaciones9';
export const URL_TWITTER_CLIENTE_CORPORATIVO = 'https://twitter.com/INS_CR';
export const URL_LINKEDIN_CLIENTE_CORPORATIVO = 'https://www.linkedin.com/company/grupoins/';
export const URL_WHATSAPP_CLIENTE_CORPORATIVO = 'https://api.whatsapp.com/send?phone=50684740050&text=Hola%20INS,%20tengo%20una%20consulta';
export const CORREO_CLIENTE_CORPORATIVO = 'info@premiamall.cr'
export const TELEFONO_CLIENTE_CORPORATIVO = '40354141'
export const WHATSAPP_CLIENTE_CORPORATIVO = '50672037861'
export const CLAVE_SERVIDOR_RECAPTCHA = '6LfMVeUfAAAAAADitKnHeaLlWzV9Fnn-180HlOFQ'
export const CLAVE_SECRETA_RECAPTCHA = '6LfMVeUfAAAAANx6AdbW0RHbxphT4S32RbYODicC'
export const ID_SITIO_ECOMMERCE:number = 78
export const ID_CLIENTE_BENEFICIOS:number=0
export const URL_BANNER_BOTON_HOME='../../assets/images/banner_principal_app/'
export const URL_BASE_IMAGENES_INTERNAS='../assets/images/'
export const URL_IMAGENES_LOGO='../../assets/images/logos/'
// export const URL_BASE_WEB_API='https://serviciostap.com/api/'
export const URL_BASE_WEB_API='https://cert.serviciostap.com/api/'
export const URL_LOGO_ENCABEZADO='../assets/images/banner_principal_app/logo.png'
export const URL_LOGO_ENCABEZADO_MENU_LATERAL='../assets/images/banner_principal_app/logo_lateral.png';
// export const URL_BASE_WEB_API='https://pts.cr/ws_ecommerce/'
// export const USER_WEB_API_360='Beneficios-Scotiabank';
// export const PASS_WEB_API_360='Ecommerce_T@p1991*';
export const USER_WEB_API_360='us3r360';
export const PASS_WEB_API_360='t4p20211981!';
export const MONTO_MINIMO_COMPRA=0;
export const MODO_WEB=1;
export const ACTIVAR_MARKET_PLACE=2;
export const ACTIVAR_TIENDA=1;
export const ACTIVAR_BENEFICIOS=0;
export const ACTIVAR_CUPONES=1;
export const TITULO_MODULO_MARKET_PLACE='';
export const TITULO_MODULO_TIENDA='Mall virtual';
export const TITULO_MODULO_BENEFICIOS='';
export const TITULO_MODULO_CUPONES='Cupones digitales';
export const SUBTITULO_MODULO_MARKET_PLACE='';
export const SUBTITULO_MODULO_TIENDA='';
export const SUBTITULO_MODULO_BENEFICIOS='';
export const SUBTITULO_MODULO_CUPONES='';
export const TITULO_TIENDA_PRODUCTOS_NUEVOS='Productos nuevos';
export const TITULO_TIENDA_PRODUCTOS_MAS_VENDIDOS='Productos más vendidos';
export const URL_BASE_BANNER_COMERCIO='';
export const MOSTRAR_BANNER_COMERCIO_BENEFICIOS=1;
export const SERVIDOR_CORREO='190.0.230.55';
export const USUARIO_CORREO_REMITENTE='info@premiamall.cr';
export const CONTRASENA_CORREO_REMITENTE='';
export const NOMBRE_MOSTRAR_CORREO_CUPON_BENEFICIO='Beneficios Premia Mall';
export const CORREO_CC_CUPON_BENEFICIO='rmurillo@todoaplazo.com'
export const CANTIDAD_TOP_COMERCIOS_MARKET_PLACE=20;
export const CANTIDAD_TOP_CUPONES=8;
export const ID_SITIO_MARKET_PLACE=1;
export const NOMBRE_MOSTRAR_CORREO_CUPON_MARKET_PLACE='Market Place Premia Mall';
export const URL_BASE_WEB_API_CLIENTE_EXTERNO='https://apiintegracion.ins-cr.com/v1/';
export const USER_WEB_API_CLIENTE_EXTERNO='3101581795';
export const PASS_WEB_API_CLIENTE_EXTERNO='96c247cb-5eec-45fd-c63b-bf57d202c0bc';
export const MOSTRAR_BANNER_INTERMEDIO=0;
export const MOSTRAR_BANNER_INTERMEDIO_FIJO=0;
export const MOSTRAR_BANNER_FINAL=0;
export const MOSTRAR_CARRUSEL_TIENDA_HOME=1;
export const MOSTRAR_CARRUSEL_BENEFICIOS_HOME=0;
export const MOSTRAR_CARRUSEL_MARKET_HOME=0;
export const MOSTRAR_CARRUSEL_CUPONES_HOME=1;
export const CONTRASENA_AUTOMATICA_USUARIO_INTERNO_INS='1nSUs3R';
export const UTILIZAR_LOGIN_EXTERNO=2;
export const API_KEY_IOS='AIzaSyCHy0RvNjEBWutcoqYdjxgzl7Jfnecfi2M';
export const API_KEY_ANDROID='AIzaSyBQiIOIFwy6tN2uqUCbWeP7TYm90V-s-Fg';
export const API_KEY_WEB='AIzaSyC7qT4NFKh47LpfgWzboOfga6lROwz-P_E';
export const PIN_MARKET_PLACE='../../assets/images/mapa/PIN_MARCADOR_MARKET_PLACE.png';
export const PIN_BENEFICIOS='../../assets/images/mapa/PIN_MARCADOR_BENEFICIOS.png';
export const IMG_GO_TO_COMERCIOS_CERCANOS='../../assets/images/mapa/IMG_GO_TO.png';
export const MOSTRAR_BOTONES_MODULOS_ACTIVOS=2;
export const MOSTRAR_BOTONES_MODULOS_ACTIVOS_SOLO_EN_APP=1;
export const MOSTRAR_BOTONES_MODULOS_VERTICALMENTE=1;
export const MOSTRAR_FORMULARIO_PREVIO_A_COMPRA=2;
export const ENMASCARAR_NUMERO_TARJETA_ORDEN=2;
export const VALIDAR_BIN_EN_COMPRA=1;

