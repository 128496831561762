import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { portadaProducto, cuponDescuento, resumenCompra, carrito, ordenPortada, categoriaProducto, detalleProducto, precioCuotas, orden, banner, formaPago } from '../interfaces';
import { URL_BASE_WEB_API, ID_SITIO_ECOMMERCE } from '../global-variables';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({ 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' })
};
@Injectable({
  providedIn: 'root'
})
export class TiendaService {
  httpOptions = HTTP_OPTIONS;
  url_base_web_api: string = URL_BASE_WEB_API;
  idSitio = ID_SITIO_ECOMMERCE;
  constructor(private http: HttpClient) {

  }

  buscarProductosCriterios(pCriterioBusqueda: string) {
    try {
      let params = new HttpParams()
        .set('pCriterioBusqueda', pCriterioBusqueda.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'Tienda/buscarproductoscriterios', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  agregarOrdenCompra(pIdUsuario: number, pCodigoCupon: string, pDetalleFormaPago: string, pIdFormaPago: number,
    pMontoAplicadoCupon: number, pImpuestos: number, pSubtotal: number, pShipping: number, pTotal: number,
    pDescuento: number, pTipoShipping: number, pIdDireccionEntrega: number, pNombreCliente: string,
    pIdentificacionCliente: string, pCorreoCliente: string, pTelefonoCliente: string,
    pDetalleDireccionEntrega: string, pNombreFormaPago: string) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pCodigoCupon', pCodigoCupon.toString())
        .set('pDetalleFormaPago', pDetalleFormaPago.toString())
        .set('pIdFormaPago', pIdFormaPago.toString())
        .set('pMontoAplicadoCupon', pMontoAplicadoCupon.toString())
        .set('pImpuestos', pImpuestos.toString())
        .set('pSubtotal', pSubtotal.toString())
        .set('pShipping', pShipping.toString())
        .set('pTotal', pTotal.toString())
        .set('pDescuento', pDescuento.toString())
        .set('pTipoShipping', pTipoShipping.toString())
        .set('pIdDireccionEntrega', pIdDireccionEntrega.toString())
        .set('pNombreCliente', pNombreCliente.toString())
        .set('pIdentificacionCliente', pIdentificacionCliente.toString())
        .set('pCorreoCliente', pCorreoCliente.toString())
        .set('pTelefonoCliente', pTelefonoCliente.toString())
        .set('pDetalleDireccionEntrega', pDetalleDireccionEntrega.toString())
        .set('pNombreFormaPago', pNombreFormaPago.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<number>(this.url_base_web_api + 'Tienda/agregarordencompra', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  actualizarCuponDescuento(pIdUsuario: number, pCodigoCupon: string, pMontoDisponible: number,
    pIdOrdenCompra: number, pMontoAplicado: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pCodigoCupon', pCodigoCupon.toString())
        .set('pMontoDisponible', pMontoDisponible.toString())
        .set('pIdOrdenCompra', pIdOrdenCompra.toString())
        .set('pMontoAplicado', pMontoAplicado.toString());
      return this.http.get<boolean>(this.url_base_web_api + 'Tienda/actualizarCuponDescuento', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  validarCuponDescuento(pIdUsuario: number, pCodigoCupon: string, pMontoTotalCompra: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pCodigoCupon', pCodigoCupon.toString())
        .set('pMontoTotalCompra', pMontoTotalCompra.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<cuponDescuento>(this.url_base_web_api + 'Tienda/validarCuponDescuento', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerTotalesCarrito(pIdUsuario: number, pTipoShipping: number, pMontoCupones: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pTipoShipping', pTipoShipping.toString())
        .set('pMontoCupones', pMontoCupones.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<resumenCompra>(this.url_base_web_api + 'Tienda/obtenertotalescarrito', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  modificarCarrito(pIdUsuario: number, pIdProduct: number, pCantidad: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pIdProduct', pIdProduct.toString())
        .set('pCantidad', pCantidad.toString());
      // .set('pIdSitio',this.idSitio.toString());
      return this.http.get<boolean>(this.url_base_web_api + 'Tienda/modificarcarrito', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  eliminarCarrito(pIdUsuario: number, pIdProduct: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pIdProduct', pIdProduct.toString())
      // .set('pIdSitio',this.idSitio.toString());
      return this.http.get<boolean>(this.url_base_web_api + 'Tienda/eliminarcarrito', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  agregarCarrito(pIdUsuario: number, pIdProduct: number, pCantidad: number, pdetalles: string) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pIdProduct', pIdProduct.toString())
        .set('pCantidad', pCantidad.toString())
        .set('pdetalles', pdetalles.toString())
      // .set('pIdSitio',this.idSitio.toString());
      return this.http.get<boolean>(this.url_base_web_api + 'Tienda/agregarcarrito', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerCarrito(pIdUsuario: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString());
      // .set('pIdSitio',this.idSitio.toString());
      return this.http.get<carrito>(this.url_base_web_api + 'Tienda/obtenercarrito', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerDetalleOrden(pIdOrden: number) {
    try {
      let params = new HttpParams()
        .set('pIdOrden', pIdOrden.toString());
      // .set('pIdSitio',this.idSitio.toString());
      return this.http.get<orden>(this.url_base_web_api + 'Tienda/obtenerdetalleorden', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerOrdenesActivas(pIdUsuario: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<ordenPortada[]>(this.url_base_web_api + 'Tienda/obtenerordenesactivas', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerOrdenesFinalizadas(pIdUsuario: number) {
    try {
      let params = new HttpParams()
        .set('pIdUsuario', pIdUsuario.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<ordenPortada[]>(this.url_base_web_api + 'Tienda/obtenerordenesfinalizadas', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  productosPorMarca(pIdMarca: number) {
    try {
      let params = new HttpParams()
        .set('pIdSitio', this.idSitio.toString())
        .set('pIdMarca', pIdMarca.toString())
        .set('pMinimo', 1)
        .set('pMaximo', 100)
        ;
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'Tienda/productospormarca', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  productosMasVendidos() {
    try {
      let params = new HttpParams()
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'Tienda/productosmasvendidos', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  productosRelacionados(pIdProducto: number) {
    try {
      let params = new HttpParams()
        .set('pIdProducto', pIdProducto.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'Tienda/productosrelacionados', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  productosNuevos() {
    try {
      let params = new HttpParams()
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'Tienda/productosnuevos', { params: params });
    }
    catch (Error) {
      return null;
    }
  }
  menuCategorias() {
    try {
      let params = new HttpParams()
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<categoriaProducto[]>(this.url_base_web_api + 'Tienda/menucategorias', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  menuSubCategorias(pIdCategoria: number) {
    try {
      let params = new HttpParams()
        .set('pIdCategoria', pIdCategoria.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<categoriaProducto[]>(this.url_base_web_api + 'Tienda/menusubcategorias', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  productosPorSubcategoria(pIdSubcategoria: number) {
    try {
      let params = new HttpParams()
        .set('pIdSubcategoria', pIdSubcategoria.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<portadaProducto[]>(this.url_base_web_api + 'Tienda/productosporsubcategoria', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  detalleProducto(pIdProducto: number) {
    try {
      let params = new HttpParams()
        .set('pIdProducto', pIdProducto.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<detalleProducto>(this.url_base_web_api + 'Tienda/detalleProducto', { params: params });
    }
    catch (Error) {
      return null;
    }
  }

  obtenerformaspago(pMontoTotal: number) {
    try {
      let params = new HttpParams()
        .set('pMontoTotal', pMontoTotal.toString())
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<formaPago[]>(this.url_base_web_api + 'Tienda/obtenerformaspago', { params: params });
    }
    catch (Error) {
      return null;
    }
  }


  bannersHome() {
    try {
      let params = new HttpParams()
        .set('pIdSitio', this.idSitio.toString());
      return this.http.get<banner[]>(this.url_base_web_api + 'Tienda/bannersHome', { params: params });
    }
    catch (Error) {
      return null;
    }
  }




}
