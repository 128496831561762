import { Component, Inject, Input, OnInit, ViewChild, ElementRef , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder } from '@angular/forms';
/*import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'*/
import { ToastrService } from 'ngx-toastr';
// import { HttpClient, HttpParams } from '@angular/common/http';
import { orden, usuario,ordenCuponesGenerada } from '../../interfaces';
import { formatDate } from '@angular/common';
import { URL_ESTADOS_ORDEN, URL_IMG_ENCABEZADO_ORDEN, URL_IMG_MINIATURA_TIENDA,NOTA_ORDEN, NOTA_ORDEN_SECUNDARIA } from '../../global-variables';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CuponesService } from 'src/app/services/cupones.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-orden-compra-cupon',
  templateUrl: './orden-compra-cupon.component.html',
  styleUrls: ['./orden-compra-cupon.component.scss'],
})
export class OrdenCompraCuponComponent implements OnInit {
  mostrarCupones:boolean=false;
  elementType:string='canvas';
  fechaVencimientoOferta: string;
  fechaVencimientoCupon: string;
  @ViewChild('htmlData') htmlData: ElementRef;
  faDownload = faDownload;
  notaOrden: string = NOTA_ORDEN;
  notaOrdenSecundaria: string = NOTA_ORDEN_SECUNDARIA;
  ultimoPasoProcesado: number;
  urlEstados: string = URL_ESTADOS_ORDEN;
  url_img_miniatura_tienda: string = URL_IMG_MINIATURA_TIENDA;
  url_encabezado_orden:string = URL_IMG_ENCABEZADO_ORDEN;
  @Input() idOrden: number;
  ordenMostrar: ordenCuponesGenerada;
  columnasProductos: string[] = ['image', 'name', 'details', 'quantity','unitCost', 'discount','extendedAmount'];
  constructor( private formBuilder: FormBuilder,
    private toastr: ToastrService, private servicioCupones:CuponesService) {
  }
  ngOnInit(): void {
    this.cargarInfoOrden();
  }
  cargarInfoOrden() {
    this.servicioCupones.obtenerDetalleOrden(this.idOrden).subscribe(
      result => {
        this.ordenMostrar = result;
        if(this.ordenMostrar.orden.detalle.orderStatuDetails.includes('Orden de Pedido') || this.ordenMostrar.orden.detalle.orderStatuDetails.includes('Pendiente Aprobación de Pago') || this.ordenMostrar.orden.detalle.orderStatuDetails.includes('Pago Rechazado') || this.ordenMostrar.orden.detalle.orderStatuDetails.includes('Producto No Disponible')|| this.ordenMostrar.orden.detalle.orderStatuDetails.includes('Anulada')){
          this.mostrarCupones=false;
        }
        else{
          this.mostrarCupones=true;
        }
        const fechaPipe: DatePipe = new DatePipe('en-US');
        this.fechaVencimientoCupon = fechaPipe.transform(this.ordenMostrar.listaCupones[0].fechaFinVisualizacion, 'dd/MM/YYYY');
        if (this.ordenMostrar.orden.id == 0) {
          this.toastr.error('Ha ocurrido un problema al cargar la información de la orden, por favor intente más tarde!', 'Error!',{progressBar:true});
        }
        else {
          this.ultimoPasoProcesado = Math.max.apply(Math, this.ordenMostrar.orden.listaEstados.map(function (o) { return o.paso; }));
        }
      }
    );
  }

  public openPDF(): void {
    let DATA = document.getElementById('htmlData');

    html2canvas(DATA).then(canvas => {

      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;

      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('Orden-Compra-'+ this.idOrden.toString() + '.pdf');
    });
    this.toastr.success('La descarga ha sido realizada!', 'Aviso',{progressBar:true});
    window.scroll(0, 0);
  }

  mostrarInfoPaso(pPasoMostrar:string){
    if(pPasoMostrar=='1A'){
      this.toastr.info('Orden de pedido pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='1B'){
      this.toastr.info('Orden de pedido realizada!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='2A'){
      this.toastr.info('Aprobación de pago pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='2B'){
      this.toastr.info('Aprobación de pago realizada!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='3A'){
      this.toastr.info('Revisión de disponibilidad de producto pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='3B'){
      this.toastr.info('Revisión de disponibilidad de producto realizada!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='4A'){
      this.toastr.info('Facturación pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='4B'){
      this.toastr.info('Facturación realizada!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='5A'){
      this.toastr.info('Orden en ruta de entrega pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='5B'){
      this.toastr.info('Orden en ruta de entrega realizada!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='6A'){
      this.toastr.info('Entrega pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='6B'){
      this.toastr.info('Entrega realizada!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='7A'){
      this.toastr.info('Finalización pendiente!', 'Información',{progressBar:true});
    }
    if(pPasoMostrar=='7B'){
      this.toastr.info('Finalización realizada!', 'Información',{progressBar:true});
    }

  }

}
