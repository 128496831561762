import { Component, OnInit ,Inject,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { portadaProducto,portadaBeneficio,cuponProducto } from '../../interfaces';
import { Router } from '@angular/router';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { BeneficioService } from 'src/app/services/beneficio.service';
import { TiendaService } from 'src/app/services/tienda.service';
import { MarketService } from 'src/app/services/market.service';
import { CuponesService } from 'src/app/services/cupones.service';
import { LoadingController } from '@ionic/angular';
import{MODO_WEB,URL_BANNER_BOTON_HOME,ACTIVAR_MARKET_PLACE,ACTIVAR_TIENDA,ACTIVAR_BENEFICIOS,ACTIVAR_CUPONES} from '../../global-variables';
@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss'],
})
export class BuscarComponent implements OnInit {
  cargando: boolean = false;
  margenSuperior:string;
  esModoWeb: number = MODO_WEB;
  marketActivo=ACTIVAR_MARKET_PLACE;
  tiendaActivo=ACTIVAR_TIENDA;
  beneficiosActivo=ACTIVAR_BENEFICIOS;
  cuponesActivo=ACTIVAR_CUPONES;
  banner:string=URL_BANNER_BOTON_HOME+'cintillos/que_andas_buscando.png';
  idComercioSeleccionado:number=0;
  idCategoriaComercioSeleccionada:number=0;
  idCuponSeleccionado:number=0;
  nombreCategoriaComercioSeleccionado:string='';
  idProductoSeleccionado:number=0;
  listaProductosEncontrados: portadaProducto[];
  listaBeneficiosEncontrados: portadaBeneficio[];
  listaProductosMarketEncontrados: portadaProducto[];
  listaCuponesEncontrados: cuponProducto[];
  faSearch = faSearch;
  mostrarResultadoBeneficios: boolean=false;
  mostrarResultadoMarket: boolean=false;
  mostrarResultadoCupones: boolean=false;
  mostrarResultadoTienda: boolean=false;
  criterio: string='';
  constructor(public loading: LoadingController,private toastr: ToastrService,private servicioTienda:TiendaService,private servicioCupones:CuponesService,
    private servicioMarket:MarketService,private servicioBeneficios:BeneficioService,
    private router: Router) {
    this.mostrarResultadoBeneficios = false;
    this.mostrarResultadoTienda = false;
    this.criterio = '';
    if(this.esModoWeb==1){
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
  }

  async mostrarLoading() {
    this.cargando = true;
    return await this.loading.create({
      message: 'Procesando, espere por favor...'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.cargando) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async cerrarLoading() {
    this.cargando = false;
    return await this.loading.dismiss().then(() => console.log('cerrado'))
  }

  ngOnInit(): void {
  }

  buscarEnTienda() {
    this.servicioTienda.buscarProductosCriterios(this.criterio).subscribe(
      result => {
        this.listaProductosEncontrados = result;
        if (this.listaProductosEncontrados.length != 0) {
          this.mostrarResultadoTienda = true;
        }
        else{
          this.mostrarResultadoTienda=false;
        }
        this.cerrarLoading();
      }
    );
  }

  buscarEnBeneficios() {
    this.servicioBeneficios.buscarBeneficiosCriterios(this.criterio).subscribe(
      result => {
        this.listaBeneficiosEncontrados = result;
        if (this.listaBeneficiosEncontrados.length != 0) {
          this.mostrarResultadoBeneficios= true;
        }
        else{
          this.mostrarResultadoBeneficios=false;
        }
        this.cerrarLoading();
      }
    );
  }

  buscarEnMarket() {
    this.servicioMarket.buscarProductosCriterios(this.criterio).subscribe(
      result => {
        this.listaProductosMarketEncontrados = result;
        if (this.listaProductosMarketEncontrados.length != 0) {
          this.mostrarResultadoMarket = true;
        }
        else{
          this.mostrarResultadoMarket=false;
        }
        this.cerrarLoading();
      }
    );
  }

  buscarEnCupones() {
    this.servicioCupones.obtenerListaCuponesCriterio(this.criterio).subscribe(
      result => {
        this.listaCuponesEncontrados = result;
        if (this.listaCuponesEncontrados.length != 0) {
          this.mostrarResultadoCupones = true;
        }
        else{
          this.mostrarResultadoCupones=false;
        }
        this.cerrarLoading();
      }
    );
  }

  buscar() {
    this.mostrarLoading();
    if (this.criterio != null && this.criterio != '') {
      if(this.beneficiosActivo==1){
        this.buscarEnBeneficios();
      }
      if(this.tiendaActivo==1){
        this.buscarEnTienda();
      }
      if(this.marketActivo==1){
        this.buscarEnMarket();
      }
      if(this.cuponesActivo==1){
        this.buscarEnCupones();
      }
    }
    else {
      this.cerrarLoading();
      this.toastr.error('Por favor escriba los criterios de búsqueda deseados','Error',{progressBar:true});
    }
  }

  productoTiendaSeleccionado(t){
    this.idProductoSeleccionado=t;
    this.criterio='';
    this.mostrarResultadoBeneficios=false;
    this.mostrarResultadoTienda=false;
    this.mostrarResultadoMarket=false;
    this.mostrarResultadoCupones=false;
    this.router.navigate(['tienda/'+this.idProductoSeleccionado.toString()]);
  }

  productoMarketSeleccionado(t){
    this.idProductoSeleccionado=t;
    this.criterio='';
    this.mostrarResultadoBeneficios=false;
    this.mostrarResultadoTienda=false;
    this.mostrarResultadoMarket=false;
    this.mostrarResultadoCupones=false;
    this.router.navigate(['market/'+this.idProductoSeleccionado.toString()]);
  }

  CuponSeleccionado(t){
    this.idCuponSeleccionado=t;
    this.criterio='';
    this.mostrarResultadoBeneficios=false;
    this.mostrarResultadoTienda=false;
    this.mostrarResultadoMarket=false;
    this.mostrarResultadoCupones=false;
    this.router.navigate(['cupones/'+this.idCuponSeleccionado.toString()]);

  }
  comercioSeleccionado(t){
    this.idComercioSeleccionado=t.idComercio;
    this.idCategoriaComercioSeleccionada=t.idCategoria;
    this.nombreCategoriaComercioSeleccionado=t.nombreCategoria;
    this.criterio='';
    this.mostrarResultadoBeneficios=false;
    this.mostrarResultadoTienda=false;
    this.mostrarResultadoMarket=false;
    this.mostrarResultadoCupones=false;
    this.router.navigate(['beneficios/'+this.idComercioSeleccionado.toString()+'/'+this.nombreCategoriaComercioSeleccionado+'/'+this.idCategoriaComercioSeleccionada.toString()]);
  }
}
