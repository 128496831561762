import { Component, OnInit, Inject,Input, CUSTOM_ELEMENTS_SCHEMA,Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { URL_BASE_IMG_COMERCIOS_BENEFICIOS } from '../../../global-variables';
import { URL_BASE_IMG_PORTADA_BENEFICIOS } from '../../../global-variables';
import { URL_IMG_MARCO_BENEFICIO_PORTADA } from '../../../global-variables';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { portadaBeneficio,referenciaCategoriaComercioBeneficio,canton, provincia, distrito} from '../../../interfaces';
import { BeneficioService } from 'src/app/services/beneficio.service';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-lista-elementos-categoria',
  templateUrl: './lista-elementos-categoria.component.html',
  styleUrls: ['./lista-elementos-categoria.component.scss'],
})
export class ListaElementosCategoriaComponent implements OnInit {
  ratioListaElementos:string='';
  cantones: canton[];
  provincias:provincia[];
  distritos:distrito[];
  faChevronCircleLeft=faAngleLeft;
  cantidadColumnas: number;
  provinciaBuscar:number=0;
  cantonBuscar:number=0;
  distritoBuscar:number=0;
  page = 0;
  size = 8;
  listaBeneficiosMostrar: portadaBeneficio[]=[];
  listaBeneficios: portadaBeneficio[]=[];
  @Output() comercioSeleccionado = new EventEmitter<referenciaCategoriaComercioBeneficio>();
  elementoSeleccionado:referenciaCategoriaComercioBeneficio;
  @Input() idCategoria: number=0;
  @Input() nombreCategoria: string='';
  url_base_imagenes_comercios: string = URL_BASE_IMG_COMERCIOS_BENEFICIOS;
  url_base_imagenes_portada: string = URL_BASE_IMG_PORTADA_BENEFICIOS;
  url_imagen_marco_beneficio: string = URL_IMG_MARCO_BENEFICIO_PORTADA;
  @Input() listaBeneficiosExterna: portadaBeneficio[];
  constructor(private servicioBeneficios:BeneficioService,  private paginator: MatPaginatorIntl,private activatedRoute: ActivatedRoute) {
    if (this.listaBeneficiosExterna != null) {
      this.listaBeneficios = this.listaBeneficiosExterna;
      this.getData({ pageIndex: this.page, pageSize: this.size });
    }
    else {
      this.activatedRoute.paramMap.subscribe((parametros: ParamMap) => {
        this.idCategoria = parseInt(parametros.get("idCategoria")),
          this.nombreCategoria = parametros.get("nombreCategoria");
      });
    }
    this.paginator.itemsPerPageLabel = "beneficios por página";
    this.paginator.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return 'No hay beneficios disponibles';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return 'mostrando beneficios del ' + (startIndex+1) + ' al ' + endIndex + ' de un total de ' + length + ' beneficios';
    };
    this.paginator.nextPageLabel = "página siguiente";
    this.paginator.previousPageLabel = "página anterior";
    
  }

  obtenerCantones() {
    this.servicioBeneficios.obtenerCantones(this.provinciaBuscar).subscribe(
      result => {
        this.cantones= result;
        this.distritos=[];
        this.distritoBuscar=0;
      }
    );
  }

  obtenerProvincias() {
    this.servicioBeneficios.obtenerProvincias().subscribe(
      result => {
        this.provincias= result;
        this.provinciaBuscar=0;
        this.cantones=[];
        this.cantonBuscar=0;
        this.distritos=[];
        this.distritoBuscar=0;
      }
    );
  }

  obtenerDistritos() {
    this.servicioBeneficios.obtenerDistritos(this.cantonBuscar).subscribe(
      result => {
        this.distritos= result;
      }
    );
  }

  ngOnInit() {
    if(window.screen.width<window.screen.height){
      this.ratioListaElementos='2:3';
    }
    else{
      this.ratioListaElementos='1:1';
    }
    this.obtenerProvincias();
    if (this.listaBeneficiosExterna != null) {
      this.listaBeneficios = this.listaBeneficiosExterna;
      this.getData({ pageIndex: this.page, pageSize: this.size });
    }
    if( this.idCategoria!=0){
      this.servicioBeneficios.comerciosCategoria(this.idCategoria).subscribe(
        next => {
          this.listaBeneficios=next;
          this.getData({ pageIndex: this.page, pageSize: this.size });
        }
      );
    }
    
    this.calcularCantidadColumnas(window.innerWidth);
  }

  onResize(event) {
    this.calcularCantidadColumnas(event.target.innerWidth);
  }

  calcularCantidadColumnas(pResolucion: number) {
    if (pResolucion >= 0 && pResolucion <= 480) {
      this.cantidadColumnas = 2;
    }
    if (pResolucion > 480 && pResolucion <= 767) {
      this.cantidadColumnas = 2;
    }
    if (pResolucion > 767 && pResolucion <= 1024) {
      this.cantidadColumnas = 3;
    }
    if (pResolucion > 1024) {
      this.cantidadColumnas = 4;
    }
  }

  getData(obj) {
    try {
      let index = 0,
        startingIndex = obj.pageIndex * obj.pageSize,
        endingIndex = startingIndex + obj.pageSize;

      this.listaBeneficiosMostrar = this.listaBeneficios.filter(() => {
        index++;
        return (index > startingIndex && index <= endingIndex) ? true : false;
      });
    } catch (e) {
    }
  }

  avisarSeleccion(idCategoria: number, nombreCategoria: string,idComercio:number) {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: ''
    };
    this.elementoSeleccionado.idCategoria = idCategoria;
    this.elementoSeleccionado.idComercio = idComercio;
    this.elementoSeleccionado.nombreCategoria = nombreCategoria;
    this.elementoSeleccionado.nombreComercio = '';
    this.comercioSeleccionado.emit(this.elementoSeleccionado);
  }

  filtrarLista(){
    this.servicioBeneficios.filtrarComerciosUbicacion(this.idCategoria,this.provinciaBuscar,this.cantonBuscar,this.distritoBuscar,'','').subscribe(
      next => {
        this.listaBeneficios=next;
        this.getData({ pageIndex: this.page, pageSize: this.size });
      }
    );
  }

  limpiarFiltros(){
    if( this.idCategoria!=0){
      this.servicioBeneficios.comerciosCategoria(this.idCategoria).subscribe(
        next => {
          this.listaBeneficios=next;
          this.getData({ pageIndex: this.page, pageSize: this.size });
          this.obtenerProvincias();
        }
      );
    }
  }
}
