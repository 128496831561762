import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { InicioSesionComponent } from '../inicio-sesion/inicio-sesion.component';
import { RegistroComponent } from '../registro/registro.component';
import { SolicitarCreditoComponent } from '../solicitar-credito/solicitar-credito.component';
import { RecuperarContrasenaComponent } from '../recuperar-contrasena/recuperar-contrasena.component';
import { usuario, categoriaMarket, datosAdicionalesUsuario, categoriaBeneficio, carrito } from '../../interfaces';
import { SessionStorageService } from 'ngx-webstorage';
import { faCaretDown, faHome, faUserAlt, faShoppingCart, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { URL_IMAGENES_LOGO } from '../../global-variables';
import { MarketService } from 'src/app/services/market.service';
import { BeneficioService } from 'src/app/services/beneficio.service';
import {URL_WHATSAPP_CLIENTE_CORPORATIVO } from '../../global-variables';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
  
  estiloResponsive:string='';
  urlWhatsapp=URL_WHATSAPP_CLIENTE_CORPORATIVO;
  logoDerecho: string = URL_IMAGENES_LOGO + 'LOGO_DERECHO.png';
  logoIzquierdo: string = URL_IMAGENES_LOGO + 'LOGO_IZQUIERDO.png';
  faCaretDown = faCaretDown;
  faHome = faHome;
  windowResponsive: boolean = false;
  faSearch = faSearch;
  faShoppingCart = faShoppingCart;
  faUserAlt = faUserAlt;
  /*faUserCircle = faUserCircle;*/
  isExpanded = false;
  infoUsuario: usuario;
  infoAdicionalUsuario: datosAdicionalesUsuario;
  infocarrito: carrito;
  listaCatMarket: categoriaMarket[];
  listaCatBeneficios: categoriaBeneficio[] = [];
  intervaloValidacionUsuario: number;
  cantidadProductosCarrito: number;
  datosCliente: string = "";

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openDialogRegistro() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '30vw';
    const dialogRef = this.dialog.open(RegistroComponent, dialogConfig);

  }

  openDialogSolicitarCredito() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '45vw';
    const dialogRef = this.dialog.open(SolicitarCreditoComponent, dialogConfig);

  }

  openDialogLogin() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '30vw';
    const dialogRef = this.dialog.open(InicioSesionComponent, dialogConfig);

  }

  openDialogRecuperarContrasena() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '30vw';
    const dialogRef = this.dialog.open(RecuperarContrasenaComponent, dialogConfig);
  }

  constructor(private router: Router,private servicioMarket: MarketService, private servicioBeneficios: BeneficioService, public dialog: MatDialog, private sessionStore: SessionStorageService, private toastr: ToastrService) {
    this.cantidadProductosCarrito = 0;
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    this.infoAdicionalUsuario = { puntos: 0, tipoIdentificacion: '' };
    this.infocarrito = { id: 0, listaProductos: null, idUsuario: 0 };
    if (window.screen.width < 992) {
      this.windowResponsive = true;
      this.estiloResponsive='divGeneralResponsive';
    }
    else {
      this.windowResponsive = false;
      this.estiloResponsive='divGeneral';
    }
    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
    }
    if (this.sessionStore.retrieve('carrito') != null) {
      this.infocarrito = this.sessionStore.retrieve('carrito');
      this.cantidadProductosCarrito = this.infocarrito.listaProductos.length;
    }
    if (this.sessionStore.retrieve('infoAdicionalUsuario') != null) {
      this.infoAdicionalUsuario = this.sessionStore.retrieve('infoAdicionalUsuario');
    }
    if (this.infoUsuario.nombre == '' && this.infoAdicionalUsuario.puntos == 0) {
      this.datosCliente = '';
    }
    else {
      this.datosCliente = "¡Hola, " + this.infoUsuario.nombre + "! (Puntos disponibles: " + this.infoAdicionalUsuario.puntos + ")";
    }

    this.actualizarContadorCarrito();
  }

  obtenerCategoriasMarket() {
    this.servicioMarket.categorias().subscribe(
      next => {
        this.listaCatMarket = next;
      }
    );

  }
  obtenerCategoriasBeneficios() {
    this.servicioBeneficios.categorias().subscribe(
      next => {
        this.listaCatBeneficios = next;
      }
    );

  }
  ngOnInit() {
    this.obtenerCategoriasBeneficios();
    this.obtenerCategoriasMarket();
    if (window.screen.width < 992) {
      this.windowResponsive = true;
    }
    else {
      this.windowResponsive = false;
    }
    //este semaforo se usa para que no se matricule la observacion de los cambios en las variables de sesión si ya existe dicha observacion
    /*if (this.sessionStore.retrieve('observando') == null) {*/
    this.sessionStore.observe('usuario')
      .subscribe((value) => {
        if (value != null) {
          this.infoUsuario = value;
          this.datosCliente = "¡Hola, " + this.infoUsuario.nombre + "! (Puntos disponibles: " + this.infoAdicionalUsuario.puntos + ")";
        }
        else {
          this.infoUsuario = {
            id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
            telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
          };
        }
      }
      );

    this.sessionStore.observe('infoAdicionalUsuario')
      .subscribe((value) => {
        if (value != null) {
          this.infoAdicionalUsuario = value;
          this.datosCliente = "¡Hola, " + this.infoUsuario.nombre + "! (Puntos disponibles: " + this.infoAdicionalUsuario.puntos + ")";
        }
        else {
          this.infocarrito = { id: 0, listaProductos: null, idUsuario: 0 };
          this.datosCliente = "¡Hola, " + this.infoUsuario.nombre + "! (Puntos disponibles: " + this.infoAdicionalUsuario.puntos + ")";
        }
      }
      );
    //  this.sessionStore.observe('carrito')
    //    .subscribe((value) => {
    //      this.infocarrito = value;
    //      this.cantidadProductosCarrito = this.infocarrito.listaProductos.length;
    //    }
    //    );
    //}

    // this.router.events.subscribe((val) => {
    //   // Filter the event type
    //   if (val instanceof NavigationStart) {
    //     // Hide the navbar
    //     this.navbar.hide();
    //     // Debug log
    //     console.log('NavBar closed');
    //   }
    // });
  }



  actualizarContadorCarrito() {
    let intervalo = setInterval(() => {
      if (this.sessionStore.retrieve('carrito') != null) {
        this.infocarrito = this.sessionStore.retrieve('carrito');
        this.cantidadProductosCarrito = this.infocarrito.listaProductos.length;
      }
      else {
        this.infocarrito = null;
        this.cantidadProductosCarrito = 0;
      }
    }, 1000);
  }

  cerrarSesion() {
    this.toastr.info('Muchas gracias ' + this.infoUsuario.nombre + ' regresa pronto!', '¡Hasta luego!',{progressBar:true});
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    this.infocarrito = { id: 0, listaProductos: null, idUsuario: 0 };
    this.sessionStore.clear('usuario');
    this.sessionStore.clear('carrito');
    this.cantidadProductosCarrito = 0;
  }




}
