import { Component, OnInit, Inject, Input } from '@angular/core';
import { faShoppingCart, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faWhatsapp, faTwitter, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { URL_DETALLE_PRODUCTO, URL_DETALLE_BENEFICIO, URL_DETALLE_CUPON } from '../../../global-variables';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { carrito, usuario, productoOrden, portadaProducto, detalleProducto, cuponProducto } from '../../../interfaces';
import { ToastrService } from 'ngx-toastr';
import { TiendaService } from 'src/app/services/tienda.service';
// import { HttpClient, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-barra-producto',
  templateUrl: './barra-producto.component.html',
  styleUrls: ['./barra-producto.component.scss'],
})
export class BarraProductoComponent implements OnInit {

  url_detalle_producto: string = URL_DETALLE_PRODUCTO;
  url_detalle_beneficio: string = URL_DETALLE_BENEFICIO;
  url_detalle_cupon: string = URL_DETALLE_CUPON;
  faShoppingCart = faShoppingCart;
  faShareAlt = faShareAlt;
  faFacebook = faFacebook;
  faWhatsapp = faWhatsapp;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faPinterest = faPinterest;
  infocarrito: carrito;
  infoUsuario: usuario;
  productoAgregadoCarritoBD: boolean;
  productoAgregarCarrito: productoOrden;
  estiloSeparador: string = '';
  @Input() productoSeleccionado: portadaProducto;
  @Input() cuponSeleccionado: cuponProducto;
  @Input() mostrarCarrito: boolean;
  @Input() rutaBeneficio: string;
  constructor(private servicioTienda: TiendaService, private toastr: ToastrService, private sessionStore: SessionStorageService) {
    this.infocarrito = { id: 0, listaProductos: [], idUsuario: 0 };
    this.productoAgregarCarrito = {
      productID: 0, name1: '', name: '', code: '', image: '', unitCost: 0,
      quantity: 0, extendedAmount: 0, discount: 0, type: 0, iD_Ticket_Code: 0, ticket_Code: '',
      details: '', unitDiscount: 0
    };
    this.productoAgregadoCarritoBD = true;
  }

  ngOnInit(): void {
    if (this.mostrarCarrito == false) {
      this.estiloSeparador = 'col-12'
    }
    else {
      this.estiloSeparador = 'col-6'
    }
  }
  agregarCarrito() {
    this.productoAgregarCarrito.code = this.productoSeleccionado.code;
    this.productoAgregarCarrito.name = this.productoSeleccionado.name;
    this.productoAgregarCarrito.image = this.productoSeleccionado.image;
    this.productoAgregarCarrito.productID = this.productoSeleccionado.productID;
    this.productoAgregarCarrito.unitDiscount = this.productoSeleccionado.unitDiscount;
    this.productoAgregarCarrito.unitCost = this.productoSeleccionado.precioUnitario;
    this.productoAgregarCarrito.quantity = 1;
    this.productoAgregarCarrito.extendedAmount = this.productoSeleccionado.finalCost;
    //VERIFICA SI EL USUARIO ESTA LOGUEADO ENTONCES AGREGA EL PRODUCTO EN EL CARRITO DE LA BD
    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
      this.infocarrito.idUsuario = this.infoUsuario.id;
      if (this.infoUsuario.id != 0) {
        //ACTUALIZA CARRITO EN BD
        this.servicioTienda.agregarCarrito(this.infoUsuario.id, this.productoSeleccionado.productID, 1, '').subscribe(
          result => {
            this.productoAgregadoCarritoBD = result;
          }
        );
      }
    }
    //SI EL CARRITO EN MEMORIA NO TIENE PRODUCTOS ENTONCES LO INICIALIZA Y LE AGREGA EL PRODUCTO SELECCIONADO
    if (this.sessionStore.retrieve('carrito') == null) {
      if (this.infoUsuario != null) {
        this.infocarrito.idUsuario = this.infoUsuario.id;
      }
      else {
        this.infocarrito.idUsuario = 0;
      }
      this.infocarrito.listaProductos = [this.productoAgregarCarrito];
    }
    //SI EL CARRITO EN MEMORIA YA TIENE PRODUCTOS ENTONCES LE AGREGA EL PRODUCTO SELECCIONADO
    else {
      this.infocarrito = this.sessionStore.retrieve('carrito');
      this.infocarrito.listaProductos.push(this.productoAgregarCarrito);
    }
    //ACTUALIZA CARRITO EN MEMORIA
    this.sessionStore.store('carrito', this.infocarrito);
    this.toastr.success('Producto agregado al carrito de compras!', 'Aviso!',{progressBar:true});
  }
  compartirFacebook() {
    let prefijoRedSocial = 'https://www.facebook.com/sharer/sharer.php?u=';
    let rutaFinal = '';
    if (this.productoSeleccionado == null && this.cuponSeleccionado != null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_cupon + this.cuponSeleccionado.idCuponMaestro;
    }
    else if (this.rutaBeneficio == null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_producto + this.productoSeleccionado.productID;
    }
    else {
      rutaFinal = prefijoRedSocial + this.url_detalle_beneficio + this.rutaBeneficio;
    }
    window.open(rutaFinal, '_blank');
  }
  compartirWhatsapp() {
    let prefijoRedSocial = 'https://api.whatsapp.com/send?text=Mira%20esto:%20';
    let rutaFinal = '';
    if (this.productoSeleccionado == null && this.cuponSeleccionado != null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_cupon + this.cuponSeleccionado.idCuponMaestro;
    }
    else if (this.rutaBeneficio == null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_producto + this.productoSeleccionado.productID;
    }
    else {
      rutaFinal = prefijoRedSocial + this.url_detalle_beneficio + this.rutaBeneficio;
    }
    window.open(rutaFinal, '_blank');
  }
  compartirInstagram() {
    let prefijoRedSocial = 'https://www.instagram.com/?url=';
    let rutaFinal = '';
    if (this.productoSeleccionado == null && this.cuponSeleccionado != null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_cupon + this.cuponSeleccionado.idCuponMaestro;
    }
    else if (this.rutaBeneficio == null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_producto + this.productoSeleccionado.productID;
    }
    else {
      rutaFinal = prefijoRedSocial + this.url_detalle_beneficio + this.rutaBeneficio;
    }
    window.open(rutaFinal, '_blank');
  }
  compartirPinterest() { }
  compartirTwitter() {
    let prefijoRedSocial = 'https://twitter.com/intent/tweet?text=Mira%20esto:%20&url=[url]&hashtags=multibeneficios%20multicreditos';
    let rutaFinal = '';
    if (this.productoSeleccionado == null && this.cuponSeleccionado != null) {
      rutaFinal = prefijoRedSocial + this.url_detalle_cupon + this.cuponSeleccionado.idCuponMaestro;
    }
    else if (this.rutaBeneficio == null) {
      rutaFinal = prefijoRedSocial.replace('[url]', this.url_detalle_producto + this.productoSeleccionado.productID);
    }
    else {
      rutaFinal = prefijoRedSocial.replace('[url]', this.url_detalle_beneficio + this.rutaBeneficio);
    }
    window.open(rutaFinal, '_blank');
  }

}
