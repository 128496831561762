import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuscarComponent } from './buscar.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TiendaModule } from '../tienda/tienda.module';
import { BeneficiosModule } from '../beneficios/beneficios.module';
import {FooterMenuModule} from '../footer-menu/footer-menu.module';
import {CuponesModule} from '../cupones/cupones.module';
@NgModule({
  declarations: [BuscarComponent],
  imports: [
    CommonModule,FormsModule,RouterModule,MatInputModule,MatButtonModule,ReactiveFormsModule,
    MatFormFieldModule,FontAwesomeModule,MatTooltipModule,BeneficiosModule,TiendaModule,FooterMenuModule,CuponesModule
  ],
  exports: [BuscarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BuscarModule { }
