import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { observable,BehaviorSubject, throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { canton, direccionUsuario,usuario,LoginRequest,infoToken, paginaFooter,bin } from '../interfaces';
import { NumericValueAccessor } from '@ionic/angular';
import { BlockLike } from 'typescript';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { URL_BASE_WEB_API,USER_WEB_API_360,PASS_WEB_API_360,ID_SITIO_ECOMMERCE} from '../global-variables';
const HTTP_OPTIONS={
  headers: new HttpHeaders({'Content-Type':'application/json','Access-Control-Allow-Origin':'*'})
};
@Injectable({
  providedIn: 'root'
})


export class SeguridadService {
  idSitio=ID_SITIO_ECOMMERCE;
  usuario360=USER_WEB_API_360;
  pass360=PASS_WEB_API_360;
  httpOptions=HTTP_OPTIONS;
  url_base_web_api:  string  =  URL_BASE_WEB_API;
  authSubject  =  new  BehaviorSubject(false);
  token:infoToken;
  constructor(private http:HttpClient,private sessionStore: SessionStorageService) { }

  obtenerToken(){
    let credencialesWebAPI:LoginRequest={'Username':'','Password':''};
    credencialesWebAPI.Password=this.pass360;
    credencialesWebAPI.Username=this.usuario360;
    return  this.http.post(`${this.url_base_web_api}Login`,credencialesWebAPI,this.httpOptions)
    .pipe(
      tap((resp:infoToken) => {
        this.token={'token':''};
        Object.assign(this.token,resp);
        this.sessionStore.store('token',this.token);
      }),
      catchError(this.handlerError)
    )
  }

  handlerError(err:any){
    return throwError(err.error);
  }
  enviarSolicitudCredito(pIdentificacion:string,pNombre:string,pTelefono:string,pFechaNacimiento:string,
    pTipoIngreso:string,pActividad:string,pAntiguedad:string,pMonto:number,pNaturaleza:string,pNum_asegurado:string){
      try{
        let params = new HttpParams()
        .set('pIdentificacion',pIdentificacion.toString())
        .set('pNombre',pNombre.toString())
        .set('pTelefono',pTelefono.toString())
        .set('pFechaNacimiento',pFechaNacimiento.toString())
        .set('pTipoIngreso',pTipoIngreso.toString())
        .set('pActividad',pActividad.toString())
        .set('pAntiguedad',pAntiguedad.toString())
        .set('pMonto',pMonto.toString())
        .set('pNaturaleza',pNaturaleza.toString())
        .set('pIdSitio',this.idSitio.toString())
        .set('pNumAsegurado',pNum_asegurado.toString());
        return this.http.get<boolean>(this.url_base_web_api+'Seguridad/enviarSolicitudCredito',{params:params});
      } 
      catch(Error){
        return null;
      }
  }
  obtenerOcupaciones(){
    try{
      let params = new HttpParams()
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<string[]>(this.url_base_web_api+'Seguridad/obtenerocupaciones',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  agregarDireccion(pIdUsuario:number,pTitulo:string,pIdProvincia:string,
    pIdCanton:number,pCiudad:string,pDetalleDireccion:string, pTelefono:string){
      try{
        let params = new HttpParams()
        .set('pIdUsuario',pIdUsuario.toString())
        .set('pTitulo',pTitulo.toString())
        .set('pIdProvincia',pIdProvincia.toString())
        .set('pIdCanton',pIdCanton.toString())
        .set('pCiudad',pCiudad.toString())
        .set('pDetalleDireccion',pDetalleDireccion.toString())
        .set('pTelefono',pTelefono.toString());
        return this.http.get<boolean>(this.url_base_web_api+'Seguridad/agregardireccion',{params:params});
      } 
      catch(Error){
        return null;
      }
  }
  modificarDireccion(pIdDireccion:number,pIdUsuario:number,pTitulo:string,pIdProvincia:string,
    pIdCanton:number,pCiudad:string,pDetalleDireccion:string, pTelefono:string){
      try{
        let params = new HttpParams()
        .set('pIdDireccion',pIdDireccion.toString())
        .set('pIdUsuario',pIdUsuario.toString())
        .set('pTitulo',pTitulo.toString())
        .set('pIdProvincia',pIdProvincia.toString())
        .set('pIdCanton',pIdCanton.toString())
        .set('pCiudad',pCiudad.toString())
        .set('pDetalleDireccion',pDetalleDireccion.toString())
        .set('pTelefono',pTelefono.toString());
        return this.http.get<boolean>(this.url_base_web_api+'Seguridad/modificardireccion',{params:params});
      } 
      catch(Error){
        return null;
      }
  }

  eliminarDireccion(pIdDireccion:number){
    try{
      let params = new HttpParams()
      .set('pIdDireccion',pIdDireccion.toString());
      return this.http.get<boolean>(this.url_base_web_api+'Seguridad/eliminardireccion',{params:params});
    } 
    catch(Error){
      return null;
    }
  }
  obtenerCantones(pIdProvincia:string){
    try{
      let params = new HttpParams()
      .set('pIdProvincia',pIdProvincia.toString());
      return this.http.get<canton[]>(this.url_base_web_api+'Seguridad/obtenercantones',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  obtenerDirecciones(pIdUsuario:number){
    try{
      let params = new HttpParams()
      .set('pIdUsuario',pIdUsuario.toString());
      return this.http.get<direccionUsuario[]>(this.url_base_web_api+'Seguridad/obtenerdirecciones',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  cambiarContrasena(pIdUsuario:number, pContrasena:string){
    try{
      let params = new HttpParams()
      .set('pIdUsuario',pIdUsuario.toString())
      .set('pContrasena',pContrasena.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<boolean>(this.url_base_web_api+'Seguridad/cambiarcontrasena',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  actualizarInfoGeneral(pIdUsuario:number, pNombre:string, pApellidos:string, pTelefono:string, 
    pRecibirPromociones:number){
      try{
        let params = new HttpParams()
        .set('pIdUsuario',pIdUsuario.toString())
        .set('pNombre',pNombre.toString())
        .set('pApellidos',pApellidos.toString())
        .set('pTelefono',pTelefono.toString())
        .set('pRecibirPromociones',pRecibirPromociones.toString())
        .set('pIdSitio',this.idSitio.toString());
        return this.http.get<boolean>(this.url_base_web_api+'Seguridad/actualizarinfogeneral',{params:params});
      } 
      catch(Error){
        return null;
      }
  }

  infoPadron(pIdentificacion:string){
    try{
      let params = new HttpParams()
      .set('pIdentificacion',pIdentificacion.toString());
      return this.http.get<usuario>(this.url_base_web_api+'Seguridad/infopadron',{params:params});
    } 
    catch(Error){
      return null;
    }
  }
  
  recuperarContrasena(pCorreo:string){
    try{
      let params = new HttpParams()
      .set('pCorreo',pCorreo.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<boolean>(this.url_base_web_api+'Seguridad/recuperarcontrasena',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  iniciarSesion(pUsuario:string, pContrasena:string){
    try{
      let params = new HttpParams()
      .set('pUsuario',pUsuario.toString())
      .set('pContrasena',pContrasena.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<usuario>(this.url_base_web_api+'Seguridad/iniciarsesion',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  registrarusuario(pUsuario:string, pContrasena:string, pCorreo:string, pNombre:string, 
    pApellido1:string, pApellido2:string, pTelefono:string, pIdentificacion:string){
    try{
      let params = new HttpParams()
      .set('pUsuario',pUsuario.toString())
      .set('pContrasena',pContrasena.toString())
      .set('pCorreo',pCorreo.toString())
      .set('pNombre',pNombre.toString())
      .set('pApellido1',pApellido1.toString())
      .set('pApellido2',pApellido2.toString())
      .set('pTelefono',pTelefono.toString())
      .set('pIdentificacion',pIdentificacion.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<boolean>(this.url_base_web_api+'Seguridad/registrarusuario',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  obtenerPaginaFooter(pNombrePagina:string){
    try{
      let params = new HttpParams()
      .set('pNombrePagina',pNombrePagina.toString())
      .set('pIdSitio',this.idSitio.toString());
      return this.http.get<paginaFooter>(this.url_base_web_api+'Seguridad/obtenerpaginafooter',{params:params});
    } 
    catch(Error){
      return null;
    }
  }

  validarBIN(pBIN:string){
    try{
      let params = new HttpParams()      
      .set('pIdSitio',this.idSitio.toString())
      .set('pBIN',pBIN.toString());
      return this.http.get<bin>(this.url_base_web_api+'Seguridad/validarbin',{params:params});
    } 
    catch(Error){
      return null;
    }
  }
}
