import { Component, ViewChild, Inject, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr';
import { usuario, carrito, tiposIdentificacion, datosAdicionalesUsuario } from '../../interfaces';
import { formatDate } from '@angular/common';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { forEachChild } from 'typescript';
import { ModalController } from '@ionic/angular';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { TiendaService } from 'src/app/services/tienda.service';
import { ClienteExternoService } from 'src/app/services/cliente-externo.service';
import { URL_LOGO_ENCABEZADO, UTILIZAR_LOGIN_EXTERNO, CLAVE_SERVIDOR_RECAPTCHA } from '../../global-variables';
import { LoadingController } from '@ionic/angular';
import { ReCaptcha2Component } from 'ngx-captcha';
@Component({
  selector: 'app-inicio-sesion',
  templateUrl: './inicio-sesion.component.html',
  styleUrls: ['./inicio-sesion.component.scss'],
})
export class InicioSesionComponent implements OnInit {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  aFormGroup: FormGroup;
  validacionCorrecta: boolean;
  claveRecaptcha: string = CLAVE_SERVIDOR_RECAPTCHA;
  utilizarLoginExterno = UTILIZAR_LOGIN_EXTERNO;
  logo: string = URL_LOGO_ENCABEZADO;
  tipoIdentificacionSeleccionada: string;
  listaTiposIdentificacion: tiposIdentificacion[] = [{ id: '0', codigo: 'F', nombre: 'Física Nacional' },
  { id: '3', codigo: 'J', nombre: 'Jurídica Nacional' },
  { id: '6', codigo: 'D', nombre: 'DIMEX' },
  { id: '7', codigo: 'JE', nombre: 'Jurídica Extranjera' },
  { id: '12', codigo: 'CD', nombre: 'DIDI' }];
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  identificacion: string;
  contrasena: string;
  infoUsuario: usuario;
  infoAdicionalUsuario: datosAdicionalesUsuario;
  infoCarritoAlmacenado: carrito;
  infoCarritoMemoria: carrito;
  mostrarContra: boolean;
  hoy = Date.now();
  hora: number;
  cargando: boolean = false;
  constructor(public loading: LoadingController, public viewCtrl: ModalController,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<InicioSesionComponent>,
    private toastr: ToastrService,
    private sessionStore: SessionStorageService, private servicioSeguridad: SeguridadService,
    private servicioTienda: TiendaService, private servicioExterno: ClienteExternoService) {
    this.mostrarContra = false;
    this.infoCarritoMemoria = { id: 0, listaProductos: null, idUsuario: 0 };
    this.infoCarritoAlmacenado = { id: 0, listaProductos: null, idUsuario: 0 };
    this.infoAdicionalUsuario = { puntos: 0, tipoIdentificacion: '' };
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }
  async mostrarLoading() {
    this.cargando = true;
    return await this.loading.create({
      message: 'Procesando, espere por favor...'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.cargando) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async cerrarLoading() {
    this.cargando = false;
    return await this.loading.dismiss().then(() => console.log('cerrado'))
  }

  ngOnInit(): void {
    // this.identificacion='113070728';
    // this.contrasena='Soporte2';
    // this.tipoIdentificacionSeleccionada='0';
    this.validacionCorrecta = false;
  }

  iniciarSesion() {
    this.mostrarLoading();
    if (this.contrasena == '' || this.identificacion == '') {
      this.cerrarLoading();
      this.toastr.error('Es necesario completar todos los campos', 'Error!',{progressBar:true});
      this.captchaElem.resetCaptcha();
    }
    else if (this.contrasena == null || this.identificacion == null) {
      this.cerrarLoading();
      this.toastr.error('Es necesario completar todos los campos', 'Error!',{progressBar:true});
      this.captchaElem.resetCaptcha();
    }
    else if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.identificacion)) {
      this.cerrarLoading();
      this.toastr.error('El correo electrónico no tiene un formato correcto', 'Error!',{progressBar:true});
      this.captchaElem.resetCaptcha();
    }
    else {
      if (this.utilizarLoginExterno == 2) {
        if (this.validacionCorrecta) {          
          this.servicioSeguridad.iniciarSesion(this.identificacion.toString(), this.contrasena.toString()).subscribe(
            result => {
              this.infoUsuario = result;
              if (this.infoUsuario.id != 0) {
                if (this.infoUsuario.condition.toUpperCase() != 'ACTIVE') {
                  this.cerrarLoading();
                  this.toastr.error('Su usuario está deshabilitado, por favor contactar a un administrador!', 'Error!',{progressBar:true});
                  this.captchaElem.resetCaptcha();
                }
                else {
                  this.hora = parseInt(formatDate(this.hoy, 'HH', 'en-US'));
                  if (this.hora >= 5 && this.hora < 12) {
                    this.toastr.success('Buenos días ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                  }
                  else if (this.hora >= 0 && this.hora < 5) {
                    this.toastr.success('Buenas noches ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                  }
                  else if (this.hora >= 12 && this.hora < 18) {
                    this.toastr.success('Buenas tardes ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                  }
                  else if (this.hora >= 18 || this.hora > 0) {
                    this.toastr.success('Buenas noches ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                  }
                  this.sessionStore.store('usuario', this.infoUsuario);
                  this.cargarCarrito();
                  this.viewCtrl.dismiss();
                  this.dialogRef.close('${form.value.filename}');
                  this.cerrarLoading();
                }
              }
              else {
                this.cerrarLoading();
                this.toastr.error('Usuario o contraseña inválidos, por favor verificar la información!', 'Error!',{progressBar:true});
                this.captchaElem.resetCaptcha();
              }
            }
          );
        }
        else {
          this.cerrarLoading();
          this.toastr.error('Por favor realice la verificación "No soy un robot"!', 'Error!',{progressBar:true});
          this.captchaElem.resetCaptcha();
        }
      }
      else {
        if (this.validacionCorrecta) {          
          this.servicioExterno.autenticarUsuario(this.tipoIdentificacionSeleccionada.toString(), this.identificacion.toString(), this.identificacion.toString(), this.contrasena.toString()).subscribe(
            result => {
              this.infoUsuario = result;
              if (this.infoUsuario != null) {
                if (this.infoUsuario.id != 0) {
                  if (this.infoUsuario.condition.toUpperCase() != 'ACTIVE') {
                    this.cerrarLoading();
                    this.toastr.error('Su usuario está deshabilitado, por favor contactar a un administrador!', 'Error!',{progressBar:true});
                  }
                  else {
                    this.sessionStore.store('usuario', this.infoUsuario);
                    //setea el tipo de identificacion del usuario según la información recibida
                    this.infoAdicionalUsuario.tipoIdentificacion = this.tipoIdentificacionSeleccionada;
                    //consulta puntos disponibles del usuario
                    this.servicioExterno.consultarPuntos(this.infoAdicionalUsuario.tipoIdentificacion, this.infoUsuario.identificacion, this.infoUsuario.usuario).subscribe(
                      resp => {
                        if (resp != null) {
                          this.infoAdicionalUsuario.puntos = resp;
                          this.sessionStore.store('infoAdicionalUsuario', this.infoAdicionalUsuario);
                        }
                      }
                    )
                    this.hora = parseInt(formatDate(this.hoy, 'HH', 'en-US'));
                    if (this.hora >= 5 && this.hora < 12) {
                      this.toastr.success('Buenos días ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                    }
                    else if (this.hora >= 0 && this.hora < 5) {
                      this.toastr.success('Buenas noches ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                    }
                    else if (this.hora >= 12 && this.hora < 18) {
                      this.toastr.success('Buenas tardes ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                    }
                    else if (this.hora >= 18 || this.hora > 0) {
                      this.toastr.success('Buenas noches ' + this.infoUsuario.nombre + ' bienvenid@ a nuestro programa de lealtad', '¡Hola!',{progressBar:true});
                    }

                    this.cargarCarrito();
                    this.cerrarLoading();
                    this.viewCtrl.dismiss();
                    this.dialogRef.close('${form.value.filename}');
                  }
                }
                else {
                  this.cerrarLoading();
                  this.toastr.error('Usuario o contraseña inválidos, por favor verificar la información!', 'Error!',{progressBar:true});
                }
              }
              else {
                this.cerrarLoading();
                this.toastr.error('Usuario o contraseña inválidos, por favor verificar la información!', 'Error!',{progressBar:true});
              }
            }
          );
        }
        else {
          this.cerrarLoading();
          this.toastr.error('Por favor realice la verificación "No soy un robot"!', 'Error!',{progressBar:true});
          this.captchaElem.resetCaptcha();
        }
      }

    }
  }
  recaptchaExitoso(parametro: any) {
    this.validacionCorrecta = true;
  }

  habilitarMostrarContra() {
    if (this.mostrarContra) {
      this.mostrarContra = false;
    }
    else {
      this.mostrarContra = true;
    }
  }

  cargarCarrito() {
    //OBTIENE CARRITO DE BD PARA EL USUARIO ACTUAL
    this.servicioTienda.obtenerCarrito(this.infoUsuario.id).subscribe(
      result => {
        this.infoCarritoAlmacenado = result;
        //VERIFICA SI HAY PRODUCTOS EN EL CARRITO EN MEMORIA
        if (this.sessionStore.retrieve('carrito') != null) {
          this.infoCarritoMemoria = this.sessionStore.retrieve('carrito');
        }
        //SI HAY PRODUCTOS EN EL CARRITO EN MEMORIA Y TAMBIEN HAY PRODUCTOS EN EL CARRITO DE BD ENTONCES LOS MEZCLA Y ALMACENA EN BD LOS PRODUCTOS DEL CARRITO EN MEMORIA
        if (this.infoCarritoMemoria.listaProductos != null && this.infoCarritoAlmacenado.listaProductos.length != 0) {
          this.mezclarCarritos();
        }
        //SI SOLO HAY PRODUCTOS EN EL CARRITO EN MEMORIA ENTONCES LOS ALMACENA EN LA BD
        else if (this.infoCarritoMemoria.listaProductos != null) {
          this.infoCarritoMemoria.idUsuario = this.infoUsuario.id;
          this.almacenarCarrito(this.infoCarritoMemoria);
          this.sessionStore.store('carrito', this.infoCarritoMemoria);
        }
        //SI SOLO HAY PRODUCTOS EN EL CARRITO DE BD ENTONCES LOS CARGA EN MEMORIA
        else if (this.infoCarritoAlmacenado.listaProductos.length != 0) {
          this.sessionStore.store('carrito', this.infoCarritoAlmacenado);
        }
      });
  }

  almacenarCarrito(carritoGuardar: carrito) {
    for (let prod of carritoGuardar.listaProductos) {
      this.servicioTienda.agregarCarrito(this.infoUsuario.id, prod.productID, prod.quantity, prod.details).subscribe(
        result => {
          let resultado: boolean = result;
        });
    }
  }
  mezclarCarritos() {
    //ALMACENA EN BASE DE DATOS EL CARRITO QUE ESTA EN MEMORIA ASIGNANDOLE EL ID DE USUARIO
    this.infoCarritoMemoria.idUsuario = this.infoUsuario.id;
    this.almacenarCarrito(this.infoCarritoMemoria);
    //MEZCLA AMBOS CARRITOS
    this.infoCarritoAlmacenado.listaProductos = this.infoCarritoAlmacenado.listaProductos.concat(this.infoCarritoMemoria.listaProductos);
    this.sessionStore.store('carrito', this.infoCarritoAlmacenado);
  }

  cerrarVentana() {
    this.viewCtrl.dismiss(null);
  }

}
