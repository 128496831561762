import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule} from '@angular/forms';
import { HomePageModule } from './pages/home/home.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ToastrModule } from 'ngx-toastr';
import { InicioSesionModule } from './modules/inicio-sesion/inicio-sesion.module';
import { RegistroModule } from './modules/registro/registro.module';
import {RecuperarContrasenaModule} from './modules/recuperar-contrasena/recuperar-contrasena.module'
import { BuscarModule } from './modules/buscar/buscar.module';
import {NavMenuModule} from './modules/nav-menu/nav-menu.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,BuscarModule, BrowserAnimationsModule,InicioSesionModule,
    FormsModule,RegistroModule, NgbModule,HomePageModule,HttpClientModule,NavMenuModule,RecuperarContrasenaModule,
    FontAwesomeModule,ReactiveFormsModule,MatFormFieldModule,
    GoogleTagManagerModule.forRoot({
      id: 'G-XFLGEPDK7V',
    }),
    NgxWebstorageModule.forRoot(),ToastrModule.forRoot({positionClass: 'toast-center-center', closeButton:true,tapToDismiss:true})
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy},{provide:'googleTagManagerId', useValue:'GTM-KRNNMHK'}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
