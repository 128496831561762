import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA,  Inject ,Output, EventEmitter} from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { URL_BASE_IMG_CATEGORIA_BENEFICIO } from '../../../global-variables';
import { categoriaBeneficio,referenciaCategoriaComercioBeneficio } from '../../../interfaces';
import { BeneficioService } from 'src/app/services/beneficio.service';
@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
})
export class CategoriasComponent implements OnInit {
  modoResposive:boolean=false;
  url_base_imagenes: string = URL_BASE_IMG_CATEGORIA_BENEFICIO;
  listaCategoriasBeneficios: categoriaBeneficio[]=[];
  @Output() categoriaSeleccionada = new EventEmitter<referenciaCategoriaComercioBeneficio>();
  elementoSeleccionado:referenciaCategoriaComercioBeneficio;

  constructor(private servicioBeneficios:BeneficioService) {
    let ancho:number=window.visualViewport.width;
    if(ancho<=992){
      this.modoResposive=true;
    }
    else{
      this.modoResposive=false;
    }
  }
  
  ngOnInit() {
    let ancho:number=window.visualViewport.width;
    if(ancho<=992){
      this.modoResposive=true;
    }
    else{
      this.modoResposive=false;
    }
    this.servicioBeneficios.categorias().subscribe(
      next => this.listaCategoriasBeneficios=next
    )
    
  }

  avisarSeleccion(idCategoria: number, nombreCategoria: string) {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: ''
    };
    this.elementoSeleccionado.idCategoria = idCategoria;
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreCategoria = nombreCategoria;
    this.elementoSeleccionado.nombreComercio = '';
    this.categoriaSeleccionada.emit(this.elementoSeleccionado);
  }
}
