import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarruselMarketPlaceComponent } from '../carrusel-market-place/carrusel-market-place/carrusel-market-place.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MarketModule} from '../market/market.module';


@NgModule({
  declarations: [CarruselMarketPlaceComponent],
  imports: [
    CommonModule, RouterModule,  CarouselModule,MarketModule
  ],
  exports: [CarruselMarketPlaceComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CarruselMarketPlaceModule { }
