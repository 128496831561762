import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerBotonHomeComponent } from './banner-boton-home/banner-boton-home.component';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FooterMenuModule} from '../footer-menu/footer-menu.module';
import {CarruselBeneficiosModule} from '../../modules/carrusel-beneficios/carrusel-beneficios.module';
import {CarruselGenericoModule}from '../carrusel-generico/carrusel-generico.module'
import {CarruselMarketPlaceModule} from '../carrusel-market-place/carrusel-market-place.module';
import {CarruselProductosModule} from '../carrusel-productos/carrusel-productos.module';
import {CarruselCuponesModule} from '../carrusel-cupones/carrusel-cupones.module';
@NgModule({
  declarations: [BannerBotonHomeComponent,HomeComponent],
  imports: [
    CommonModule,RouterModule,NgbModule,FooterMenuModule,CarruselBeneficiosModule,CarruselGenericoModule,
    CarruselMarketPlaceModule,CarruselProductosModule,CarruselCuponesModule
  ],
  exports: [BannerBotonHomeComponent,HomeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
