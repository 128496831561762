import {Input, Component,ViewChild, OnInit,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import{referenciaCategoriaComercioMarket,bannerBeneficio,cuponAdquirido,portadaProducto} from '../../interfaces';
import { ActivatedRoute, ParamMap } from '@angular/router';
import{MODO_WEB, URL_BANNER_BOTON_HOME,TITULO_MODULO_MARKET_PLACE,SUBTITULO_MODULO_MARKET_PLACE,ACTIVAR_MARKET_PLACE} from '../../global-variables';
import { Router } from '@angular/router';
import { MarketService } from 'src/app/services/market.service';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent implements OnInit {
  faChevronCircleLeft=faAngleLeft;
  margenSuperior:string;
  idProductoSeleccionado:number=0;
  listaProductosEncontrados: portadaProducto[];
  cuponAdquirido:cuponAdquirido;
  listaBannersMarketMostrar:bannerBeneficio[] = [];
  listaBannersMarket:bannerBeneficio[] = [];
  activarMarket = ACTIVAR_MARKET_PLACE;
  titulo_modulo_Market=TITULO_MODULO_MARKET_PLACE;
  subtitulo_modulo_Market=SUBTITULO_MODULO_MARKET_PLACE;
  banner:string=URL_BANNER_BOTON_HOME+'cintillos/Market.png';
  esModoWeb:number=MODO_WEB;
  @Input() elementoSeleccionado:referenciaCategoriaComercioMarket;
  constructor(private toastr: ToastrService,private router: Router,private activatedRoute: ActivatedRoute,private servicioMarket: MarketService) { 
    if(this.esModoWeb==1){
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
  }

  ngOnInit(): void {
    
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: '',idMarca:0
    };
    this.cuponAdquirido={ id:0,tituloSeccion:'',subtituloSeccion:'',codigo:'',correoEnvio:'',tituloCupon:'',disclaimer:'',condiciones:'',
    restricciones:'',vigencia:'',fechaFinalizacion:'',urlImagen:'',disponibles:0,precio:0,precioCuotas:0,
    precioPunto:0,idBeneficio:0,idProducto:0,nombreComercio:'',formaUso:'',cantidadArticulos:0,montoPagarEnComercio:0,montoPagadoConPuntos:0,
    montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''};

   this.inicializarMarket();
  }

  inicializarMarket(){
    this.activatedRoute.params
    .subscribe(
      (updatedParams) => {
       if(updatedParams['idComercio']!=undefined && updatedParams['idCategoria']!= undefined && updatedParams['nombreCategoria']!= undefined){
          this.elementoSeleccionado = {
            idCategoria: updatedParams['idCategoria'], idComercio: updatedParams['idComercio'],
            nombreComercio: updatedParams['nombreComercio'], nombreCategoria: updatedParams['nombreCategoria'],idMarca:updatedParams['idMarca']
          };
          this.seleccionRealizada(this.elementoSeleccionado);
        }
        if(updatedParams['idProducto']!=undefined){
          this.idProductoSeleccionado=updatedParams['idProducto'];
        }
      }
    );

     //OBTIENE BANNERS A MOSTRAR
     this.servicioMarket.bannerCategoria(0).subscribe(
      next => {
        this.listaBannersMarket = next;
        if (this.listaBannersMarket != undefined) {
          this.cargarBannersCarruseles();
        }
      }
    );
  }

  cargarBannersCarruseles() {
    try {
      this.listaBannersMarketMostrar=[];
      if(this.elementoSeleccionado.idCategoria!=0){
        this.listaBannersMarketMostrar = this.listaBannersMarket.filter((e) => {
          if (e.idCategoria == this.elementoSeleccionado.idCategoria) {
            if(window.screen.width<=480){
              e.urlImagenDesktop=e.urlImagenResponsive;
            }
            return e;
          }
        });
      }
      else{
        this.listaBannersMarketMostrar = this.listaBannersMarket;
      }

    } catch (e) {
    }
  }

  seleccionRealizada(t) {
    document.querySelector('app-market-page').querySelector('ion-content').scrollToTop();
    this.elementoSeleccionado.idCategoria = t.idCategoria;
    this.elementoSeleccionado.nombreCategoria = t.nombreCategoria;
    this.elementoSeleccionado.idComercio = t.idComercio;
    this.elementoSeleccionado.nombreComercio = t.nombreComercio;
    this.elementoSeleccionado.idMarca=t.idMarca;
    this.cargarBannersCarruseles();
    if(this.elementoSeleccionado.idMarca!=0){
      this.buscarEnTienda(this.elementoSeleccionado.idMarca);
    }
  }

  adquisicionCupon(t){
    this.cuponAdquirido.codigo=t.codigo;
    this.cuponAdquirido.id=t.id;
    this.cuponAdquirido.tituloSeccion=t.tituloSeccion;
    this.cuponAdquirido.subtituloSeccion=t.subtituloSeccion;
    this.cuponAdquirido.correoEnvio=t.correoEnvio;
    this.cuponAdquirido.tituloCupon=t.tituloCupon;
    this.cuponAdquirido.disclaimer=t.disclaimer;
    this.cuponAdquirido.condiciones=t.condiciones;
    this.cuponAdquirido.restricciones=t.restricciones;
    this.cuponAdquirido.vigencia=t.vigencia;
    this.cuponAdquirido.fechaFinalizacion=t.fechaFinalizacion;
    this.cuponAdquirido.urlImagen=t.urlImagen;
    this.cuponAdquirido.disponibles=t.disponibles;
    this.cuponAdquirido.precio=t.precio;
    this.cuponAdquirido.precioCuotas=t.precioCuotas;
    this.cuponAdquirido.precioPunto=t.precioPunto;
    this.cuponAdquirido.idProducto=t.idProducto;
    this.cuponAdquirido.idBeneficio=t.idBeneficio;
    this.cuponAdquirido.nombreComercio=t.nombreComercio;
    this.cuponAdquirido.formaUso=t.formaUso;
    this.cuponAdquirido.cantidadArticulos=t.cantidadArticulos;
    this.cuponAdquirido.montoPagadoConCuotas=t.montoPagadoConCuotas;
    this.cuponAdquirido.montoPagadoConPuntos=t.montoPagadoConPuntos;
    this.cuponAdquirido.montoPagarEnComercio=t.montoPagarEnComercio;
    this.cuponAdquirido.montoPagadoConCuotas=t.montoPagadoConCuotas;
    this.cuponAdquirido.montoPagadoEnLinea=t.montoPagadoEnLinea;
    this.cuponAdquirido.cantidadPuntosRedimidos=t.cantidadPuntosRedimidos;
    this.cuponAdquirido.precioUnitario=t.precioUnitario;
    this.cuponAdquirido.referenciaInterna=t.referenciaInterna;

    
    
    document.querySelector('app-market-page').querySelector('ion-content').scrollToTop();
    this.elementoSeleccionado.idCategoria = 0;
    this.elementoSeleccionado.nombreCategoria = '';
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreComercio = '';
    this.elementoSeleccionado.idMarca=0;
    this.idProductoSeleccionado=0;

    this.toastr.success('Su cupón ha sido adquirido satisfactoriamente','¡Felicidades!',{progressBar:true})
  }

  finalizadaAdquisicion(t){
    this.elementoSeleccionado.idCategoria = 0;
    this.elementoSeleccionado.nombreCategoria = '';
    this.elementoSeleccionado.idComercio = 0;
    this.elementoSeleccionado.nombreComercio = '';
    this.cuponAdquirido={ id:0,tituloSeccion:'',subtituloSeccion:'',codigo:'',correoEnvio:'',tituloCupon:'',disclaimer:'',condiciones:'',
    restricciones:'',vigencia:'',fechaFinalizacion:'',urlImagen:'',disponibles:0,precio:0,precioCuotas:0,
    precioPunto:0,idBeneficio:0,idProducto:0,nombreComercio:'',formaUso:'',cantidadArticulos:0,montoPagarEnComercio:0,montoPagadoConPuntos:0,
    montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''};
    this.idProductoSeleccionado=0;
  }

  buscarEnTienda(idMarca:number) {
    this.servicioMarket.buscarProductos(idMarca).subscribe(
      result => {
        if(result!=null){
          this.listaProductosEncontrados = result;
        }
      }
    );
  }

  productoSeleccionado(t){
    this.idProductoSeleccionado=t;    
  }

  avisarSeleccion(idCategoria: number, nombreCategoria: string,idComercio:number,nombreComercio:string,idMarcaTienda:number) {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: '',idMarca:0
    };
    this.elementoSeleccionado.idCategoria = idCategoria;
    this.elementoSeleccionado.idComercio = idComercio;
    this.elementoSeleccionado.nombreCategoria = nombreCategoria;
    this.elementoSeleccionado.nombreComercio = nombreComercio;
    this.elementoSeleccionado.idMarca=idMarcaTienda;
  }
  regresar(componenteAnterior:string){
    if(componenteAnterior=='detalleComercio'){
      this.elementoSeleccionado = {
        idCategoria: this.elementoSeleccionado.idCategoria, idComercio: 0,
        nombreComercio: '', nombreCategoria: this.elementoSeleccionado.nombreCategoria,idMarca:0
      };
    }
    else if(componenteAnterior=='detalleMarket'){
      this.idProductoSeleccionado=0;
    }


  }
}
