import { Component, Inject, CUSTOM_ELEMENTS_SCHEMA, OnInit, SimpleChange, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { cuponAdquirido, telefono, portadaProducto, infoProductoMarket, usuario, datosAdicionalesUsuario, productoOrden, precioCuotas, referenciaCategoriaComercioMarket } from '../../../interfaces';
import {
  SERVIDOR_CORREO, USUARIO_CORREO_REMITENTE, CONTRASENA_CORREO_REMITENTE,
  NOMBRE_MOSTRAR_CORREO_CUPON_MARKET_PLACE, CORREO_CC_CUPON_BENEFICIO, URL_IMG_DETALLE_PRODUCTO_TIENDA,
  TITULO_MODULO_MARKET_PLACE, SUBTITULO_MODULO_MARKET_PLACE
} from '../../../global-variables';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from 'ngx-webstorage';
import { MarketService } from 'src/app/services/market.service';
import { ClienteExternoService } from 'src/app/services/cliente-externo.service';
import { LoadingController } from '@ionic/angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-detalle-market',
  templateUrl: './detalle-market.component.html',
  styleUrls: ['./detalle-market.component.scss'],
})
export class DetalleMarketComponent implements OnInit {
  titulo_modulo_Market = TITULO_MODULO_MARKET_PLACE;
  subtitulo_modulo_Market = SUBTITULO_MODULO_MARKET_PLACE;
  cuponAdquirido: cuponAdquirido;
  servidorCorreo = SERVIDOR_CORREO;
  usuarioCorreoRemitente = USUARIO_CORREO_REMITENTE;
  contrasenaCorreoRemitente = CONTRASENA_CORREO_REMITENTE;
  nombreMostrarCorreoCuponMarket = NOMBRE_MOSTRAR_CORREO_CUPON_MARKET_PLACE;
  correoCCCuponBeneficio = CORREO_CC_CUPON_BENEFICIO;
  prefijoMapa: string = 'https://www.google.com/maps/?q=';
  concatenacionMapa: string = '+';
  prefijoCallTo: string = 'tel:+506';
  prefijoMailTo: string = 'mailto:';
  prefijoWeb: string = 'http://www.';
  listaTelefonos: telefono[];
  @Input() elementoSeleccionado: referenciaCategoriaComercioMarket = {
    idCategoria: 0, idComercio: 0,
    nombreComercio: '', nombreCategoria: '', idMarca: 0
  };
  @Input() idProducto: number = 0;
  @Input() idComercio: number = 0;
  @Output() avisoCuponAdquirido = new EventEmitter<cuponAdquirido>();
  producto: infoProductoMarket = {
    detalleProducto: {
      id: 0,
      cuotaPrincipal: {
        id: 0, nombre: '', descripcion: '', interes: 0, cantidadCuotas: 0, valorCuota: 0,
        requiereAprobacion: 0, correoAprobacionProducto: '', correoAprobacionFormaPago: '', imagen: '',
        disclaimer: ''
      },
      otrasCuotas: [], productID: 0, code: '', precioPuntos: 0,
      name: '', classification1ID: 0, classification1Name: '', subClassificationID: 0, subClassificationName: '',
      classification2ID: 0, classification2Name: '', image: '', column1: 0, taxPercent: 0, unitDiscount: 0,
      unitSuggestedCost: 0, hasSize: 0, costAfterUtility: 0, finalCost: 0, description: '', especifications: '',
      otherData: '', condition: '', message: '', keywords: '', precioUnitario: 0, valorPunto: 0,
      imagenesDetalle: {
        id: 0, productID: 0, url_imagen_uno: '', url_imagen_dos: '',
        url_imagen_tres: '', url_imagen_cuatro: ''
      },nombrePunto:'',precioMillas:0,valorMilla:0,nombreMilla:''
    }, detalleComercio: {
      idComercio: 0, urlImagenPortada: '', urlImagenLogo: '', nombreComercio: '', contactoComercio: '', facebook: '',
      telefono: '', correo: '', web: '', listaBeneficios: [], listaSucursales: []
    }
  };
  cuotaSeleccionada: string;
  cantidad: number;
  imagenActual: SafeUrl;
  url_img_producto: string = URL_IMG_DETALLE_PRODUCTO_TIENDA;
  productosRelacionados: portadaProducto[];
  infoUsuario: usuario;
  infoAdicionalUsuario: datosAdicionalesUsuario;
  productoAgregarCarrito: productoOrden;

  constructor(private sanitizer: DomSanitizer,public loading: LoadingController,private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private sessionStore: SessionStorageService,
    private marketServicio: MarketService, private servicioExterno: ClienteExternoService) {
    this.infoUsuario = {
      id: 0, identificacion: '', nombre: '', apellidos: '', usuario: '', renovarcontrasena: 0, telefono1: '',
      telefono2: '', condition: '', apellido1: '', apellido2: '', promotions: 0, nombreCompleto: ''
    };
    this.infoAdicionalUsuario = { puntos: 0, tipoIdentificacion: '' };
    this.cuponAdquirido = {
      id: 0, tituloSeccion: '', subtituloSeccion: '', codigo: '', correoEnvio: '', tituloCupon: '', disclaimer: '', condiciones: '',
      restricciones: '', vigencia: '', fechaFinalizacion: '', urlImagen: '', disponibles: 0, precio: 0, precioCuotas: 0,
      precioPunto: 0, idBeneficio: 0, idProducto: 0, nombreComercio: '', formaUso: '',cantidadArticulos:0,montoPagarEnComercio:0,
      montoPagadoConPuntos:0,montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''
    };
    this.activatedRoute.paramMap.subscribe((parametros: ParamMap) => {
      if (parametros.get("idProducto") != null) {
        this.idProducto = parseInt(parametros.get("idProducto"));
        this.idComercio = parseInt(parametros.get("idComercio"));
        this.obtenerInformacionProducto();
      }
    });

    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
    }
    if (this.sessionStore.retrieve('infoAdicionalUsuario') != null) {
      this.infoAdicionalUsuario = this.sessionStore.retrieve('infoAdicionalUsuario');
    }
  }
  async mostrarLoading() {
    const loading = await this.loading.create({
      message: 'Espere por favor...',
      duration: 500
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }

  separarTelefonos(datos: string) {
    try {
      var datosSplit = datos.split('\n');
      this.listaTelefonos = null;
      if (datosSplit.length != 0) {
        this.listaTelefonos = [{ numeroMostrar: datosSplit[0], numeroLlamar: datosSplit[0].replace('-', '') }];
        for (let i = 1; i < datosSplit.length; i++) {
          this.listaTelefonos.push({ numeroMostrar: datosSplit[i], numeroLlamar: datosSplit[i].replace('-', '') });
        }
      }
    } catch (e) {

    }

  }
  ngOnInit(): void {
    if (this.idProducto != 0) {
      this.obtenerInformacionProducto();
    }
    this.activatedRoute.params.subscribe(
      (updatedParams) => {
        if (updatedParams['idProducto'] != null) {
          this.idProducto = updatedParams['idProducto'];
          this.idComercio = updatedParams['idComercio'];
          this.obtenerInformacionProducto();
        }
      }
    );
  }

  seleccionPlazo() {
    this.producto.detalleProducto.cuotaPrincipal = this.producto.detalleProducto.otrasCuotas.filter((e) => {
      if (e.id.toString() == this.cuotaSeleccionada.toString()) {
        return e;
      }
    })[0];
  }


  obtenerInformacionProducto() {
    let productoDet: infoProductoMarket;
    let formasPagoFantasia: precioCuotas[];
    this.marketServicio.detalleProducto(this.idProducto, this.idComercio).subscribe(
      result => {
        productoDet = result;
        this.desplegarInfoProducto(productoDet);
        this.separarTelefonos(this.producto.detalleComercio.telefono);
      });
    this.cantidad = 1;
  }

  desplegarInfoProducto(productoDet: infoProductoMarket) {
    this.producto = productoDet;
    this.producto.detalleProducto.imagenesDetalle.url_imagen_cuatro = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.detalleProducto.imagenesDetalle.url_imagen_cuatro);
    this.producto.detalleProducto.imagenesDetalle.url_imagen_tres = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.detalleProducto.imagenesDetalle.url_imagen_tres);
    this.producto.detalleProducto.imagenesDetalle.url_imagen_dos = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.detalleProducto.imagenesDetalle.url_imagen_dos);
    this.producto.detalleProducto.imagenesDetalle.url_imagen_uno = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.detalleProducto.imagenesDetalle.url_imagen_uno);
    this.producto.detalleProducto.precioPuntos = Math.round(this.producto.detalleProducto.precioPuntos);
    this.imagenActual = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.detalleProducto.imagenesDetalle.url_imagen_uno);
  }

  comprar() {
    this.mostrarLoading();
    this.servicioExterno.consultarPuntos(this.infoAdicionalUsuario.tipoIdentificacion, this.infoUsuario.identificacion, this.infoUsuario.usuario).subscribe(
      resp => {
        if (resp != null) {
          if (resp != this.infoAdicionalUsuario.puntos) {
            this.infoAdicionalUsuario.puntos = resp;
            this.sessionStore.store('infoAdicionalUsuario', this.infoAdicionalUsuario);
          }
          if (this.infoAdicionalUsuario.puntos > 0) {
            let precioFinalPuntosCompra: number = 0;
            let montoPagarEnComercio: number = 0;
            let montoPagadoConPuntos: number = 0;
            precioFinalPuntosCompra = this.cantidad * this.producto.detalleProducto.precioPuntos;
            if (precioFinalPuntosCompra <= this.infoAdicionalUsuario.puntos) {
              montoPagarEnComercio = 0;
              montoPagadoConPuntos = precioFinalPuntosCompra * this.producto.detalleProducto.valorPunto;
            }
            else {
              montoPagarEnComercio = (precioFinalPuntosCompra - this.infoAdicionalUsuario.puntos) * this.producto.detalleProducto.valorPunto;
              montoPagadoConPuntos = (precioFinalPuntosCompra * this.producto.detalleProducto.valorPunto) - montoPagarEnComercio;
              precioFinalPuntosCompra = precioFinalPuntosCompra - this.infoAdicionalUsuario.puntos;
            }

            //proceder con la compra
            //rebaja los puntos al cliente
            let codigoReferencia: string;//el cual quedara registrado en el core del cliente externo en la transaccion de rebaja de puntos
            //codigo de referencia compuesto por año, mes, dia hora, minuto, segundo, id de producto y id de usuario de TAP
            let fechaActual: Date = new Date();
            codigoReferencia = fechaActual.getFullYear().toString() + fechaActual.getMonth().toString() + fechaActual.getDay().toString() +
              fechaActual.getHours().toString() + fechaActual.getMinutes().toString() + fechaActual.getSeconds().toString() +
              this.producto.detalleProducto.productID.toString() + this.infoUsuario.id.toString();

            //redime los puntos necesarios para la compra
            this.servicioExterno.redimirPuntos(this.infoAdicionalUsuario.tipoIdentificacion, this.infoUsuario.identificacion, this.infoUsuario.usuario, precioFinalPuntosCompra,
              codigoReferencia, 'PYMES', 'Redencion de puntos en ' + this.producto.detalleComercio.nombreComercio).subscribe(
                respRedencion => {
                  if (respRedencion != null) {
                    if (respRedencion.codigoMovimientoTransaccion != null) {
                      this.infoAdicionalUsuario.puntos = this.infoAdicionalUsuario.puntos - precioFinalPuntosCompra;
                      this.sessionStore.store('infoAdicionalUsuario', null);
                      this.sessionStore.store('infoAdicionalUsuario', this.infoAdicionalUsuario);
                    }
                    //procede con la geeneracion del cupon
                    this.marketServicio.adquirirCuponMarket(this.idProducto, this.infoUsuario.id, this.servidorCorreo,
                      this.usuarioCorreoRemitente, this.contrasenaCorreoRemitente, this.nombreMostrarCorreoCuponMarket,
                      this.correoCCCuponBeneficio, this.infoUsuario.usuario, this.titulo_modulo_Market,
                      this.subtitulo_modulo_Market, precioFinalPuntosCompra, respRedencion.codigoMovimientoTransaccion, this.cantidad,
                      montoPagarEnComercio, 0, 0, montoPagadoConPuntos, 0, 0,codigoReferencia
                    ).subscribe(
                      next => {
                        this.cuponAdquirido = next;
                        this.cuponAdquirido.precioPunto = this.producto.detalleProducto.precioPuntos;
                        this.cuponAdquirido.tituloCupon = this.producto.detalleProducto.name;
                        this.cuponAdquirido.tituloSeccion = this.titulo_modulo_Market;
                        this.cuponAdquirido.subtituloSeccion = this.subtitulo_modulo_Market;
                        this.avisoCuponAdquirido.emit(this.cuponAdquirido);
                      }
                    );
                  }
                }
              );
          }
          else {
            this.toastr.error('No es posible realizar la compra debido a que no posee puntos', '¡Error!',{progressBar:true});
          }



        }
      }
    );





  }
}
