import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import {InicioSesionModule} from '../inicio-sesion/inicio-sesion.module'
// import {RegistroModule} from '../registro/registro.module';
import { RouterModule } from '@angular/router';
import { TabsComponent } from './tabs.component';

@NgModule({
  declarations: [TabsComponent],
  imports: [
    CommonModule,RouterModule
    // ,InicioSesionModule,RegistroModule
  ],
  exports: [TabsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TabsModule { }
