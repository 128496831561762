import { Component,Output, Inject, CUSTOM_ELEMENTS_SCHEMA, OnInit, SimpleChange, SimpleChanges, Input, SecurityContext, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap,Router } from '@angular/router';
import { detalleProducto, portadaProducto, carrito, usuario, productoOrden, precioCuotas, formaPago,referenciaCategoriaSubCategoriaProducto } from '../../../interfaces';
import { URL_IMG_DETALLE_PRODUCTO_TIENDA } from '../../../global-variables';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from 'ngx-webstorage';
import { faShoppingCart, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { TiendaService } from 'src/app/services/tienda.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.scss'],
})
export class DetalleProductoComponent implements OnInit {
  @Output() seleccionElemento = new EventEmitter<referenciaCategoriaSubCategoriaProducto>();
  mostrarCuotas: boolean = false;
  faShoppingCart = faShoppingCart;
  faShoppingBag = faShoppingBag;
  @Input() idProducto: number = 0;
  producto: detalleProducto = {
    id: 0, precioPuntos: 0,
    cuotaPrincipal: {
      id: 0, nombre: '', descripcion: '', interes: 0, cantidadCuotas: 0, valorCuota: 0,
      requiereAprobacion: 0, correoAprobacionProducto: '', correoAprobacionFormaPago: '', imagen: '',
      disclaimer: ''
    },
    otrasCuotas: [], productID: 0, code: '', valorPunto: 0,
    name: '', classification1ID: 0, classification1Name: '', subClassificationID: 0, subClassificationName: '',
    classification2ID: 0, classification2Name: '', image: '', column1: 0, taxPercent: 0, unitDiscount: 0,
    unitSuggestedCost: 0, hasSize: 0, costAfterUtility: 0, finalCost: 0, description: '', especifications: '',
    otherData: '', condition: '', message: '', keywords: '', precioUnitario: 0,
    imagenesDetalle: {
      id: 0, productID: 0, url_imagen_uno: '', url_imagen_dos: '',
      url_imagen_tres: '', url_imagen_cuatro: ''
    }, nombrePunto: '', precioMillas: 0, valorMilla: 0, nombreMilla: ''
  };
  cuotaSeleccionada: string;
  cantidad: number;
  modoPantallaHorizontal: boolean;
  imagenActual: SafeUrl;
  url_img_producto: string = URL_IMG_DETALLE_PRODUCTO_TIENDA;
  productosRelacionados: portadaProducto[];
  infocarrito: carrito;
  infoUsuario: usuario;
  productoAgregarCarrito: productoOrden;
  productoInfo: portadaProducto = {
    id: 0, productID: 0, code: '', name: '', classification1Name: '',
    subClassificationName: '', classification2Name: '', bulletImage: '', classification1ID: 0,
    unitCost: 0, utilityPercent: 0, unitDiscount: 0, taxPercent: 0, unitSuggestedCost: 0,
    image: '', condition: '', message: '', blocked: 0, costAfterUtility: 0,
    finalCost: 0, quota: 0, miles: 0, costByCash: 0, costByPoint: 0, costByPoint1: 0,
    precioUnitario: 0
  };
  elementoSeleccionado: { idCategoria: number; idSubcategoria: number; nombreSubcategoria: string; nombreCategoria: string; idMarca: number; nombreMarca: string; };

  constructor(private router:Router,private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private sessionStore: SessionStorageService,
    private tiendaServicio: TiendaService) {
    this.infocarrito = { id: 0, listaProductos: [], idUsuario: 0 };
    this.productoAgregarCarrito = {
      productID: 0, name1: '', name: '', code: '', image: '', unitCost: 0,
      quantity: 0, extendedAmount: 0, discount: 0, type: 0, iD_Ticket_Code: 0, ticket_Code: '',
      details: '', unitDiscount: 0
    };
    this.activatedRoute.paramMap.subscribe((parametros: ParamMap) => {
      if (parametros.get("idProducto") != null) {
        this.idProducto = parseInt(parametros.get("idProducto"));
        this.obtenerInformacionProducto();
      }
    });
  }


  ngOnInit(): void {
    if (this.idProducto != 0) {
      this.obtenerInformacionProducto();
    }
    // this.obtenerInformacionProducto();
    this.activatedRoute.params.subscribe(
      (updatedParams) => {
        if (updatedParams['idProducto'] != null) {
          this.idProducto = updatedParams['idProducto'];
          this.obtenerInformacionProducto();
        }
      }
    );
    this.definirOrientacionImagenes(window.innerWidth, window.innerHeight);
  }

  // seleccionPlazo(value: string) {
  //   this.producto.cuotaPrincipal = this.producto.otrasCuotas.filter((e) => {
  //     if (e.id.toString() == value) {
  //       return e;
  //     }
  //   })[0];
  // }

  seleccionPlazo() {
    this.producto.cuotaPrincipal = this.producto.otrasCuotas.filter((e) => {
      if (e.id.toString() == this.cuotaSeleccionada.toString()) {
        return e;
      }
    })[0];
  }

  onResize(event) {
    this.definirOrientacionImagenes(event.target.innerWidth, event.target.innerHeight);
  }
  definirOrientacionImagenes(pAncho: number, pAlto: number) {
    if (pAncho > pAlto) {
      this.modoPantallaHorizontal = true;
    }
    else {
      this.modoPantallaHorizontal = false;
    }
  }

  // ngOnChanges(cambios: SimpleChanges) {
  //   if (cambios.idProducto.currentValue != cambios.idProducto.previousValue) {
  //     this.obtenerInformacionProducto();
  //   }
  // }

  obtenerInformacionProducto() {
    let productoDet: detalleProducto;
    let formasPagoFantasia: formaPago[];
    this.tiendaServicio.detalleProducto(this.idProducto).subscribe(
      result => {
        productoDet = result;

        if (productoDet.otrasCuotas.length > 0) {
          productoDet.cuotaPrincipal = {
            id: 0, nombre: '', descripcion: '', interes: 0, cantidadCuotas: 0, valorCuota: 0,
            requiereAprobacion: 0, correoAprobacionProducto: '', correoAprobacionFormaPago: '', imagen: '',
            disclaimer: ''
          };
          productoDet.cuotaPrincipal = productoDet.otrasCuotas[productoDet.otrasCuotas.length - 1];
          this.mostrarCuotas = true;
        }
        else {
          this.mostrarCuotas = false;
        }
        this.desplegarInfoProducto(productoDet);
      }
    );
    this.cantidad = 1;
    this.definirOrientacionImagenes(window.innerWidth, window.innerHeight);
    this.tiendaServicio.productosRelacionados(this.idProducto).subscribe(
      result => {
        this.productosRelacionados = result;
      }
    );
    document.querySelector('app-tienda-page').querySelector('ion-content').scrollToTop();
  }

  desplegarInfoProducto(productoDet: detalleProducto) {
    this.producto = productoDet;
    this.producto.imagenesDetalle.url_imagen_cuatro = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.imagenesDetalle.url_imagen_cuatro));
    this.producto.imagenesDetalle.url_imagen_tres = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.imagenesDetalle.url_imagen_tres));
    this.producto.imagenesDetalle.url_imagen_dos = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.imagenesDetalle.url_imagen_dos));
    this.producto.imagenesDetalle.url_imagen_uno = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.url_img_producto + this.producto.imagenesDetalle.url_imagen_uno));
    this.imagenActual = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(this.producto.imagenesDetalle.url_imagen_uno.toString()));
    this.productoInfo.id = this.producto.id;
    this.productoInfo.image = this.producto.image;
    this.productoInfo.name = this.producto.name;
    this.productoInfo.precioUnitario = this.producto.precioUnitario;
    this.productoInfo.productID = this.producto.productID;
    this.productoInfo.finalCost = this.producto.finalCost;
    this.productoInfo.code = this.producto.code;
    this.productoInfo.unitDiscount = this.producto.unitDiscount;
    this.producto.otrasCuotas=this.producto.otrasCuotas.filter((e) => {
      if (e.nombre.indexOf('ombina') ==-1 && e.nombre.indexOf('+') ==-1 ) {
        return e;
      }
    });
  }

  agregarCarrito() {
    this.productoAgregarCarrito.code = this.producto.code;
    this.productoAgregarCarrito.name = this.producto.name;
    this.productoAgregarCarrito.image = this.producto.image;
    this.productoAgregarCarrito.productID = this.producto.productID;
    this.productoAgregarCarrito.unitDiscount = this.producto.unitDiscount;
    this.productoAgregarCarrito.unitCost = this.producto.precioUnitario;
    this.productoAgregarCarrito.extendedAmount = this.producto.finalCost;
    this.productoAgregarCarrito.quantity = this.cantidad;
    //VERIFICA SI EL USUARIO ESTA LOGUEADO ENTONCES AGREGA EL PRODUCTO EN EL CARRITO DE LA BD
    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
      this.infocarrito.idUsuario = this.infoUsuario.id;
      if (this.infoUsuario.id != 0) {
        //ACTUALIZA CARRITO EN BD
        this.tiendaServicio.agregarCarrito(this.infoUsuario.id, this.productoAgregarCarrito.productID,
          this.productoAgregarCarrito.quantity, this.productoAgregarCarrito.details).subscribe(
            result => {
              let productoAgregadoCarritoBD: boolean = result;
            }
          );
      }
    }
    //SI EL CARRITO EN MEMORIA NO TIENE PRODUCTOS ENTONCES LO INICIALIZA Y LE AGREGA EL PRODUCTO SELECCIONADO
    if (this.sessionStore.retrieve('carrito') == null) {
      if (this.infoUsuario != null) {
        this.infocarrito.idUsuario = this.infoUsuario.id;
      }
      else {
        this.infocarrito.idUsuario = 0;
      }
      this.infocarrito.listaProductos = [this.productoAgregarCarrito];
    }
    //SI EL CARRITO EN MEMORIA YA TIENE PRODUCTOS ENTONCES LE AGREGA EL PRODUCTO SELECCIONADO
    else {
      this.infocarrito = this.sessionStore.retrieve('carrito');
      this.infocarrito.listaProductos.push(this.productoAgregarCarrito);
    }
    //ACTUALIZA CARRITO EN MEMORIA
    this.sessionStore.store('carrito', this.infocarrito);
    this.toastr.success('Producto agregado al carrito de compras!', 'Aviso!',{progressBar:true});
  }

  compraloAqui() {
    let hola: string = 'fdsfd';
    hola = 'nada que ver';
    this.agregarCarrito();
  }

  productoRelacionadoSeleccionado(t) {
    this.idProducto = t;
    this.obtenerInformacionProducto();

  }

  limpiarProductoSeleccionado(){
    this.elementoSeleccionado = {
      idCategoria: 0, idSubcategoria: 0,
      nombreSubcategoria: '', nombreCategoria: '',idMarca:0,nombreMarca:''
    };
    this.elementoSeleccionado.idCategoria = 0;
    this.elementoSeleccionado.idSubcategoria = 0;
    this.elementoSeleccionado.nombreCategoria = '';
    this.elementoSeleccionado.nombreSubcategoria = '';
    this.elementoSeleccionado.idMarca=this.producto.classification2ID;
    this.elementoSeleccionado.nombreMarca=this.producto.classification2Name;
    this.seleccionElemento.emit(this.elementoSeleccionado);
  }

  redirigirSubCategoria(){
    this.elementoSeleccionado = {
      idCategoria: 0, idSubcategoria: 0,
      nombreSubcategoria: '', nombreCategoria: '',idMarca:0,nombreMarca:''
    };
    this.elementoSeleccionado.idCategoria = this.producto.classification1ID;
    this.elementoSeleccionado.idSubcategoria = this.producto.subClassificationID;
    this.elementoSeleccionado.nombreCategoria = this.producto.classification1Name;
    this.elementoSeleccionado.nombreSubcategoria = this.producto.subClassificationName;
    this.elementoSeleccionado.idMarca=0;
    this.elementoSeleccionado.nombreMarca='';
    this.seleccionElemento.emit(this.elementoSeleccionado);
  }


}
