import { Component, ViewChild, Inject, OnInit, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr';
import { usuario } from '../../interfaces';
import { SessionStorageService } from 'ngx-webstorage';
import { CLAVE_SERVIDOR_RECAPTCHA } from '../../global-variables';
import { MatRadioChange } from '@angular/material/radio';
// import { ReCaptcha2Component } from 'ngx-captcha';
import { SeguridadService } from 'src/app/services/seguridad.service';
import {datosFormSolicitud} from '../../interfaces'
@Component({
  selector: 'app-solicitar-credito',
  templateUrl: './solicitar-credito.component.html',
  styleUrls: ['./solicitar-credito.component.scss'],
})
export class SolicitarCreditoComponent implements OnInit {

  // @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  tipoSolicitud: string;
  aFormGroup: FormGroup;
  validacionCorrecta: boolean;
  claveRecaptcha: string = CLAVE_SERVIDOR_RECAPTCHA;
  montoSolicitar: number=0;
  compraMall: number=0;
  infoUsuario: usuario;
  tiposIdentificacion = [{ id: 1, detalle: 'Nacional' },
  { id: 2, detalle: 'DIMEX' }];
  usuarioPadron: usuario;
  tipoIdentificacionSeleccionada: number=0;
  tipoIngresoSeleccionado: number=0;
  tiposIngresos = [{ id: 1, detalle: 'Asalariado' },
  { id: 2, detalle: 'No asalariado' }];
  actividadesEconomicas: string[]=[];
  actividadEconomicaSeleccionada: string='';
  antiguedad: string='';
  identificacion: string='';
  nombre: string='';
  num_asegurado:string='';
  apellido1: string='';
  apellido2: string='';
  telefono: string='';
  fechaNacimiento: string='';
  registroExitoso: boolean;
  correo:string='';
  cantidadMaxCaractIdentificacion: number=0;
  constructor(private servicioSeguridad: SeguridadService, private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SolicitarCreditoComponent>,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public datosRecibidos: datosFormSolicitud,
    private sessionStore: SessionStorageService) {
    this.registroExitoso = false;
    if (datosRecibidos.monto != null && datosRecibidos.monto != 0) {
      this.compraMall = 1;
      this.montoSolicitar = datosRecibidos.monto;
    }
    else {
      this.compraMall = 2;
      this.montoSolicitar = 0;
    }
    if (datosRecibidos.tipoSolicitud != null && datosRecibidos.tipoSolicitud != '') {
      this.tipoSolicitud = datosRecibidos.tipoSolicitud;
    }
    else {
      this.tipoSolicitud = 'inmediato';
    }
  }
  radioChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.cantidadMaxCaractIdentificacion = 9;
    }
    else {
      this.cantidadMaxCaractIdentificacion = 12;
    }
    this.identificacion = '';
  }
  ngOnInit(): void {
    this.obtenerActividadesEconomicas();
    if (this.sessionStore.retrieve('usuario') != null) {
      this.infoUsuario = this.sessionStore.retrieve('usuario');
      this.identificacion = this.infoUsuario.identificacion;
      this.nombre = this.infoUsuario.nombre;
      if (this.infoUsuario.apellido1 == null && this.infoUsuario.apellido2 == null) {
        var separacionApellidos = this.infoUsuario.apellidos.split(' ', 2);
        this.apellido1 = separacionApellidos[0];
        this.apellido2 = separacionApellidos[1];
      }
      else {
        this.apellido1 = this.infoUsuario.apellido1;
        this.apellido2 = this.infoUsuario.apellido2;
      }
      this.telefono = this.infoUsuario.telefono1;
    }
    this.tipoIdentificacionSeleccionada = 1;
    this.tipoIngresoSeleccionado = 1;
    this.cantidadMaxCaractIdentificacion = 9;
  }

  cambioMonto() {
    /*this.montoSolicitar = this.currencyPipe.transform(this.montoSolicitar.replace(/\D/g, '').replace(/^0+/, ''), '₡', 'symbol', '1.0-0');*/
  }
  recaptchaExitoso(parametro: any) {
    this.validacionCorrecta = true;
  }

  obtenerActividadesEconomicas() {
    this.servicioSeguridad.obtenerOcupaciones().subscribe(
      result => {
        this.actividadesEconomicas = result;
      });
  }

  cambioIdentificacion() {
    this.identificacion = this.identificacion.replace(' ', '').replace('-', '');
    if (this.tipoIdentificacionSeleccionada == 1) {
      this.servicioSeguridad.infoPadron(this.identificacion).subscribe(
        result => {
          this.usuarioPadron = result;
          this.nombre = this.usuarioPadron.nombre;
          this.apellido1 = this.usuarioPadron.apellido1;
          this.apellido2 = this.usuarioPadron.apellido2;
        });
    }
  }

  solicitar() {
    let procesar: boolean = false;
    if (this.apellido1 != '' && this.apellido2 != ''
      && this.identificacion != '' && this.nombre != ''
      && this.telefono != '' && this.tipoIdentificacionSeleccionada != 0
      && this.apellido1 != null && this.apellido2 != null
      && this.identificacion != null && this.nombre != null
      && this.telefono != null && this.tipoIdentificacionSeleccionada != null
      && this.tipoSolicitud != 'inmediato') {
      procesar = true;
    }
    if (this.apellido1 != '' && this.apellido2 != ''
      && this.identificacion != '' && this.nombre != ''
      && this.telefono != '' && this.tipoIdentificacionSeleccionada != 0
      && this.apellido1 != null && this.apellido2 != null
      && this.identificacion != null && this.nombre != null
      && this.telefono != null && this.tipoIdentificacionSeleccionada != null
      && this.fechaNacimiento != ''
      && this.montoSolicitar != null || this.montoSolicitar != 0
      && this.tipoIngresoSeleccionado != 0
      && this.fechaNacimiento != null
      && this.tipoIngresoSeleccionado != null
      && this.tipoSolicitud == 'inmediato') {
      procesar = true;
      if (this.tipoIngresoSeleccionado == 2) {
        if (this.actividadEconomicaSeleccionada == '' || this.antiguedad == '') {
          procesar = false;
        }
      }
    }
    if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.correo) 
    && this.tipoSolicitud!='inmediato') {
      this.toastr.error('El correo electrónico no tiene un formato correcto', 'Error!');
      procesar = false;
    }
    if (!/^[0-9]*$/.test(this.telefono)) {
      procesar = false;
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!');
    }
    if (this.telefono.length != 8) {
      procesar = false;
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!');
    }
    // if (!this.validacionCorrecta) {
    //   procesar = false;
    //   this.toastr.error('Por favor realice la verificación de seguridad!', 'Error!');
    // }
    if (procesar) {
      let naturaleza: string = '';
      if (this.tipoSolicitud == 'inmediato') {
        if (this.compraMall == 1) {
          naturaleza = 'Solicitud Crédito Inmediato-Compra del Mall';
        }
        else {
          naturaleza = 'Solicitud Crédito Inmediato-Directa';
        }
      }
      else if(this.tipoSolicitud == 'unificacion'){
        naturaleza = 'Solicitud Unificación de Deudas';
      }
      else if(this.tipoSolicitud == 'financiamiento'){
        naturaleza = 'Solicitud Financiamiento Instantáneo';
      }
      else if(this.tipoSolicitud == 'volvemos'){
        naturaleza = 'Solicitud Volver a Prestar';
      }
      this.servicioSeguridad.enviarSolicitudCredito(this.identificacion,
        this.nombre.toString() + ' ' + this.apellido1 + ' ' + this.apellido2,
        this.telefono, this.fechaNacimiento,
        this.tipoIngresoSeleccionado == 1 ? 'Asalariado' : 'No asalariado',
        this.tipoIngresoSeleccionado == 2 ? this.actividadEconomicaSeleccionada.toString() : 'No aplica',
        this.tipoIngresoSeleccionado == 2 ? this.antiguedad.toString() : 'No aplica',
        this.montoSolicitar, naturaleza,this.num_asegurado).subscribe(
          result => {
            let resultado: boolean = result;
            this.toastr.success('Su solicitud ha sido enviada, pronto nos comunicaremos con usted', 'Muchas gracias!');
            this.dialogRef.close('PROCESADO');
          });
    }
    else {
      this.toastr.error('Por favor complete toda la información solicitada', 'Error');
      // this.captchaElem.resetCaptcha();
    }

  }

  cambioTelefono() {
    if (!/^[0-9]*$/.test(this.telefono)) {
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!');
    }
    if (this.telefono.length != 8) {
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!');
    }
  }
}
