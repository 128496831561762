import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, Input, Output, EventEmitter  } from '@angular/core';
import { URL_BASE_IMG_COMERCIOS_BENEFICIOS } from '../../../global-variables';
import { URL_BASE_IMG_PORTADA_BENEFICIOS } from '../../../global-variables';
import {  SERVIDOR_CORREO,USUARIO_CORREO_REMITENTE,CONTRASENA_CORREO_REMITENTE,
  NOMBRE_MOSTRAR_CORREO_CUPON_BENEFICIO,NOMBRE_MOSTRAR_CORREO_CUPON_MARKET_PLACE, CORREO_CC_CUPON_BENEFICIO,TITULO_MODULO_BENEFICIOS, 
  SUBTITULO_MODULO_BENEFICIOS,TITULO_MODULO_MARKET_PLACE,SUBTITULO_MODULO_MARKET_PLACE,MODO_WEB } from '../../../global-variables';
import { referenciaCategoriaComercioBeneficio, cuponAdquirido } from '../../../interfaces';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { BeneficioService } from 'src/app/services/beneficio.service';
import {MarketService } from 'src/app/services/market.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NavController } from '@ionic/angular';
@Component({
  selector: 'app-cupon-adquirido',
  templateUrl: './cupon-adquirido.component.html',
  styleUrls: ['./cupon-adquirido.component.scss'],
})
export class CuponAdquiridoComponent implements OnInit {
  correoAdicional:string='';
  @Input() cuponMostrar: cuponAdquirido = { id:0,tituloSeccion:'',subtituloSeccion:'',codigo:'',correoEnvio:'',tituloCupon:'',disclaimer:'',condiciones:'',
  restricciones:'',vigencia:'',fechaFinalizacion:'',urlImagen:'',disponibles:0,precio:0,precioCuotas:0,
  precioPunto:0,idBeneficio:0,idProducto:0,nombreComercio:'',formaUso:'',cantidadArticulos:0,montoPagarEnComercio:0,montoPagadoConPuntos:0,
  montoPagadoConCuotas:0,montoPagadoEnLinea:0,cantidadPuntosRedimidos :0,precioUnitario:0,referenciaInterna:''};
  titulo_modulo_beneficios = TITULO_MODULO_BENEFICIOS;
  subtitulo_modulo_beneficios = SUBTITULO_MODULO_BENEFICIOS;
  titulo_modulo_market_place=TITULO_MODULO_MARKET_PLACE;
  subtitulo_modulo_market_place=SUBTITULO_MODULO_MARKET_PLACE;
  servidorCorreo=SERVIDOR_CORREO;
  usuarioCorreoRemitente=USUARIO_CORREO_REMITENTE;
  contrasenaCorreoRemitente=CONTRASENA_CORREO_REMITENTE;
  nombreMostrarCorreoCuponBeneficio=NOMBRE_MOSTRAR_CORREO_CUPON_BENEFICIO;
  nombreMostrarCorreoCuponMarketPlace=NOMBRE_MOSTRAR_CORREO_CUPON_MARKET_PLACE;
  correoCCCuponBeneficio= CORREO_CC_CUPON_BENEFICIO;
  faChevronCircleLeft = faAngleLeft;
  elementType:string='canvas';
  esModoWeb:number=MODO_WEB;
  margenSuperior:string;
  vertical:number=0;
  @Input() moduloRemitente:string='';
  @Output() regreso = new EventEmitter<referenciaCategoriaComercioBeneficio>();
  elementoSeleccionado: referenciaCategoriaComercioBeneficio = {
    idCategoria: 0, idComercio: 0,
    nombreComercio: '', nombreCategoria: ''
  };
  constructor(public navCtrl: NavController,private toastr: ToastrService,private servicioBeneficios: BeneficioService,private servicioMarket:MarketService, private activatedRoute: ActivatedRoute) { 
    if(this.esModoWeb==1){
      this.margenSuperior='margenSuperior';
    }
    else{
      this.margenSuperior='';
    }
    if(window.screen.width>window.screen.height){
      this.vertical=2;
    }
    else{
      this.vertical=1;
    }
  }

  ngOnInit() {
   
  }

  avisarSeleccion() {
    this.elementoSeleccionado = {
      idCategoria: 0, idComercio: 0,
      nombreComercio: '', nombreCategoria: ''
    };
    this.regreso.emit(this.elementoSeleccionado);


  }

  redireccionarHistorial(){
    this.navCtrl.navigateRoot(['cuenta']);
  }

  reenviarCupon(){
    let respuesta:number;
    if(this.moduloRemitente=='BENEFICIOS'){
    this.servicioBeneficios.reenviarCuponBeneficio(this.servidorCorreo,
      this.usuarioCorreoRemitente,this.contrasenaCorreoRemitente,this.nombreMostrarCorreoCuponBeneficio,
      this.correoCCCuponBeneficio,this.correoAdicional,this.titulo_modulo_beneficios,
      this.subtitulo_modulo_beneficios,this.cuponMostrar.condiciones,this.cuponMostrar.codigo,
      this.cuponMostrar.formaUso,this.cuponMostrar.tituloCupon,
      this.cuponMostrar.nombreComercio).subscribe(
      next => {
        respuesta = next;
        if(respuesta==1){
          this.toastr.success('El cupón se ha enviado al correo ' + this.correoAdicional,'Aviso',{progressBar:true});
        }
        else{
          this.toastr.error('Ha ocurrido un problema al enviar el correo, por favor intente mas tarde', '¡Error!',{progressBar:true});
        }
      }
    )
  }
  else if(this.moduloRemitente=='MARKET_PLACE'){
    this.servicioMarket.reenviarCuponMarket(this.servidorCorreo,
      this.usuarioCorreoRemitente,this.contrasenaCorreoRemitente,this.nombreMostrarCorreoCuponMarketPlace,
      this.correoCCCuponBeneficio,this.correoAdicional,this.titulo_modulo_market_place,
      this.subtitulo_modulo_market_place,this.cuponMostrar.condiciones,this.cuponMostrar.codigo,
      this.cuponMostrar.formaUso,this.cuponMostrar.tituloCupon,
      this.cuponMostrar.nombreComercio,
      this.cuponMostrar.cantidadArticulos,this.cuponMostrar.montoPagarEnComercio,0,0,
      this.cuponMostrar.montoPagadoConPuntos,this.cuponMostrar.montoPagadoConCuotas,this.cuponMostrar.montoPagadoEnLinea,
      this.cuponMostrar.cantidadPuntosRedimidos).subscribe(
      next => {
        respuesta = next;
        if(respuesta==1){
          this.toastr.success('El cupón se ha enviado al correo ' + this.correoAdicional,'Aviso',{progressBar:true});
        }
        else{
          this.toastr.error('Ha ocurrido un problema al enviar el correo, por favor intente mas tarde', '¡Error!',{progressBar:true});
        }
      }
    )
  }
}

}
