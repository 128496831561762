import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarraProductoComponent } from './barra-producto/barra-producto.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [BarraProductoComponent],
  imports: [
    CommonModule, MatButtonModule, MatMenuModule,
    FontAwesomeModule, ToastrModule, MatTooltipModule
  ],
  exports: [BarraProductoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BarraProductoModule { }
