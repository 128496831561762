import { Component, Inject, OnInit, CUSTOM_ELEMENTS_SCHEMA ,Input} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr';
import { ModalController,LoadingController } from '@ionic/angular';
import {SeguridadService} from '../../services/seguridad.service';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss'],
})
export class RecuperarContrasenaComponent implements OnInit {
  cargando: boolean = false;
  aFormGroup: FormGroup;
  
  
  correo: string;
  resultadoRecuperacion: boolean = false;

  constructor(public loading: LoadingController,private formBuilder: FormBuilder,private servicioSeguridad:SeguridadService,
    private dialogRef: MatDialogRef<RecuperarContrasenaComponent>,
    private toastr: ToastrService,public viewCtrl: ModalController,) {
  }

  async mostrarLoading() {
    this.cargando = true;
    return await this.loading.create({
      message: 'Procesando, espere por favor...'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.cargando) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async cerrarLoading() {
    this.cargando = false;
    return await this.loading.dismiss().then(() => console.log('cerrado'))
  }

  ngOnInit(): void {
  }

  recuperarContrasena() {
    this.mostrarLoading();
    if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.correo)) {
      this.cerrarLoading();
      this.toastr.error('El correo electrónico no tiene un formato correcto', 'Error!',{progressBar:true});
    }
    else {
      this.servicioSeguridad.recuperarContrasena(this.correo).subscribe(
        result => {
          if (result==true) {
            this.cerrarLoading();
            this.toastr.success('Se ha enviado un correo con su contraseña temporal!', '¡Muchas gracias!',{progressBar:true});
            this.viewCtrl.dismiss();
            this.dialogRef.close('${form.value.filename}');
          }
          else {
            this.cerrarLoading();
            this.toastr.error('Ha ocurrido un problema, intenta mas tarde', 'Error!',{progressBar:true});
          }
        });
    }
  }

}
