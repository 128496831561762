import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { categoriaProducto } from '../../../interfaces';
import { MatAccordion } from '@angular/material/expansion';
import { URL_IMG_CATEGORIA_TIENDA } from '../../../global-variables';
import { referenciaCategoriaSubCategoriaProducto } from '../../../interfaces';
import { TiendaService } from 'src/app/services/tienda.service';
import { ModalController } from '@ionic/angular';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-menu-categoria-productos',
  templateUrl: './menu-categoria-productos.component.html',
  styleUrls: ['./menu-categoria-productos.component.scss'],
})
export class MenuCategoriaProductosComponent implements OnInit{
  // @Output() cerrarDiv = new EventEmitter();
  // @Output() seleccionElemento = new EventEmitter<referenciaCategoriaSubCategoriaProducto>();
  faCerrar=faTimes;
  categorias: categoriaProducto[];
  urlImgCategoriaTienda: string = URL_IMG_CATEGORIA_TIENDA;
  elementoSeleccionado: referenciaCategoriaSubCategoriaProducto;
  constructor(private tiendaServicio:TiendaService,public viewCtrl: ModalController) {
    // http.get<categoriaProducto[]>(baseUrl + 'tienda/menucategorias').subscribe(result => {
    //   this.categorias = result;
    // }, error => console.error(error));
    // this.elementoSeleccionado = {
    //   idCategoria: 0, idSubcategoria: 0,
    //   nombreSubcategoria: '', nombreCategoria: ''
    // };

  }
  ngOnInit(): void {
    this.tiendaServicio.menuCategorias().subscribe(
      result => {
        this.categorias = result;
      }
    );
     this.elementoSeleccionado = {
      idCategoria: 0, idSubcategoria: 0,
      nombreSubcategoria: '', nombreCategoria: '',idMarca:0,nombreMarca:''
    };
  }
  avisarCerrado() {
    this.viewCtrl.dismiss(null);
    // this.cerrarDiv.emit(false);
  }

  avisarSeleccion(idCategoria: number, idSubCategoria: number, nombreCategoria: string, nombreSubcategoria: string) {
    this.elementoSeleccionado = {
      idCategoria: 0, idSubcategoria: 0,
      nombreSubcategoria: '', nombreCategoria: '',idMarca:0,nombreMarca:''
    };
    this.elementoSeleccionado.idCategoria = idCategoria;
    this.elementoSeleccionado.idSubcategoria = idSubCategoria;
    this.elementoSeleccionado.nombreCategoria = nombreCategoria;
    this.elementoSeleccionado.nombreSubcategoria = nombreSubcategoria;
    this.viewCtrl.dismiss(this.elementoSeleccionado);
    // this.seleccionElemento.emit(this.elementoSeleccionado);
  }
}

