import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistroComponent } from './registro.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

// import { NgxCaptchaModule } from 'ngx-captcha';
@NgModule({
  declarations: [RegistroComponent],
  imports: [
    CommonModule, MatDialogModule,
    FormsModule,
    MatInputModule, 
    MatButtonModule, FontAwesomeModule, ReactiveFormsModule, MatRadioModule, MatFormFieldModule,
    MatCheckboxModule, BrowserAnimationsModule, ToastrModule,MatTooltipModule
  ],
  /*exports: [RegistroComponent],*/
  entryComponents:[RegistroComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RegistroModule { }
