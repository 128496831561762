import { Component, OnInit,Input,  CUSTOM_ELEMENTS_SCHEMA ,Output,EventEmitter} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { URL_IMG_MARCO_BENEFICIO_PORTADA } from '../../global-variables';
import { URL_BASE_IMG_COMERCIOS_BENEFICIOS } from '../../global-variables';
import { URL_BASE_IMG_PORTADA_BENEFICIOS } from '../../global-variables';
import { referenciaCategoriaComercioBeneficio} from '../../interfaces';
@Component({
  selector: 'app-carrusel-beneficios',
  templateUrl: './carrusel-beneficios.component.html',
  styleUrls: ['./carrusel-beneficios.component.scss'],
})

export class CarruselBeneficiosComponent implements OnInit {
  @Output() comercioSeleccionado = new EventEmitter<referenciaCategoriaComercioBeneficio>();
  @Input() listaDatos;
  @Input() tipoElemento;
  url_img_marco_portada: string = URL_IMG_MARCO_BENEFICIO_PORTADA;
  url_base_imagenes_comercios: string = URL_BASE_IMG_COMERCIOS_BENEFICIOS;
  url_base_imagenes_portada: string = URL_BASE_IMG_PORTADA_BENEFICIOS;
  elementoSeleccionado:referenciaCategoriaComercioBeneficio= {
    idCategoria: 0, idComercio: 0,
    nombreComercio: '', nombreCategoria: ''
  };
  constructor() { }

  customOptions: OwlOptions;

  ngOnInit() {
    setTimeout(() => {
      this.customOptions = {
        autoWidth: true,
        loop: true,
        autoplay: true,
        animateIn: true,
        animateOut: true,
        autoplayHoverPause: true,
        lazyLoad: true,
        autoplaySpeed: 500,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        responsiveRefreshRate: 200,
        navSpeed: 900,
        responsive: {
          0: {
            items: 2,
            margin: 5
          },
          480: {
            items: 2,
            margin: 15
          },
          767: {
            items: 3,
            margin: 40
          },
          1024: {
            items: 4,
            margin: 50
          }
        },
        nav: true,
        navText: ['<','>'],
        fluidSpeed: false
      }
    }, 200);
  }


 
}
