import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePage } from './home.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage
  }
  // ,
  // {
  //   path: 'beneficios',
  //   loadChildren: () => import('../../pages/beneficios/beneficios.module').then( m => m.BeneficiosPageModule)
  // },
  // {
  //   path: 'tienda',
  //   loadChildren: () => import('../../pages/tienda/tienda.module').then( m => m.TiendaPageModule)
  // },
  // {
  //   path: 'servicios',
  //   loadChildren: () => import('../../pages/servicios/servicios.module').then( m => m.ServiciosPageModule)
  // },
  // {
  //   path: 'registro',
  //   loadChildren: () => import('../../pages/registro/registro.module').then( m => m.RegistroPageModule)
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
