import { Component, OnInit,Inject,AfterViewInit } from '@angular/core';
import { HomeComponent } from 'src/app/modules/home/home.component';
import{URL_LOGO_ENCABEZADO,MODO_WEB} from '../../global-variables';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  
  logo:string=URL_LOGO_ENCABEZADO;
  esModoWeb:number=MODO_WEB;

  constructor() { 
    // let window.innerWidth=1

  }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
  }

  // public ngAfterViewChecked(): void {window.dispatchEvent(new Event('resize'));}

}
