import { Component, OnInit, Inject, Input, CUSTOM_ELEMENTS_SCHEMA, Output, EventEmitter, SimpleChange, SimpleChanges  } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { categoriaProducto, referenciaCategoriaSubCategoriaProducto } from '../../../interfaces';
import { URL_IMG_CATEGORIA_TIENDA } from '../../../global-variables';
import { TiendaService } from 'src/app/services/tienda.service';
@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
})
export class CategoriaComponent implements OnInit {

  urlImgCategoriaTienda: string = URL_IMG_CATEGORIA_TIENDA;
  @Input() idCategoriaProducto: number;
  @Input() nombreCategoriaProducto: string;
  subcategorias: categoriaProducto[];
  elementoSeleccionado: referenciaCategoriaSubCategoriaProducto;
  cantidadColumnas: number;
  relacionAnchoAlto: string;
  @Output() subCategoriaSeleccionada = new EventEmitter<referenciaCategoriaSubCategoriaProducto>();
  constructor( private tiendaServicio:TiendaService) {
  }

  ngOnInit(): void {
    this.actualizarListaSubcategorias();
    this.calcularCantidadColumnas(window.innerWidth, window.innerHeight);
    this.elementoSeleccionado = {
      idCategoria: this.idCategoriaProducto, idSubcategoria: 0,
      nombreSubcategoria: '', nombreCategoria: this.nombreCategoriaProducto,idMarca:0,nombreMarca:''
    };
  }

  ngOnChanges(cambios: SimpleChanges) {
    if (cambios.idCategoriaProducto.currentValue != cambios.idCategoriaProducto.previousValue) {
      this.actualizarListaSubcategorias();
    }
  }

  actualizarListaSubcategorias() {
    this.tiendaServicio.menuSubCategorias(this.idCategoriaProducto).subscribe(
      result => {
        this.subcategorias = result;
      }
    );
  }

  avisarSeleccion(idSubCategoria: number, nombreSubcategoria: string) {
    this.elementoSeleccionado.idCategoria = this.idCategoriaProducto;
    this.elementoSeleccionado.idSubcategoria = idSubCategoria;
    this.elementoSeleccionado.nombreCategoria = this.nombreCategoriaProducto;
    this.elementoSeleccionado.nombreSubcategoria = nombreSubcategoria;
    this.subCategoriaSeleccionada.emit(this.elementoSeleccionado);
  }

  onResize(event) {
    this.calcularCantidadColumnas(event.target.innerWidth, event.target.innerHeight);
  }

  calcularCantidadColumnas(pAncho: number, pAlto: number) {
    this.relacionAnchoAlto = '100:80';

    if ((pAncho / pAlto) > 1) {
      if (pAncho >= 0 && pAncho <= 480) {
        this.cantidadColumnas = 1;
      }
      if (pAncho > 480 && pAncho <= 767) {
        this.cantidadColumnas = 2;
      }
      if (pAncho > 767 && pAncho <= 1024) {
        this.cantidadColumnas = 3;
      }
      if (pAncho > 1024) {
        this.cantidadColumnas = 4;
      }
    }
    else {
      if (pAncho >= 0 && pAncho <= 480) {
        this.cantidadColumnas = 1;
        /*this.relacionAnchoAlto = '100:130';*/
      }
      if (pAncho > 480 && pAncho <= 767) {
        this.cantidadColumnas = 2;
        /*this.relacionAnchoAlto = '100:130';*/
      }
      if (pAncho > 767 && pAncho <= 1024) {
        this.cantidadColumnas = 3;
        /*this.relacionAnchoAlto = '100:140';*/

      }
      if (pAncho > 1024) {
        this.cantidadColumnas = 4;
        /*this.relacionAnchoAlto = '100:150';*/
      }
    }
  }

}
