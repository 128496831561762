import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, OnInit, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { URL_IMG_MINIATURA_TIENDA } from '../../../global-variables';
import { cuponProducto } from '../../../interfaces';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-lista-cupones',
  templateUrl: './lista-cupones.component.html',
  styleUrls: ['./lista-cupones.component.scss'],
})
export class ListaCuponesComponent implements OnInit {
  @Input() listaDatos: cuponProducto[];
  url_img_miniatura_tienda: string = URL_IMG_MINIATURA_TIENDA;
  cantidadColumnas: number;
  relacionAnchoAlto: string;
  @Input() mostrarPaginacion: boolean;
  @Input() esMarketPlace: boolean = false;
  @Output() idCuponMaestroSeleccionado = new EventEmitter<number>();
  listaDatosMostrar: cuponProducto[];
  page = 0;
  size = 16;
  constructor(private paginator: MatPaginatorIntl) {
    this.paginator.itemsPerPageLabel = "cupones por página";
    this.paginator.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return 'No hay cupones disponibles';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return 'mostrando cupones del ' + (startIndex + 1) + ' al ' + endIndex + ' de un total de ' + length + ' cupones';
    };
    this.paginator.nextPageLabel = "página siguiente";
    this.paginator.previousPageLabel = "página anterior";
    this.getData({ pageIndex: this.page, pageSize: this.size });

  }

  ngOnInit(): void {
    this.inicializarLista();
  }

  ngOnChanges(cambios: SimpleChanges) {
    if (cambios.listaDatos.currentValue != cambios.listaDatos.previousValue) {
      this.inicializarLista();
    }
  }

  inicializarLista() {
    this.page = 0;
    this.getData({ pageIndex: this.page, pageSize: this.size });
    this.calcularCantidadColumnas(window.innerWidth, window.innerHeight);

  }

  onResize(event) {
    this.calcularCantidadColumnas(event.target.innerWidth, event.target.innerHeight);
  }

  calcularCantidadColumnas(pAncho: number, pAlto: number) {
    this.relacionAnchoAlto = '100:130';

    if ((pAncho / pAlto) > 1) {
      if (pAncho >= 0 && pAncho <= 480) {
        this.cantidadColumnas = 2;
      }
      if (pAncho > 480 && pAncho <= 767) {
        this.cantidadColumnas = 2;
      }
      if (pAncho > 767 && pAncho <= 1024) {
        this.cantidadColumnas = 3;
      }
      if (pAncho > 1024) {
        this.cantidadColumnas = 4;
      }
    }
    else {
      if (pAncho >= 0 && pAncho <= 480) {
        this.cantidadColumnas = 2;
        /*this.relacionAnchoAlto = '100:130';*/
      }
      if (pAncho > 480 && pAncho <= 767) {
        this.cantidadColumnas = 2;
        /*this.relacionAnchoAlto = '100:130';*/
      }
      if (pAncho > 767 && pAncho <= 1024) {
        this.cantidadColumnas = 3;
        /*this.relacionAnchoAlto = '100:140';*/

      }
      if (pAncho > 1024) {
        this.cantidadColumnas = 4;
        /*this.relacionAnchoAlto = '100:150';*/
      }
    }
  }

  getData(obj) {
    try {
      let index = 0,
        startingIndex = obj.pageIndex * obj.pageSize,
        endingIndex = startingIndex + obj.pageSize;

      this.listaDatosMostrar = this.listaDatos.filter(() => {
        index++;
        return (index > startingIndex && index <= endingIndex) ? true : false;
      });
      window.scroll(0, 0);
    } catch (e) {
    }
  }

  avisarSeleccionCupon(pIdCuponMaestro: number) {
    this.idCuponMaestroSeleccionado.emit(pIdCuponMaestro);
  }

}
