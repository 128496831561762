import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecuperarContrasenaComponent } from './recuperar-contrasena.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';


import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [RecuperarContrasenaComponent],
  imports: [
    CommonModule, MatDialogModule, FormsModule, MatInputModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule,FontAwesomeModule,MatTooltipModule,
    BrowserAnimationsModule, ToastrModule,MatRadioModule,MatCheckboxModule
  ],
  entryComponents: [RecuperarContrasenaComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecuperarContrasenaModule { }
