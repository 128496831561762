import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BarraProductoModule } from '../barra-producto/barra-producto.module';
import { PipesModule } from '../pipes/pipes.module'
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { FooterMenuModule } from '../footer-menu/footer-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { DetalleCuponComponent } from './detalle-cupon/detalle-cupon.component';
import { ListaCuponesComponent } from './lista-cupones/lista-cupones.component';
import { CarritoCuponComponent } from './carrito-cupon/carrito-cupon.component';
import { CuponesComponent } from './cupones.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrdenCompraModule } from '../orden-compra/orden-compra.module';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import {OrdenCompraCuponModule} from '../orden-compra-cupon/orden-compra-cupon.module';

@NgModule({
  declarations: [DetalleCuponComponent, ListaCuponesComponent, CuponesComponent,CarritoCuponComponent],
  imports: [
    CommonModule, FontAwesomeModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, RouterModule, MatGridListModule,
    MatMenuModule, MatButtonModule, MatExpansionModule,
    MatSidenavModule, MatPaginatorModule, NgxSliderModule, MatSelectModule, NgxImageZoomModule, MatTooltipModule, OrdenCompraModule, MatRadioModule, MatTableModule,
    BarraProductoModule, FooterMenuModule, ToastrModule, PipesModule,OrdenCompraCuponModule
  ],
  exports: [DetalleCuponComponent, ListaCuponesComponent, CuponesComponent,CarritoCuponComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CuponesModule { }
