import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FooterMenuComponent} from '../footer-menu/footer-menu/footer-menu.component';
import {FooterMenuAppComponent} from '../footer-menu/footer-menu-app/footer-menu-app.component';



@NgModule({
  declarations: [FooterMenuComponent,FooterMenuAppComponent],
  imports: [
    CommonModule,RouterModule
  ],
  exports: [FooterMenuComponent,FooterMenuAppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FooterMenuModule { }
