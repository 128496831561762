import { Component,ViewChild, OnInit,Inject, CUSTOM_ELEMENTS_SCHEMA,Input} from '@angular/core';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr';
import { faEye,faEyeSlash,faInfo } from '@fortawesome/free-solid-svg-icons';
import { usuario } from '../../interfaces';
import { CLAVE_SERVIDOR_RECAPTCHA } from '../../global-variables';
// import { ReCaptcha2Component } from 'ngx-captcha';
import { MatRadioChange } from '@angular/material/radio';
import { SeguridadService } from 'src/app/services/seguridad.service';
import { LoadingController, ModalController } from '@ionic/angular';
@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  cargando: boolean = false;
  // @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  aFormGroup: FormGroup;
  validacionCorrecta: boolean;
  claveRecaptcha: string = CLAVE_SERVIDOR_RECAPTCHA;
  faEye = faEye;
  faInfo=faInfo;
  faEyeSlash = faEyeSlash;
  mostrarContra: boolean;
  terminosAceptados: boolean = false;
  linkTerminos: string = 'https://www.pts.cr';
  /*formulario: FormGroup;*/
  tiposIdentificacion = [{ id: 1, detalle: 'Nacional' },
    { id: 2, detalle: 'DIMEX' }];
  usuarioPadron: usuario;
  tipoIdentificacionSeleccionada:number;
  identificacion: string;
  nombre: string;
  apellido1: string;
  apellido2: string;
  telefono: string;
  correo: string;
  contrasena: string;
  registroExitoso: boolean;
  cantidadMaxCaractIdentificacion: number;
  constructor(public loading: LoadingController,private servicioSeguridad:SeguridadService,
    public viewCtrl: ModalController,
    private dialogRef: MatDialogRef<RegistroComponent>,
    private toastr: ToastrService ) {
    this.mostrarContra = false;
    this.registroExitoso = false;
  }

  async mostrarLoading() {
    this.cargando = true;
    return await this.loading.create({
      message: 'Procesando, espere por favor...'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.cargando) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async cerrarLoading() {
    this.cargando = false;
    return await this.loading.dismiss().then(() => console.log('cerrado'))
  }



  radioChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.cantidadMaxCaractIdentificacion = 9;
    }
    else {
      this.cantidadMaxCaractIdentificacion = 12;
    }
    this.identificacion = '';
  }
  ngOnInit() {
    this.validacionCorrecta = false;
    this.tipoIdentificacionSeleccionada = 1;
    this.cantidadMaxCaractIdentificacion=9;
  }


  registro() {
    this.mostrarLoading();
    let registrar: boolean = true;
    if (this.apellido1 == '' || this.apellido2 == '' || this.contrasena == '' || this.correo == '' || this.identificacion == '' || this.nombre == '' || this.telefono == '') {
      this.cerrarLoading();
      this.toastr.error('Es necesario completar todos los campos', 'Error!',{progressBar:true});
      registrar = false;
    }
    else if (this.apellido1 == null || this.apellido2 == null || this.contrasena == null || this.correo == null || this.identificacion == null || this.nombre == null || this.telefono == null) {
      this.cerrarLoading();
      this.toastr.error('Es necesario completar todos los campos', 'Error!',{progressBar:true});
      registrar = false;
    }
    else if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.correo)) {
      this.cerrarLoading();
      this.toastr.error('El correo electrónico no tiene un formato correcto', 'Error!',{progressBar:true});
      registrar = false;
    }
    else if (!/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/.test(this.contrasena)) {
      this.cerrarLoading();
      this.toastr.error('La contraseña no es segura, utilice entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter especial (caracteres especiales: $%#!@-?¿¡)', 'Error!',{progressBar:true});
      registrar = false;
    }
    else if (this.tipoIdentificacionSeleccionada == 1) {
      if (!/^[1-9]-?\d{4}-?\d{4}$/.test(this.identificacion)) {
        this.cerrarLoading();
        this.toastr.error('Número de identificación nacional es inválida, escriba los 9 dígitos sin incluir guiones', 'Error!',{progressBar:true});
        registrar = false;
      }
    }
    if (!/^[0-9]*$/.test(this.telefono)) {
      this.cerrarLoading();
      registrar = false;
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!',{progressBar:true});
    }
    if (this.telefono.length != 8) {
      this.cerrarLoading();
      registrar = false;
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!',{progressBar:true});
    }
    if(!this.terminosAceptados){
      this.cerrarLoading();
      registrar=false;
      this.toastr.error('Es necesario aceptar los términos y condiciones del programa', 'Error!',{progressBar:true});
    }
    // if (!this.validacionCorrecta) {
    //   this.toastr.error('Por favor realice la verificación de seguridad!', 'Error!');
    //   registrar = false;
    // }
    if (registrar == true) {
      this.servicioSeguridad.registrarusuario(this.correo,this.contrasena,this.correo,
        this.nombre,this.apellido1,this.apellido2,this.telefono,this.identificacion).subscribe(
        result => {
          this.registroExitoso = result;
          if (this.registroExitoso) {
            this.cerrarLoading();
            this.toastr.success('Bienvenid@! el registro ha sido exitoso!', '¡Muchas gracias!',{progressBar:true});
            this.viewCtrl.dismiss();
            this.dialogRef.close('${form.value.filename}');
          }
          else {
            this.cerrarLoading();
            this.toastr.error('Ha ocurrido un problema con el registro, intenta mas tarde', 'Error!',{progressBar:true});
          }
        });
    }

  }
 


  habilitarMostrarContra() {
    if (this.mostrarContra) {
      this.mostrarContra = false;
    }
    else {
      this.mostrarContra = true;
    }
  }



  cambioIdentificacion() {
    this.identificacion = this.identificacion.replace(' ', '').replace('-', '');
    if (this.tipoIdentificacionSeleccionada == 1) {
      this.servicioSeguridad.infoPadron(this.identificacion).subscribe(
        result => {
          this.usuarioPadron = result;
          this.nombre = this.usuarioPadron.nombre;
          this.apellido1 = this.usuarioPadron.apellido1;
          this.apellido2 = this.usuarioPadron.apellido2;
        });
    }
  }
  cambioTelefono() {
    if (!/^[0-9]*$/.test(this.telefono)) {
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!',{progressBar:true});
    }
    if (this.telefono.length!=8) {
      this.toastr.error('El número telefónico no tiene un formato correcto (8 números)', 'Error!',{progressBar:true});
    }
  }



  mostrarLeyendaContrasena(){
    this.toastr.info('Utilice entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter especial (caracteres especiales: $%#!@-?¿¡)', 'Formato Contraseña',{progressBar:true});
  }
}
