import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdenCompraComponent } from './orden-compra.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  declarations: [OrdenCompraComponent],
  imports: [
    CommonModule,  FormsModule,  MatButtonModule, ReactiveFormsModule,
    MatFormFieldModule, FontAwesomeModule,
    MatTooltipModule, MatTableModule
    // , BrowserAnimationsModule, ToastrModule
  ],
  exports: [OrdenCompraComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CurrencyPipe]
})
export class OrdenCompraModule { }
